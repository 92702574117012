import * as Yup from 'yup';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
// material
import {
  Grid,
  TextField,
  Alert,
  Typography,
  Switch,
  Stack,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  FormHelperText,
  FormControlLabel,
  Card,
  colors,
  Icon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, {Fragment } from 'react';

// component
import PropTypes, { number } from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import client from 'src/api/clients';
import { getClientList , setAddDevice ,getServerList ,getTimezoneList } from 'src/api/analytics';
import axios from 'src/axios';
import {setNewDevice ,putExistingDevice } from 'src/api/devices'

export default function DeviceForm({ dialogData, setOpen, reloadClientsData ,handleReloadPage}) {
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [clientDetails,setClient] = useState([]);
    const [serverDetails,setServer] = useState([]);
    const [timezoneDetails,setTimezone] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    // const [selectedClientId, setSelectedClientId] = useState('');
    // const [selectedServerId, setSelectedserverId] = useState('');
    // const [selectedTimezoneId, setSelectedtimezoneID] = useState('');
    const [originalFormDataList, setOriginalFormDataList] = useState([]);
    const [originalServerId, setOriginalServerId] = useState('');
    const [originalEventSendTo, setOriginalEventSendTo] = useState('');
    const [originalTimeZone, setOriginalTimeZone] = useState('');
    const [formDataList, setFormDataList] = useState([
      {
        clientid: '',
        CameraView: '',
        server_id: '',
        Serverport: '', 
        TimezoneId: '',
        MainStream: '',
        Category:'',
        substream: '',
        ReStream: '',
        LocalDeviceName: '',
        devicename: '',
        fps: null, 
        IsActive: 0,
        CameraMake: '',
        CameraModel: '',
        LocalHTTPURL: '',
        PublicHTTPURL: '',
        HaveSpeaker: 0,
        BackupDurationDays: null,
        Footage:'',
        IsAgentBased:'',
        EventsBackupDays: null,
        EscalatedEventsBackupDays : null,

      }
    ]);
    // console.log("Form data",formDataList)
    const [formErrors, setFormErrors] = useState({});
    const [saveClicked, setSaveClicked] = useState(false);



    const schema = Yup.object().shape({
      clientid: Yup.string().required('Client is required'),
      server_id: Yup.number().required('Server is required'),
      // CameraView: Yup.string().required('Camera View is required'),
      devicename: Yup.string().required('Camera Name is required'),
      // Serverport: Yup.number().required('Server Port is required'),
      // MainStream: Yup.string().required('Main Stream is required'),
      substream: Yup.string().required('Sub Stream is required'),
      // ReStream: Yup.string().required('ReStream is required'),
      // LocalDeviceName: Yup.string().required('Local Camera Name is required'),
      // fps: Yup.number().required('Frames Per Second (fps) is required'),
      // CameraMake: Yup.string().required('Camera Make is required'),
      // CameraModel: Yup.string().required('Camera Model is required'),
      // LocalHTTPURL: Yup.string().required('Local HTTP URL is required'),
      // PublicHTTPURL: Yup.string().required('Public HTTP URL is required'),
      TimezoneId: Yup.string().required('Time Zone is required'),
      Footage: Yup.string().required('Footage is required'),
      IsAgentBased: Yup.string().required('Events Type is required'),
      EventsBackupDays:  Yup.string().required('Events Backup Days is required'),
      EscalatedEventsBackupDays :  Yup.string().required('EscalatedEvents Backup Days  is required'),
      // archieve: Yup.boolean(),
      // BackupDurationDays: Yup.string().when('archieve', {
      //     is: true,
      //     then: Yup.string().required('BackupDurationDays is Required')
      // })
     // BackupDurationDays: Yup.string().required('BackupDurationDays is REquired'),

    });

    const handleClientChange = (index) => (event) => {
      // const newClientId = event.target.value;
      // setSelectedClientId(newClientId);

      setFormDataList((prevFormList) => {
        prevFormList[index].clientid = event.target.value
        return prevFormList
      })

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${index}.clientid`]: '' // Clear error message for the current index
      }));
    };

    const handleServerChange = (event, index) => {
      const newServerId = event.target.value;
      
      if (formDataList[index].server_id !== newServerId) {
        setOriginalServerId(formDataList[index].server_id);
      }
      // setSelectedserverId(newServerId);
      setFormDataList((prevFormData) => {
        prevFormData[index].server_id = event.target.value
        return prevFormData
      })
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${index}.server_id`]: '' // Clear error message for the current index
      }));
    };

    const handleTimeChange = (event, index) => {
      // setSelectedtimezoneID(newTimeId);
      //debugger
      let value = event.target.value
      if (formDataList[index].TimezoneId !== value) {
        setOriginalTimeZone(formDataList[index].TimezoneId);
      }

      setFormDataList((prevFormData) => {
        prevFormData[index].TimezoneId = event.target.value
        return prevFormData
      })
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${index}.TimezoneId`]: '' // Clear error message for the current index
      }));
    };
    

    const handleAddCamera = () => {
      // console.log("client ID", selectedClientId)
      setFormDataList([
        ...formDataList,
        {
          clientid: formDataList.length > 0 ? formDataList[0].clientid : null,
          CameraView: '',
          Serverport: '', 
          server_id: formDataList.length > 0 ? formDataList[0].server_id : null,
          MainStream: '',
          TimezoneId: formDataList.length > 0 ? formDataList[0].TimezoneId : null,
          Category:'',
          substream: '',
          ReStream: '',
          LocalDeviceName: '',
          devicename:'',
          fps: null, 
          IsActive: 0,
          CameraMake: '',
          CameraModel: '',
          LocalHTTPURL: '',
          PublicHTTPURL: '',
          HaveSpeaker: 0,
          BackupDurationDays: null,
          Footage:'',
          IsAgentBased:'',
          EventsBackupDays: null,
          EscalatedEventsBackupDays :null,
        }
      ]);
    };
    const handleRemoveCamera = () => {
      if (formDataList.length === 1) {

        return;
      }
      
      setFormDataList(prevFormDataList => {
          const newList = [...prevFormDataList];
          newList.pop(); 
          return newList;
      });
  };
  
  
    
  
    const handleChange = (index) => (field) => (event) => {
      //debugger
      const { value } = event.target;
      const newList = formDataList.map((formData, i) => {
        if (i === index) {
          return { ...formData, [field]: value };
        }
        return formData;
      });
  
      if (field === "clientid") {
        const updatedFormDataList = newList.map(formData => {
          return { ...formData, clientid: formDataList[0].clientid }
        });
        setFormDataList(updatedFormDataList);
      } else {
        // Otherwise, update the form data list with changes for the specific form
        setFormDataList(newList);
      }
       let errorMessage='';

       // Check if the field is "BackupDurationDays"
       if (field === 'BackupDurationDays') {
        if (!isNaN(value)) {
          // Convert value to integer
          const intValue = parseInt(value);
          // Ensure value is within the range of 1 to 30
          if (intValue < 1 || intValue > 30) {
            // Set error message if value is not in the range
            // setFormErrors(prevErrors => ({
            //   ...prevErrors,
            //   [`${index}.BackupDurationDays`]: 'Value must be between 1 and 30'
            // }));
            errorMessage='please enetr valid number';
          }
        } 
      }
      else {
        // Clear error message for other fields
        setFormErrors(prevErrors => ({
          ...prevErrors,
          [`${index}.${field}`]: ''
        }));
      }
    };
  
  
    
      
    // const handleSwitchChange = (index) => (name) => (event) => {
    //   const value = event.target.checked ? 1 : 0;
    //   setFormDataList((prevFormDataList) => {
    //     const updatedFormDataList = [...prevFormDataList];
    //     updatedFormDataList[index] = {
    //       ...updatedFormDataList[index],
    //       [name]: value
    //     };
    //     return updatedFormDataList;
    //   });
      
    // };  
    // handling for before submitting save that range function
    const handleSwitchChange = (index) => (name) => (event) => {
      const value = event.target.checked ? 1 : 0;
      setFormDataList((prevFormDataList) => {
        const updatedFormDataList = [...prevFormDataList];
        updatedFormDataList[index] = {
          ...updatedFormDataList[index],
          [name]: value
        };
    
        // If "Is Need Archive" switch is toggled, and Backup Duration Days is not provided,
        // set an error message
        if (name === 'IsActive' && value === 1 && !updatedFormDataList[index].BackupDurationDays) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`${index}.BackupDurationDays`]: 'Backup Duration Days is required'
          }));
        } else {
          // Clear the error message if Backup Duration Days is provided or "Is Need Archive" is toggled off
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`${index}.BackupDurationDays`]: ''
          }));
        }
    
        return updatedFormDataList;
      });
    };
    
        
    const jsonToFormData = (data) => {
      let formdata = new FormData()
      for (let x in data)
        formdata.append(x, data[x])
      return formdata
    }
  
    const handleSubmit = () => {  
    
      let count = 0;  
      Promise.all(
        formDataList.map((formData, index) => {
          // if(formData.BackupDurationDays === 0){
          //   count = 1;
          //   return; // Do not save if BackupDurationDays is not entered
          // }
          
          setSaveClicked(true);
          if (formData.IsActive && !formData.BackupDurationDays) {
              count = 1;
              return; // Do not save if BackupDurationDays is not entered
          }
          if (formData.IsActive && formData.BackupDurationDays === 0) {
            count = 1;
            return; // Do not save if BackupDurationDays is not entered
        }
          
          return schema.validate(formData, { abortEarly: false })
            .catch(errors => {

              // console.log("errors test",errors);
              return errors.inner.map(error => (
                count = 1,
                {
                ...error,
                path: `${index}.${error.path}`,
                
               
               
              }));
            });
            
        })
      )
      .then((validationResults) => {
        const allErrors = validationResults.flat()
          .filter(error => !!error); // Filter out any null or undefined values
        
        const errorCount = count;
        // console.log("Number of errors:", count);    

        if (dialogData && dialogData.id) {
          if (errorCount === 0){
                    // console.log('test',dialogData,dialogData.id.deviceid)
                    // if(dialogData.id.devicename == ''){
                    //   setCameraError("Camera Name is required")
                    // }

                    // const formDataList1 = {...formDataList[0], deviceid: dialogData.id.deviceid };   
                    // console.log("Before update BCDD", formDataList1);
                   
                    const updatedFields = {};

                    // Compare current form data with original data
                    formDataList.forEach((formData, index) => {
                      const originalData = originalFormDataList[index];
                      Object.keys(formData).forEach(key => {
                        if (formData[key] !== originalData[key]) {
                          updatedFields[key] = formData[key];
                        }
                      });
                      if (formData.server_id !== originalServerId && originalServerId) {
                        updatedFields.server_id = formData.server_id;
                      }
                      if (formData.IsAgentBased !== originalEventSendTo && originalEventSendTo) {
                        updatedFields.IsAgentBased = formData.IsAgentBased;
                      }
                      if (formData.TimezoneId !== originalTimeZone && originalTimeZone) {
                        updatedFields.TimezoneId = formData.TimezoneId;
                      }
                    });

                    updatedFields.deviceid = dialogData.id.deviceid;
                    updatedFields.clientid = dialogData.id.clientid;

                    putExistingDevice((response) => {
                      if (response.status === 200 || response.status === 201) {
                        // console.log(response.data);
                        handleReloadPage();
                        onClose();
                      }
                    }, jsonToFormData(updatedFields))


                    // const API_ENDPOINT = 'http://14.97.205.250:9002/devices/update';
                    // try {
                    //   const formDataList1 = {...formDataList[0], deviceid: dialogData.id.deviceid };   
                    //   console.log("update", formDataList1);
                    //   axios.put(API_ENDPOINT, jsonToFormData(formDataList1), {
                    //     headers: {
                    //       'accept': 'application/json',
                    //       'Content-Type': 'application/x-www-form-urlencoded'
                    //       // Authorization: AUTH_TOKEN
                    //     }
                    //   })
                    //   .then(response => {
                    //     console.log('API Response:', response.data);
                    //     onClose();


                    //   })
                    //   .catch(error => {
                    //     console.error('API Error:', error);
                        
                    //   });
                    // } catch (error) {
                    //   console.error('Error:', error);
                    // }                       
                  }
                  else{
                    const fieldErrors = {};
                    allErrors.forEach((error) => {
                      // Ensure that error.path is not undefined or null before splitting it
                      const [formIndex, fieldName] = (error.path ?? '').split('.');
                      // Creating a new error path with the index included
                      const indexedFieldName = `${formIndex}.${fieldName}`;
                      fieldErrors[indexedFieldName] = error.message;
                    });
                
                    setFormErrors(fieldErrors);

                  }
           
                  }
        else {

                  if (errorCount === 0) {
                    setFormErrors({});
                    // console.log("actual Form Errors",formErrors)
                    const formattedFormData = formDataList.map(formData => ({
                      ...formData,
                      // clientid: selectedClientId,
                      // server_id :selectedServerId !== '' ? parseInt(selectedServerId, 10): null,
                      Serverport: formData.Serverport !== '' ? parseInt(formData.Serverport, 10) : null,
                      fps: formData.fps !== '' ? parseInt(formData.fps, 10) : null,
                      Category : formData.Category === '' ? '0': formData.Category || '0',
                      BackupDurationDays: formData.BackupDurationDays !== '' ? parseInt(formData.BackupDurationDays, 10) : null
                    }));
                
                    // console.log("Formatted Form Data:", formattedFormData);
                
                    // const apiUrl = 'http://14.97.205.250:9002/devices/add';
                    // const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
                
                    // axios.post(apiUrl, formattedFormData, {
                    //   headers: {
                    //     Authorization: AUTH_TOKEN
                    //   }
                    // })
                    // .then(response => {
                    //   if (response.status === 200 || response.status === 201) {
                    //     console.log(response.data);
                    //     onClose();
                    //     // setSelectedClientId('');
                    //   }
                    // })
                    // .catch(error => {
                    //   console.error('Error:', error.message);
                    // });

                     setNewDevice((response) => {
                      if (response.status === 200 || response.status === 201) {
                        // console.log(response.data);
                        handleReloadPage();
                        onClose();
                      }
                    }, formattedFormData)


                  } else {
                    // Set errors if validation failed
                    const fieldErrors = {};
                    allErrors.forEach((error) => {
                      // Ensure that error.path is not undefined or null before splitting it
                      const [formIndex, fieldName] = (error.path ?? '').split('.');
                      // Creating a new error path with the index included
                      const indexedFieldName = `${formIndex}.${fieldName}`;
                      fieldErrors[indexedFieldName] = error.message;
                    });
                
                    setFormErrors(fieldErrors);
                  }
          }
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
      
    };  

    const onClose = () => {
      setOpen(false);
      setSaveClicked(false);
      setFormDataList([ 
        {
          clientid: '',
          CameraView: '',
          Serverport: null, 
          MainStream: '',
          substream: '',
          Category:'',
          ReStream: '',
          LocalDeviceName: '',
          devicename: '',
          server_id: '',
          fps: null, 
          IsActive: 0,
          CameraMake: '',
          CameraModel: '',
          LocalHTTPURL: '',
          PublicHTTPURL: '',
          HaveSpeaker: 0,
          TimezoneId:'',
          BackupDurationDays: null,
          Footage:'',
          IsAgentBased:''
        }
      ]);
      setFormErrors({});
      setSelectedFootage(Array(formDataList.length).fill(''));
      setSelectedEventsType(Array(formDataList.length).fill(''));

      setOriginalFormDataList([]);
      setOriginalServerId('')
      setOriginalEventSendTo('')
      setOriginalTimeZone('')
    }

    useEffect(() => {
      // console.log("clients from Device form");
      getClientList((response) => {
        if (response.status === 200) {
          // console.log("clients from Device form", response.data);
          setClient(response.data)    
        }
      });
    }, []);

    useEffect(() => {
      // console.log("Server ");
      getServerList((response) => {
        if (response.status === 200) {
          // console.log("servers ", response.data);
          setServer(response.data)    
        }
      });
    }, []);
    
    useEffect(() => {
      // console.log("Server ");
      getTimezoneList((response) => {
        if (response.status === 200) {
          // console.log("Time Zone ", response.data);
          setTimezone(response.data)    
        }
      });
    }, []);

    useEffect(() => {

      if(dialogData){     
        const keys = ['clientid','CameraView','TimezoneId','devicename','Category','Serverport','MainStream','substream','ReStream','LocalDeviceName','server_id','fps','IsActive','BackupDurationDays','CameraMake','CameraModel','LocalHTTPURL','PublicHTTPURL','HaveSpeaker','Footage','IsAgentBased','EscalatedEventsBackupDays','EventsBackupDays'];
        const values = ['clientid','CameraView','TimezoneId','devicename','Category','Serverport','MainStream','substream','ReStream','LocalDeviceName','server_id','fps','IsActive','BackupDurationDays','CameraMake','CameraModel','LocalHTTPURL','PublicHTTPURL','HaveSpeaker','Footage','IsAgentBased','EscalatedEventsBackupDays','EventsBackupDays'];
        if (dialogData.id) {
          const updatedFormDataList = formDataList.map((formData) => {
            const updatedFormData = { ...formData };
            keys.forEach((key, idx) => {
            
              // console.log('index',dialogData.id[values[idx]]);
                updatedFormData[key] = dialogData.id[values[idx]] === null ? '' : dialogData.id[values[idx]];
              
             
            });
            return updatedFormData;
          });
          // console.log("updatd ",updatedFormDataList)
          setFormDataList([...updatedFormDataList]);
          setOriginalFormDataList([...updatedFormDataList]);
        } else {
        //  setOpen({...dialogData, isOpen: true, id: null})
        }
      }
    }, [dialogData.isOpen]);    

    const [selectedFootage, setSelectedFootage] = useState(Array(formDataList.length).fill(''));
    const [selectedEventsType, setSelectedEventsType] = useState(Array(formDataList.length).fill(''));

    const handleFootageChange = (index) => (event) => {
      const value = event.target.value;
      setSelectedFootage(prevSelectedFootage => {
        const newSelectedFootage = [...prevSelectedFootage];
        newSelectedFootage[index] = value;
        return newSelectedFootage;
      });

      setFormDataList((prevFormList) => {
        prevFormList[index].Footage = event.target.value
        return prevFormList
      })
    };
    
    const handleEventsTypeChange = (index) => (event) => {
      //debugger
      const value = event.target.value;
      if (formDataList[index].IsAgentBased !== value) {
        setOriginalEventSendTo(formDataList[index].IsAgentBased);
      }
      setSelectedEventsType(prevSelectedEventsType => {
        const newSelectedEventsType = [...prevSelectedEventsType];
        newSelectedEventsType[index] = value;
        return newSelectedEventsType;
      });

      setFormDataList((prevFormList) => {
        prevFormList[index].IsAgentBased = event.target.value
        return prevFormList
      })

    };
    return (
    <Dialog fullWidth={fullWidth}  maxWidth="xl" open={dialogData.isOpen}  >
          <DialogTitle>Add/Edit Camera</DialogTitle>

        {dialogData.id ? null : (
          <>
            <LoadingButton
              onClick={handleRemoveCamera}
              color="error"
              variant="outlined"
              startIcon={<Iconify icon="eva:minus-fill" />}
              style={{
                position: "absolute",
                top: "20px",
                right: "90px",
              }}
            > </LoadingButton>

             <LoadingButton
              onClick={handleAddCamera}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
            > {formDataList.length}</LoadingButton>
          </>
        )}

        <Grid container spacing={2}>
          <Grid item md={8}></Grid>
        </Grid>
        <DialogContent>
          {formDataList.map((formData, index) => (
            <Card>
              <Typography
                variant="subtitle2"
                sx={{
                  my: 2, // margin top and bottom
                  bgcolor: "#f0f0f0", // background color
                  padding: "8px", // optional padding
                  borderRadius: "4px", // optional border radius
                }}
              >
                Basic Info 
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id={`clientid-label-${index}`}>
                      Client Name
                    </InputLabel>

                    <Select
                        label="Client Name"
                        labelId={`clientid-label-${index}`}
                        id={`clientid-${index}`}
                        value={formData.clientid}
                        onChange={handleClientChange(index)}
                        error={!!formErrors[`${index}.clientid`]}
                        disabled={dialogData.id ? true : false}
                      >
                        {clientDetails.map((option,index) => (
                          <MenuItem
                            key={index}
                            value={option.ID != null ? option.ID.toString() : ""}
                          >
                            {option.displayname}
                          </MenuItem>
                        ))}
                      </Select>


                    <FormHelperText style={{ color: "red" }}>
                      {formErrors[`${index}.clientid`] || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="camera-view-label">Camera View</InputLabel>
                    <Select
                      label="Camera View"
                      labelId="camera-view-label"
                      id="camera-view"
                      value={formData.CameraView}
                      onChange={handleChange(index)("CameraView")} // Pass the index here
                      // error={!!formErrors[`${index}.CameraView`]}
                    >
                      <MenuItem value="In Door">In Door</MenuItem>
                      <MenuItem value="Out Door">Out Door</MenuItem>
                    </Select>
                    {/* <FormHelperText style={{ color: "red" }}>
                      {formErrors[`${index}.CameraView`] || ""}
                    </FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="camera-Cat-label">Category</InputLabel>
                    <Select
                      label="Category"
                      labelId="camera-cat-label"
                      id="camera-cat"
                      value={formData.Category || 0}
                      onChange={handleChange(index)("Category")}
                      error={!!formErrors[`${index}.Category`]}
                    >
                      <MenuItem value="0">Not Assigned</MenuItem>
                      <MenuItem value="1"> Category 1</MenuItem>
                      <MenuItem value="2"> Category 2</MenuItem>
                      <MenuItem value="3"> Category 3</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formErrors[`${index}.Category`] || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="timeZone-label">Time Zone</InputLabel>
                    <Select
                      label="Time Zone"
                      labelId="TimeZone-label"
                      id="timeZone-label"
                      value={formData.TimezoneId}
                      onChange={(event) => handleTimeChange(event, index)}
                      error={!!formErrors[`${index}.TimezoneId`]}
                    >
                      {timezoneDetails.map((option) => (
                        <MenuItem value={option.Id}>{option.TimeZone}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formErrors[`${index}.TimezoneId`] || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Camera Name"
                    value={formData.devicename}
                    onChange={handleChange(index)("devicename")}
                    error={!!formErrors[`${index}.devicename`]}
                    helperText={formErrors[`${index}.devicename`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Server Port"
                    type="number"
                    value={formData.Serverport}
                    onChange={handleChange(index)("Serverport")}
                    // error={!!formErrors[`${index}.Serverport`]}
                    // helperText={formErrors[`${index}.Serverport`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Main Stream"
                    value={formData.MainStream}
                    onChange={handleChange(index)("MainStream")}
                    // error={!!formErrors[`${index}.MainStream`]}
                    // helperText={formErrors[`${index}.MainStream`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Sub Stream"
                    value={formData.substream}
                    onChange={handleChange(index)("substream")}
                    error={!!formErrors[`${index}.substream`]}
                    helperText={formErrors[`${index}.substream`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="ReStream"
                    value={formData.ReStream}
                    onChange={handleChange(index)("ReStream")}
                    // error={!!formErrors[`${index}.ReStream`]}
                    // helperText={formErrors[`${index}.ReStream`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Local Camera Name"
                    value={formData.LocalDeviceName}
                    onChange={handleChange(index)("LocalDeviceName")}
                    // error={!!formErrors[`${index}.LocalDeviceName`]}
                    // helperText={formErrors[`${index}.LocalDeviceName`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="server_id">Server</InputLabel>
                    <Select
                      label="Server"
                      labelId="Server_id"
                      id="server_id"
                      value={formData.server_id}
                      onChange={(event) => handleServerChange(event, index)}
                      error={!!formErrors[`${index}.server_id`]}
                    >
                      {serverDetails.map((option) => (
                        <MenuItem value={option.Id}>
                          {option.ServerName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formErrors[`${index}.server_id`] || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Frames Per Second (fps)"
                    type="number"
                    value={formData.fps}
                    onChange={handleChange(index)("fps")}
                    // error={!!formErrors[`${index}.fps`]}
                    // helperText={formErrors[`${index}.fps`] || ""}
                  />
                </Grid>

                <Grid item md={2}>
                    <FormControl fullWidth>
                      <InputLabel id={`footage-label`}>Footage</InputLabel>
                      <Select
                        label='Footage'
                        labelId={`footage-label`}
                        id={`footage`}
                        //value={selectedFootage[index]}
                        value={formData.Footage}
                        onChange={handleFootageChange(index)}
                        error={!!formErrors[`${index}.Footage`]}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="0">Image</MenuItem>
                        <MenuItem value="1">Video</MenuItem>
                      </Select>
                          <FormHelperText style={{ color: "red" }}>
                          {formErrors[`${index}.Footage`] || ""}
                          </FormHelperText>
                      
                    </FormControl>
                </Grid>

                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id={`events-type-label`}>Event Send To</InputLabel>
                    <Select
                      label='Event Send To'
                      labelId={`events-type-label`}
                      id={`events-type`}
                      //value={selectedEventsType[index]}
                      value={formData.IsAgentBased}
                      onChange={handleEventsTypeChange(index)}
                      error={!!formErrors[`${index}.IsAgentBased`]}

                    >
                      <MenuItem value="">Select</MenuItem> 
                      <MenuItem value="1">Tier 1</MenuItem>
                      <MenuItem value="0">Client</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                          {formErrors[`${index}.IsAgentBased`] || ""}
                          </FormHelperText>
                  </FormControl>
                </Grid>


                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="success"
                        checked={formData.IsActive === 1 ? true : false }
                        onChange={handleSwitchChange(index)("IsActive")}
                      />
                    }
                    label="Is Need Archives"
                  />
                </Grid>
                {formData.IsActive ? (
                  // <Grid item md={2}>
                  //   <TextField
                  //     fullWidth
                  //     label="BackupDurationDays"
                  //     type="number"
                  //     max="30"
                  //     min="1"

                  //     value={formData.BackupDurationDays}
                  //     onChange={handleChange(index)('BackupDurationDays')}
                  //     error={saveClicked && formData.archieve && !formData.BackupDurationDays}
                  //     helperText={(saveClicked && formData.archieve && !formData.BackupDurationDays) && "Backup Duration Days is required"}
                  //     />
                  // </Grid>
                  <Grid item md={2}>
                    {/* <TextField
                      fullWidth
                      label="BackupDurationDays"
                      type="number"
                      value={formData.BackupDurationDays}
                      onChange={handleChange(index)("BackupDurationDays")}
                      error={
                        saveClicked &&
                        formData.archieve &&
                        !formData.BackupDurationDays
                      }
                      helperText={
                        saveClicked &&
                        formData.archieve &&
                        !formData.BackupDurationDays &&
                        "Backup Duration Days is required"
                      }
                      InputProps={{ inputProps: { min: 1, max: 30 } }} // Setting min and max values for the input
                    /> */}
                    <TextField
                      fullWidth
                      label="BackupDurationDays"
                      type="number"
                      value={formData.BackupDurationDays}
                      onChange={handleChange(index)("BackupDurationDays")}
                      error={
                        saveClicked &&
                        formData.IsActive &&
                        (!formData.BackupDurationDays ||
                          formData.BackupDurationDays < 1 ||
                          formData.BackupDurationDays > 30)
                      }
                      helperText={
                        saveClicked &&
                        formData.IsActive &&
                        (!formData.BackupDurationDays ||
                          formData.BackupDurationDays < 1 ||
                          formData.BackupDurationDays > 30) &&
                        "Backup Duration Days must be between 1 and 30"
                      }
                      InputProps={{
                        inputProps: { min: 1, max: 30 },
                      }}
                    />
                  </Grid>
                ) : null}

                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Events Backup Days"
                    type="number"
                    value={formData.EventsBackupDays}
                    onChange={handleChange(index)("EventsBackupDays")}
                    error={!!formErrors[`${index}.EventsBackupDays`]}
                    // helperText={formErrors[`${index}.fps`] || ""}
                  />

                  <FormHelperText style={{ color: "red" }}>
                    {formErrors[`${index}.EventsBackupDays`] || ""}
                  </FormHelperText>

                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="EscalatedEvents Backup Days"
                    type="number"
                    value={formData.EscalatedEventsBackupDays}
                    onChange={handleChange(index)("EscalatedEventsBackupDays")}
                    error={!!formErrors[`${index}.EscalatedEventsBackupDays`]}
                    // helperText={formErrors[`${index}.fps`] || ""}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {formErrors[`${index}.EscalatedEventsBackupDays`] || ""}
                  </FormHelperText>
                </Grid>

              </Grid>
              <Typography variant="subtitle2" sx={{ my: 2 }}>
                Camera Configuration
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Camera Make"
                    value={formData.CameraMake}
                    onChange={handleChange(index)("CameraMake")}
                    // error={!!formErrors[`${index}.CameraMake`]}
                    // helperText={formErrors[`${index}.CameraMake`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Camera Model"
                    value={formData.CameraModel}
                    onChange={handleChange(index)("CameraModel")}
                    // error={!!formErrors[`${index}.CameraModel`]}
                    // helperText={formErrors[`${index}.CameraModel`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Local HTTP URL"
                    value={formData.LocalHTTPURL}
                    onChange={handleChange(index)("LocalHTTPURL")}
                    // error={!!formErrors[`${index}.LocalHTTPURL`]}
                    // helperText={formErrors[`${index}.LocalHTTPURL`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Public HTTP URL"
                    value={formData.PublicHTTPURL}
                    onChange={handleChange(index)("PublicHTTPURL")}
                    // error={!!formErrors[`${index}.PublicHTTPURL`]}
                    // helperText={formErrors[`${index}.PublicHTTPURL`] || ""}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="success"
                        checked={formData.HaveSpeaker === 1 ? true : false}
                        onChange={handleSwitchChange(index)("HaveSpeaker")}
                      />
                    }
                    label="Speaker"
                  />
                </Grid>
              </Grid>
            </Card>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
}
DeviceForm.propTypes = {
  setOpen: PropTypes.func.isRequired
} 