// import { useState } from "react"
// import { jsonToParam, handleResponse, getURL } from "src/utils/midHandler";
import { jsonToParam } from "src/utils/config";
import { handleResponse } from "src/utils/responseHandler";
import { getURL } from "src/utils/config";
import axios from "../axios";
import { useState } from "react";
import dayjs from "dayjs";

// const baseURL = "http://192.168.30.66:8080"
// const getURL = (url) => `${baseURL}/${url}`

const useDashboardReports = () => {
  // const [ clientwiseData, setClientwiseData ] = useState({ data: [] })
  // const [ camerawiseData, setCamerawiseData ] = useState({ data: [] })
  // const [ agentwiseData, setAgentwiseData ] = useState({ data: [] })
  // const [ agetConsolidatedData, setAgnetConsolidatedData ] = useState({ data: [] })
  // const [ hourlyEvents, setHourlyEvents ] = useState({ data: [] })
  // const [ searchEvent, setSearchEvent ] = useState({ data: [] })

  const [currentDate, setCurrentDate] = useState(dayjs().startOf("day"));
  function formatDate(dateObj) {
    const date = dateObj.$d;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const formattedDate = formatDate(currentDate);

  const getClientwiseData = (formdata, callback) => {
    if (formdata) {
      axios({
        method: "get",
        url: getURL(`client-reports/client-wise?${jsonToParam(formdata)}`),
        validateStatus: (status) => handleResponse(status),
      }).then((response) => callback(response));
    }
  };

  const getCamerawiseData = (formdata, callback) => {
    axios({
      method: "get",
      url: getURL(`camera-reports/camera-wise?${jsonToParam(formdata)}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getAgentwiseData = (formdata, callback) => {
    axios({
      method: "get",
      url: getURL(`agent-reports/agent-wise?${jsonToParam(formdata)}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getAgnetConsolidatedData = (formdata, callback) => {
    axios({
      method: "get",
      url: getURL(`agent-reports/agent-consolidated?${jsonToParam(formdata)}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getHourlyEvents = (formdata, callback) => {
    axios({
      method: "get",
      url: getURL(`agent-reports/hourly-events?${jsonToParam(formdata)}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getSearchEvent = (formdata, callback) => {
    axios({
      method: "get",
      url: getURL(`search-event-reports/search-event?${jsonToParam(formdata)}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getBasicCountsReport = (callback) => {
    axios({
      method: "get",
      url: getURL(`camera-reports`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  const getActiveAgentCount = (callback) => {
    const body = {
      date: formattedDate,
    };
    axios({
      method: "get",
      url: getURL(`agent-reports/active-agents?${jsonToParam(body)}`),

      // url: getURL(`agent-reports/active-agents?date=${currentDate}`),
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };

  return [
    getClientwiseData,
    getCamerawiseData,
    getAgentwiseData,
    getAgnetConsolidatedData,
    getHourlyEvents,
    getSearchEvent,
    getBasicCountsReport,
    getActiveAgentCount,
  ];
};

export default useDashboardReports;
