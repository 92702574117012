// import PropTypes from 'prop-types';
// material
import {  styled } from '@mui/material/styles';
import { Card, Grid, Avatar, Typography, CardContent, Box } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import ReportTable from './ReportTable';

// ----------------------------------------------------------------------
const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});
const TitleStyle = styled(Typography)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});
const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 80,
  height: 80,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));
// const InfoStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'flex-end',
//   marginTop: theme.spacing(3),
//   color: theme.palette.text.disabled
// }));
const CoverImgStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

export default function AgentDataCard({ reportData}) {  

  const { data } = reportData
    return (
    <Grid item xs={12} sm={12} md={12}>
      <Card >
        <CardContent sx={{ p:0 }}>
              <ReportTable reportData={data}/>
        </CardContent>
      </Card>
    </Grid>
  );
}
