// import PropTypes from 'prop-types';
// // material
// import { Grid } from '@mui/material';
// import AuditListCard from './AuditListCard';
// import { AuditPlayer } from '../@dashboard/audit';
// import { useEffect, useState } from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';

// // ----------------------------------------------------------------------

// AuditList.propTypes = {
//   audits: PropTypes.array.isRequired,
//   loadMoreAudits: PropTypes.func.isRequired, // Expect a function to fetch more audits
// };

// export default function AuditList({
//   clientId,
//   audits,
//   loadMoreAudits,
//   updateToClient,
//   isEscalating,
//   ...other
// }) {
//   const agentType = localStorage.getItem('IsAgentBased');

//   const initValues = {
//     src: null,
//     isOpen: false,
//   };
//   const [player, setPlayer] = useState(initValues);
//   const [displayAudits, setDisplayAudits] = useState([]); // Audits to display
//   const [hasMore, setHasMore] = useState(true); // Controls infinite scroll

//   const ITEMS_PER_PAGE = 20;

//   console.log("displayAudits==========",displayAudits)
//   // Load the initial 20 audits on component mount
//   useEffect(() => {
//     console.log("================================================================",audits)
//     setDisplayAudits(audits.slice(0, ITEMS_PER_PAGE));
//   }, [audits]);

//   // Function to handle dialog opening for AuditPlayer
//   function handleDialog(audit) {
//     const isOpen = true;
//     setPlayer((plyr) => ({ ...AuditPlayer, isOpen, ...audit }));
//   }

//   // Load more audits when scrolling
//   const fetchMoreData = () => {
//     const newAudits = audits.slice(
//       displayAudits.length,
//       displayAudits.length + ITEMS_PER_PAGE
//     );

//     if (newAudits.length > 0) {
//       setDisplayAudits((prev) => [...prev, ...newAudits]);
//     } else {
//       setHasMore(false); // No more audits to load
//     }
//   };

//   return (
//     <InfiniteScroll
//       dataLength={displayAudits.length}
//       next={fetchMoreData}
//       hasMore={hasMore}
//       loader={<h4>Loading...</h4>}
//       style={{ width: '100%' }}
//     >
//       <Grid container spacing={1} {...other}>
//         <AuditPlayer
//           player={player}
//           setPlayer={setPlayer}
//           updateToClient={updateToClient}
//           isEscalating={isEscalating}
//           clientId={clientId}
//         />

//         {displayAudits.map((audit, idx) => (
//           <Grid key={idx} item xs={12} sm={6} md={4} lg={4}>
//             <AuditListCard audit={audit} handleDialog={handleDialog} />
//           </Grid>
//         ))}
//       </Grid>
//     </InfiniteScroll>
//   );
// }



import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import AuditListCard from './AuditListCard';
import { AuditPlayer } from '../@dashboard/audit';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

// ----------------------------------------------------------------------

AuditList.propTypes = {
  audits: PropTypes.array.isRequired,
  loadMoreAudits: PropTypes.func.isRequired, // Expect a function to fetch more audits
};

export default function AuditList({
  clientId,
  audits,
  loadMoreAudits,
  updateToClient,
  isEscalating,
  ...other
}) {
  const agentType = localStorage.getItem('IsAgentBased');

  const initValues = {
    src: null,
    isOpen: false,
  };
  const [player, setPlayer] = useState(initValues);
  const [displayAudits, setDisplayAudits] = useState([]); // Audits to display
  const [hasMore, setHasMore] = useState(true); // Controls infinite scroll

  const ITEMS_PER_PAGE = 20;

  // Initially load first 20 audits when audits prop changes
  useEffect(() => {
    setDisplayAudits(audits.slice(0, ITEMS_PER_PAGE));
    setHasMore(audits.length > ITEMS_PER_PAGE); // Check if there are more than 20 audits
  }, [audits]);

  // Function to handle dialog opening for AuditPlayer
  function handleDialog(audit) {
    const isOpen = true;
    setPlayer((plyr) => ({ ...AuditPlayer, isOpen, ...audit }));
  }

  // Load more audits when scrolling
  const fetchMoreData = () => {
    // Get the next batch of audits
    const nextBatch = audits.slice(
      displayAudits.length,
      displayAudits.length + ITEMS_PER_PAGE
    );

    // Append the next batch of audits to the displayAudits state
    if (nextBatch.length > 0) {
      setDisplayAudits((prev) => [...prev, ...nextBatch]);
    } else {
      setHasMore(false); // No more audits to load
    }
  };

  return (
    <InfiniteScroll
      dataLength={displayAudits.length} // Length of currently displayed audits
      next={fetchMoreData} // Function to call when scrolling
      hasMore={hasMore} // Controls if more data is available
      loader={<h4>Loading...</h4>}
      style={{ width: '100%' }}
    >
      <Grid container spacing={1} {...other}>
        <AuditPlayer
          player={player}
          setPlayer={setPlayer}
          updateToClient={updateToClient}
          isEscalating={isEscalating}
          clientId={clientId}
        />

        {displayAudits.map((audit, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={4}>
            <AuditListCard audit={audit} handleDialog={handleDialog} />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  );
}
