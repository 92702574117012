import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Tab,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import client from 'src/api/clients';
import { ClientForm, ClientList } from 'src/sections/client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ClientDetails from 'src/sections/client/ClientDetails';
// ----------------------------------------------------------------------

export default function Clients() {
  const [moremenu, setOpenMenu] = useState({ isOpen: false, id: null });

  const [clients, setClients] = useState({
    isLoad: false,
    all: [],
    india: [],
    us: [],
    active: [],
    inActive: [],
  });

  useEffect(() => {
    client.getClients((response) => {
      if (response.status === 200) {
        const allClients = response.data;
        const indiaClients = allClients.filter((row) => row.ClientType === 'INDIA');
        const usClients = allClients.filter((row) => row.ClientType === 'US');

        setClients({
          ...clients,
          all: allClients,
          india: indiaClients,
          us: usClients,
          active: allClients.filter((row) => row.Status === 1),
          inActive: allClients.filter((row) => row.Status === 0),
        });
      }
    });
  }, [clients.isLoad]);

  const reloadClientsData = () => setClients({ ...clients, isLoad: !clients.isLoad });

  const [dialog, setOpen] = useState({ isOpen: false, id: null });
  const handleDialog = (id = null) => setOpen({ ...dialog, isOpen: true, id });

  const [value, setValue] = useState('us');
  const handleChange = (event, newValue) => setValue(newValue);

  const handleMoremenu = (id = null) =>
    setOpenMenu({ ...moremenu, isOpen: true, id });

  return (
    <Page title="User">
      <Container maxWidth="xl">
        <ClientForm
          total={clients.all}
          dialogData={dialog}
          setOpen={setOpen}
          reloadClientsData={reloadClientsData}
        />
        <ClientDetails
          dialogData={moremenu}
          setOpen={setOpenMenu}
          reloadClientsData={reloadClientsData}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Clients
          </Typography>
          <Button
            variant="contained"
            onClick={() => handleDialog()}
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Client
          </Button>
        </Stack>

        <Card>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="Country tabs"
              sx={{
                borderBottom: '1px solid #ebebeb',
                backgroundColor: '#f3f3f3',
              }}
            >
              <Tab label="US" value="us" />
              <Tab label="INDIA" value="india" />
            </TabList>

            <TabPanel value="india" sx={{ p: 0 }}>
              <ClientList
                total={clients.india}
                active={clients.india.filter((row) => row.Status === 1)}
                inactive={clients.india.filter((row) => row.Status === 0)}
                handleDialog={handleDialog}
                reloadClientsData={reloadClientsData}
                handleMoremenu={handleMoremenu}
              />
            </TabPanel>

            <TabPanel value="us" sx={{ p: 0 }}>
              <ClientList
                total={clients.us}
                active={clients.us.filter((row) => row.Status === 1)}
                inactive={clients.us.filter((row) => row.Status === 0)}
                handleDialog={handleDialog}
                reloadClientsData={reloadClientsData}
                handleMoremenu={handleMoremenu}
              />
            </TabPanel>
          </TabContext>
        </Card>
      </Container>
    </Page>
  );
}
