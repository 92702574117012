import { Navigate, useRoutes } from 'react-router-dom';
import { useState } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import WindowLayout from './layouts/WindowLayout';

//
// import Blog from './pages/Blog';
// import User from './pages/User';
// import Audits from './pages/Audits';
import AuditLive from './pages/AuditLive';
import Live from './pages/Live';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import ListAudits from './pages/ListAudits';
import DashboardApp from './pages/DashboardApp';
import Analytics from './pages/Analytics';
import Notifications from './pages/Notifications';
import MonTimings from './pages/MonTimings';
import LicenceUpgrade from './pages/LicenceUpgrade';
import Cameras from './pages/Cameras';
import { isAccess } from './utils/responseHandler';
import Clients from './pages/Clients';
import Devices from './pages/Devices';
import LiveCameras from './pages/LiveCameras';
import EmpProductivity from './pages/EmpProductivity';
import Servers from './pages/Servers';
import JKODashboard from './pages/JKODashboard';
import AutoLogin from './pages/AutoLogin';
import Reports from './pages/Reports';
import JFWReport from './pages/JFWReport';
import DevicesReport from './pages/DevicesReport';
import HeadCountReport from './pages/HeadCountReport';
import HeadCountDashboard from './pages/HeadCountDashboard';
import HeadCountInput from './pages/HeadCountInput';
import LicensePlate from './pages/LicensePlate';
import Timelaps from './pages/Timelaps';
import ShiftTme from './pages/ShiftTme';
// import NetrawalaEntry from './pages/netrawalaEntry';
// ----------------------------------------------------------------------
import DeploymentReports from './pages/DeploymentReports';
import Groupsdetails from './sections/settings/Groupsdetails';
import Protocols from './sections/settings/protocols';
import VideoArchives from './pages/VideoArchives';
import ClientContacts from './sections/clientContacts/ClientContacts';
import DashBoardDepApp from './pages/DashBoardDepApp';
import AddAgent from './sections/settings/AddAgent';
import DashBoardMdPSIT from './pages/DashBoardMdPSIT';
//
import ClientBilling from './sections/Billing/ClientBilling';
import ClientAddPlan from './sections/Billing/ClientAddPlan';
import ViewPlans from './sections/Billing/ViewPlans';
import ReportsInfc from './pages/ReportsInfc';
import VerificationPage from './pages/verificationPage';
import ClientDeviceForm from './sections/devices/ClientDeviceForm';
import { element } from 'prop-types';
import MdReports from './sections/reports/MdReports';
// Mohini 
import AddShow from './sections/Mohini/AddShow';
import MohiniReports from './sections/Mohini/MohiniReports';
import MohiniDashBoard from './sections/Mohini/MohiniDashBoard';
//New login for same clients
import AddNewLogin from './sections/settings/AddNewLogin';
import ClientConsolidationLogin from './sections/settings/ClientConsolidationLogin';

import Dashboard from './pages/Dashboard';

import NetrawalaEntry from './pages/netrawalaEntry';
import AddShiftsNeterwala from './pages/addShiftsNeterwala';
import NetrawalaEntryMonthly from './pages/netrawalaEntryMonthly';


//Event Reports
import FalseEvents from './sections/EventReports/FalseEvents';
import IndividualEvents from './sections/EventReports/IndividualEvents';
import TotalEvents from './sections/EventReports/TotalEvents';

import ClientWiseCEC from './pages/clientWise/ClientWiseCEC';
import ClientWiseGEC from './pages/clientWise/ClientWiseGEC';
import ClientWiseLC from './pages/clientWise/ClientWiseLC';

// camera Wise
import CameraWiseCompEC from './pages/CameraWise/CameraWiseCompEC';
import CameraWiseFEC from './pages/CameraWise/CameraWiseFEC';
import CameraWiseGEC from './pages/CameraWise/CameraWiseGEC';
import CameraWiseLC from './pages/CameraWise/CameraWiseLC';
import CameraWiseTEC from './pages/CameraWise/CameraWiseTEC';

// agent wise
import AgentWiseReport from './pages/AgentWiseReport';
import AgentWiseLatency from './pages/AgentWise/AgentwiseLatency';
import AgentwiseTEC from './pages/AgentWise/AgentwiseTEC';
import AgentwiseFEC from './pages/AgentWise/AgentwiseFEC';

// agent consolidated
import TotalEscalatedAGC from './pages/AgentConsolidated/TotalEscalatedAGC';
import TotalEventsAGC from './pages/AgentConsolidated/TotalEventsAGC';
import FalseEventsAGC from './pages/AgentConsolidated/FalseEventsAGC';
import TrueEventsAGC from './pages/AgentConsolidated/TrueEventsAGC';

import ClientAssign from './pages/ClientAssign';


const userValues = {
    "access_token": localStorage.getItem('access_token'),
    "token_type": localStorage.getItem('token_type'),      
    "ID": localStorage.getItem('ID'),
    "IsLicenseBase": localStorage.getItem('IsLicenseBase'),
    "IsAgentBased": localStorage.getItem('IsAgentBased'),
    "name": localStorage.getItem('name'),
    "displayname": localStorage.getItem('displayname'),
    "logo_url": localStorage.getItem('logo_url'),
    "user_type": localStorage.getItem('user_type'),
    "client_id": localStorage.getItem('client_id')
}



export default function Router() {
  const [token, setToken] = useState(localStorage.getItem('access_token'))  
  const [userType, setUserType] = useState(localStorage.getItem('user_type'))
  const [userData, setuserData] = useState(userValues)
  const [access, setAccess] = useState(isAccess())  

  const DASH_LAYOUT = [{
    path: '/',
    element: <DashboardLayout setToken={setToken} userType={userType} userData={userData} />,
    children: [
      { path: '/', element: (Number(userData.client_id) === 733  || Number(userData.client_id) === 917 )? <JKODashboard/> :
                                                                    Number(userType) === 9 ? <Clients clientId={userData.client_id} userType={userData.user_type} ></Clients>:
                                                                    //Number(userType) === 8 ? <Navigate to="/dashboard" replace />:
                                                                    Number(userType) === 8 ? <Dashboard />:
                                                                   
                                                                    Number(userType) === 37 ? <DashBoardMdPSIT clientId={userData.client_id} userType={userData.user_type}/>:
                                                                    // Number(userType) === 863 ?<DashboardApp clientId={userData.client_id} userType={userData.user_type} />:
                                                                    Number(userData.IsAgentBased) === 0 &&  Number(userData.client_id) !== 883 ?<DashboardApp clientId={userData.client_id} userType={userData.user_type} />:
                                                                    Number(userData.client_id) === 883 ?<MohiniDashBoard  clientId={userData.client_id}/>:
                                                                    <DashBoardDepApp clientId={userData.client_id} userType={userData.user_type}/> },

      // { path: '/', element: Number(userData.client_id) === 733 ? <JKODashboard/> :<DashBoardDepApp clientId={userData.client_id} userType={userData.user_type} /> },
      // { path: '/', element: Number(userData.client_id) === 765 ? <NetrawalaEntry/> :<DashboardApp clientId={userData.client_id} userType={userData.user_type} /> },
      { path: 'escalatedEvents', element:((userType!=='9' && userType !=='8')? <ListAudits userType={userData.user_type} setToken={setToken} clientId={userData.client_id} IsAgentBased={userData.IsAgentBased}/> :<NotFound/> )},
      { path: 'audits', element:((userType!=='9' && userType !=='8')? <ListAudits userType={userData.user_type} setToken={setToken} clientId={userData.client_id} IsAgentBased={userData.IsAgentBased}/> :<NotFound/> )},

      // { path: 'products', element: <ListAudits /> },
      { path: 'live-audits', element: <AuditLive /> },
      { path: 'DashboardDep', element: <DashBoardDepApp /> },
      { path: 'DashboardMdPSIT', element: <DashBoardMdPSIT /> },
      { path: 'Dashboardapp', element: <DashboardApp /> },
      { path: 'MohiniDashboard', element: <MohiniDashBoard /> },
      { path: 'analytics', element:(userType!=='2' ? <Analytics userType={9} /> : <Navigate to="/404" replace />) },
      { path: 'mon-timings', element: <MonTimings /> },
      { path: 'camerasInfo', element:(userType!=='9' ? <Cameras /> : <Navigate to="/404" replace /> )},
      { path: 'live', element: <LiveCameras /> },
      { path: 'clients', element:( userType!=='2' ? <Clients />: <Navigate to="/404" replace /> )},
      { path: 'cameras', element:(userType!=='2' ? <Devices/>: <Navigate to="/404" replace />) },
      { path: 'emp-productivity', element: <EmpProductivity /> },
      { path: 'jfw-reports', element: <JFWReport /> },
      { path: 'servers', element:(userType!=='2' ? <Servers />:<Navigate to="/404" replace />) },
      { path: 'reports', element: <Reports /> },
      { path: 'reportsinfc', element: <ReportsInfc /> },
      { path: 'head-count-report', element: <HeadCountReport /> },
      { path: 'head-count-dashboard', element: <HeadCountDashboard /> },
      { path: 'head-count-input', element: <HeadCountInput /> },
      { path: 'deploymentReports', element:(userType!=='2' ? <DeploymentReports />:<Navigate to="/404" replace /> )},
      { path: 'devices-report', element: <DevicesReport /> },
      { path: 'notifications/*', element: <Notifications />},
      { path: 'license-plate', element: <LicensePlate /> },
      { path: 'addAgent', element: (userType!=='2' ? <AddAgent /> : <Navigate to="/404" replace />) },
      //{ path: 'Add-Agent', element: (userType !== '2' ? (userType === '8' ? <Navigate to="/dashboard" replace /> : <AddAgent />) : <Navigate to="/404" replace />) },
      { path: 'loginCreations', element: (userType!=='2' ? <AddNewLogin /> : <Navigate to="/404" replace />) },
      { path: 'clientConsolidationlogin', element: (userType!=='2' ? <ClientConsolidationLogin /> : <Navigate to="/404" replace />) },
      { path: 'clientassign', element: (userType!=='2' ? <ClientAssign /> : <Navigate to="/404" replace />) },

      { path: 'timelaps', element: <Timelaps /> },
      { path: 'shifttime', element: <ShiftTme /> },
      // { path: 'group-settings', element: <Groups /> },
      { path: 'neterwala', element: <NetrawalaEntry /> },
      { path: 'neterwalaMonth', element: <NetrawalaEntryMonthly /> },   
      { path: 'addshifts', element: <AddShiftsNeterwala /> },
      
      { path: 'groups', element:(userType!=='2' ? <Groupsdetails/> : <Navigate to="/404" replace />)},
      { path: 'protocols', element:(userType!=='2' ? <Protocols/> :<Navigate to="/404" replace />)},
      { path: 'videoArchives', element:(userType!=='9' ? <VideoArchives/> :<Navigate to="/404" replace />)},
      { path: 'contacts', element:(userType!=='9' ? <ClientContacts /> : <Navigate to="/404" replace /> )},
      { path: 'generateBillForClient', element:(userType!=='2' ? <ClientBilling/> : <Navigate to="/404" replace />)},
      { path: 'addPlanToTheClient', element:(userType!=='2' ? <ClientAddPlan/> : <Navigate to="/404" replace />)},
      { path: 'viewPLans', element:(userType!=='2' ? <ViewPlans/> : <Navigate to="/404" replace />)},
      { path: 'register', element: <Register/>},
      { path:'add-client-camera',element:<ClientDeviceForm/>},
      { path: 'client-analytics',element:<Analytics/>},
      { path: 'mdReport', element: <MdReports/>}, 

      // Mohini
      { path: 'addShow', element: <AddShow/>},
      { path: 'mohiniReport', element: <MohiniReports/>},

      //event reports
      { path: 'falseEvents', element: <FalseEvents/> }, 
      { path: 'individualEvents', element: <IndividualEvents/>}, 
      { path: 'totalEvents', element: <TotalEvents/>}, 
      
      { path: 'dashboard', element: <Dashboard/>}, 

      { path: 'auto-login', element: <AutoLogin setToken={setToken} setAccess={setAccess} setUserType={setUserType} setuserData={setuserData} /> },
      // { path: '*', element: <Navigate to={Number(userData.user_type) === 30 ? "/": "/audits"} replace /> },
      // { path: '*', element: <Navigate to={Number(userData.client_id) === 765 ? "/neterwala": "/neterwala"} replace /> }
      {
        path: '*',
        element: Number(userData.client_id) === 765
          ? <Navigate to="/neterwala" replace />
          : Number(userData.user_type) === 30
            ? <Navigate to="/" replace />
            : Number(userData.user_type) === 9
              ? <Clients  userType={userData.user_type} />
              : Number(userData.user_type) === 37
              ? <Navigate to="/DashboardMdPSIT" replace /> :
               Number(userData.client_id) === 863 
               ? <Navigate to="/Dashboardapp" replace /> 
               : Number(userData.client_id) === 883 ?
               <Navigate to="/MohiniDashboard" replace />
              : Number(userData.user_type) === 8 ?
               <Navigate to="/dashboard" replace/>
              :  Number(userData.IsAgentBased) === 0 ? <Navigate to="/audits" replace />
              :<Navigate to="/escalatedEvents" replace />
      }
      
      // i want if userType 9 then redirect to Dashboard 
      
    ]
  },
  {
    path: '/',
    element: <WindowLayout />,
    children: [
      { path: 'audit-live/:gridview', element: <Live/> },
      { path: '404', element: <NotFound /> },
      // { path: 'licence', element: <LicenceUpgrade /> },      
      { path: '*', element: <Navigate to="/404" /> },

       // Dashboard paths for ops Client wise reports
       { path: '/clientwiseGEC-report/:date/:clientid/:hour', element: <ClientWiseGEC />},
       { path: '/clientwiseCEC-report/:date/:clientid/:hour', element: <ClientWiseCEC /> },
       { path: '/clientwiseLEC-report/:date/:clientid/:hour/:latency', element: <ClientWiseLC />},

       // Dashboard paths for ops Camera wise reports
      { path: '/camerawiseGEC-report/:date/:deviceid', element: <CameraWiseGEC /> },
      { path: '/camerawiseCEC-report/:date/:deviceid', element: <CameraWiseCompEC /> },
      { path: '/camerawiseTEC-report/:date/:deviceid', element: <CameraWiseTEC /> },
      { path: '/camerawiseFEC-report/:date/:deviceid', element: <CameraWiseFEC /> },
      { path: '/camerawiseLEC-report/:date/:deviceid/:latency', element: <CameraWiseLC /> },

      // ops agentwise report
      { path: '/agentwise-report/:date/:agentId/:hour', element: <AgentWiseReport /> },
      { path: '/agentwiseLatency-report/:date/:agentId/:hour', element: <AgentWiseLatency /> },
      { path: '/agentwise-tec/:date/:agentId/:hour', element: <AgentwiseTEC /> },
      { path: '/agentwise-fec/:date/:agentId/:hour', element: <AgentwiseFEC /> },

      // ops agent consolidated
      { path: '/agcTotalEvents-report/:date/:agentId', element: <TotalEventsAGC /> },
      { path: '/agcFalseEvents-report/:date/:agentId', element: <FalseEventsAGC /> },
      { path: '/agcTrueEvents-report/:date/:agentId', element: <TrueEventsAGC /> },
      { path: '/agcTotalEscalated-report/:date/:agentId', element: <TotalEscalatedAGC /> },

    ]
  }
]

  const LOGO_LAYOUT = [{
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Navigate to="/login" /> },
      { path: 'auto-login', element: <AutoLogin setToken={setToken} setAccess={setAccess} setUserType={setUserType} setuserData={setuserData} /> },
      { path: 'login', element: <Login setToken={setToken} setAccess={setAccess} setUserType={setUserType} setuserData={setuserData} /> },
      { path: 'register', element: <Register/>},
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/login" /> },
      { path: 'verificationpage', element: <VerificationPage/>}

    ]
  }]

  const UPGRADE_LAYOUT = [{
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Navigate to="/licence" /> },
      { path: 'auto-login', element: <AutoLogin setToken={setToken} setAccess={setAccess} setUserType={setUserType} setuserData={setuserData} /> },
      { path: 'login', element: <Login setToken={setToken} setAccess={setAccess} setUserType={setUserType} setuserData={setuserData}/> },
      { path: 'register', element: <Register/>},
      { path: 'licence', element: <LicenceUpgrade /> },
      { path: '404', element: <NotFound /> },
      { path: 'verificationpage', element: <VerificationPage/>},
      { path: '*', element: <Navigate to="/licence" /> }
    ]
  }]
  
  const DEFAULT_RDT = { path: '*', element: <Navigate to="/404" replace /> }
  
  const RUTS = [...token  ? (access ? DASH_LAYOUT : UPGRADE_LAYOUT) : LOGO_LAYOUT, DEFAULT_RDT]
  return useRoutes(RUTS);
}