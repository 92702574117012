// material
import {
  Card,
  Container,
  Grid,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useState, useReducer, useEffect } from "react";
import useDashboardReports from "src/api/useDashboardReports";
// import { CounterCard, FilterBox, ResultTable } from "src/sections/dashboard";
import CounterCard from "src/sections/dashboard/CounterCard";
import FilterBox from "src/sections/dashboard/FilterBox";
import ResultTable from "src/sections/dashboard/ResultTable";
import dayjs from "dayjs";
// import { format } from 'date-fns';
import CardCountDetails from "./AgentConsolidated/CardCountDetails";

// components
import Page from "../components/Page";
import { tr } from "date-fns/locale";
import { act } from "react";

export default function Dashboard() {
  // console.log('Live: ', loadCameras)
  // const [activeTab, setActiveTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [newLatencyVal, setlatancyVal] = useState(1);
  const [noOfAudits, setNoOfAudits] = useState(60);
  const [dayNoOfAudityUs, setDayNoOfAudity] = useState(500);
  const [typeOfAudits, setTypeOfAuidts] = useState("Hourly");

  const [isopen, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [count, setCount] = useState(0);

  const [isDataAvailable, setIsDataAvailable] = useState(true);

  const handleClickOpen = (row) => {
    setDialogContent(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TABS = [
    {
      tab: 0,
      name: "Client Wise",
      tableHeads: [
        { id: "sno", label: "S No", alignRight: false, dataType: "text" },
        {
          id: "DisplayName",
          label: "Client",
          alignRight: false,
          dataType: "text",
        },
        { id: "date", label: "Date", alignRight: false, dataType: "text" },
        { id: "hour", label: "Hour", alignRight: false, dataType: "text" },
        {
          id: "generatedeventscount",
          label: "Generated Events Count",
          alignRight: false,
          dataType: "link",
          link: `clientwiseGEC-report/${selectedDate}/{clientid}/{hour}`,
        },
        {
          id: "completedeventscount",
          label: "Completed Events Count",
          alignRight: false,
          dataType: "link",
          link: `clientwiseCEC-report/${selectedDate}/{clientid}/{hour}`,
        },
        {
          id: "latency",
          label: "Latency Count",
          alignRight: false,
          dataType: "link",
          link: `clientwiseLEC-report/${selectedDate}/{clientid}/{hour}/${newLatencyVal}`,
        },
        {
          id: "averagetimeperaudit",
          label: "Latency",
          alignRight: false,
          dataType: "text",
        },
      ],
      data: [],
      footerKeys: [],
      footer: [],
      colSpan: 0,
      title: "Total",
    },
    {
      tab: 1,
      name: "Camera Wise",
      tableHeads: [
        { id: "", label: "S No", alignRight: false, dataType: "text" },
        {
          id: "devicename",
          label: "Camera Name",
          alignRight: false,
          dataType: "text",
        },
        { id: "date", label: "Date", alignRight: false, dataType: "text" },
        {
          id: "generatedeventscount",
          label: "Generated Events Count",
          alignRight: false,
          dataType: "link",
          link: "camerawiseGEC-report/{date}/{deviceid}",
        },
        {
          id: "completedeventscount",
          label: "Completed Events Count",
          alignRight: false,
          dataType: "link",
          link: "camerawiseCEC-report/{date}/{deviceid}",
        },
        {
          id: "trueeventscount",
          label: "True Events Count",
          alignRight: false,
          dataType: "link",
          link: "camerawiseTEC-report/{date}/{deviceid}",
        },
        {
          id: "falseeventscount",
          label: "False Events Count",
          alignRight: false,
          dataType: "link",
          link: "camerawiseFEC-report/{date}/{deviceid}",
        },
        {
          id: "latency",
          label: "Latency Count",
          alignRight: false,
          dataType: "link",
          link: `camerawiseLEC-report/{date}/{deviceid}/${newLatencyVal}`,
        },
        { id: "art", label: "ART", alignRight: false, dataType: "text" },
        { id: "aht", label: "AHT", alignRight: false, dataType: "text" },
      ],
      data: [],
      footerKeys: [
        "GeneratedEventsSum",
        "CompletedEventsSum",
        "TrueEventsSum",
        "FalseEventsSum",
        "LatencySum",
        "ARTSum",
        "AHTSum",
      ],
      footer: [],
      colSpan: 2,
      title: "Total",
    },
    {
      tab: 2,
      name: "Agent wise",
      tableHeads: [
        { id: "sno", label: "S No", alignRight: false },
        {
          id: "agentname",
          label: "Agent Name",
          alignRight: false,
          dataType: "text",
        },
        { id: "date", label: "Date", alignRight: false, dataType: "text" },
        { id: "agenthour", label: "Hour", alignRight: false, dataType: "text" },
        {
          id: "eventscount",
          label: "Events",
          alignRight: false,
          dataType: "link",
          link: "agentwise-report/{date}/{agentid}/{agenthour}",
        },
        {
          id: "trueevents",
          label: "True Events",
          alignRight: false,
          dataType: "link",
          link: "agentwise-tec/{date}/{agentid}/{agenthour}",
        },
        {
          id: "falseevents",
          label: "False Events",
          alignRight: false,
          dataType: "link",
          link: "agentwise-fec/{date}/{agentid}/{agenthour}",
        },
        {
          id: "latencycount",
          label: "Latency",
          alignRight: false,
          dataType: "link",
          link: "agentwiseLatency-report/{date}/{agentid}/{agenthour}",
        },
        {
          id: "averagereviewtimeperaudit",
          label: "ART",
          alignRight: false,
          dataType: "text",
        },
        { id: "aht", label: "AHT", alignRight: false, dataType: "text" },
      ],
      data: [],
      footerKeys: [],
      footer: [],
      colSpan: 2,
      title: "Total",
    },
    {
      tab: 3,
      name: "Agent Consolidated",
      tableHeads: [
        { id: "", label: "S No", alignRight: false, dataType: "text" },
        {
          id: "agentname",
          label: "Agent Name",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "totalevents",
          label: "Total Events",
          alignRight: false,
          dataType: "link",
          link: "agcTotalEvents-report/{date}/{agentid}",
        },
        {
          id: "trueevents",
          label: "True Events",
          alignRight: false,
          dataType: "link",
          link: "agcTrueEvents-report/{date}/{agentid}",
        },
        {
          id: "falseevents",
          label: "False Events",
          alignRight: false,
          dataType: "link",
          link: "agcFalseEvents-report/{date}/{agentid}",
        },
        {
          id: "totalescalated",
          label: "Total Escalated",
          alignRight: false,
          dataType: "link",
          link: "agcTotalEscalated-report/{date}/{agentid}",
        },
        { id: "art", label: "ART", alignRight: false, dataType: "text" },
        { id: "aht", label: "AHT", alignRight: false, dataType: "text" },
      ],
      data: [],
      footerKeys: [
        "TotalEventsSum",
        "TrueEventsSum",
        "FalseEventsSum",
        "TotalEscalatedSum",
        "ARTSum",
        "AHTSum",
      ],
      footer: [],
      colSpan: 1,
      title: "Total",
    },
    {
      tab: 4,
      name: "Hourly Events",
      tableHeads: [
        { id: "", label: "S No", alignRight: false, dataType: "text" },
        { id: "hour", label: "Hour", alignRight: false, dataType: "text" },
        {
          id: "generated",
          label: "Generated",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "completed",
          label: "Completed",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "agents",
          label: "No of Agents",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "activecameras",
          label: "No of Active Cameras",
          alignRight: false,
          dataType: "text",
        },
      ],
      data: [],
      footerKeys: [],
      footer: [],
      colSpan: 0,
      title: "",
    },
    {
      tab: 5,
      name: "Search Event",
      tableHeads: [
        { id: "", label: "S No", alignRight: false, dataType: "text" },
        {
          id: "preview",
          label: "Preview",
          alignRight: false,
          dataType: "image",
        },
        {
          id: "eventno",
          label: "Event No",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "camera",
          label: "Camera Name",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "eventraised",
          label: "Event Raised",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "reviewstart",
          label: "Review Start Time",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "reviewend",
          label: "Review End Time",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "Latency",
          label: "Latency",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "reviewedby",
          label: "Reviewed By",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "EventType",
          label: "Agent Disposition",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "SupervisorEscalated",
          label: "is Escalated?",
          alignRight: false,
          dataType: "button",
        }, // Changed dataType to 'button'
        {
          id: "IsAnnounced",
          label: "is Announced?",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "IsCalled",
          label: "is called?",
          alignRight: false,
          dataType: "text",
        },
        {
          id: "Comments",
          label: "Comments",
          alignRight: false,
          dataType: "text",
        },
      ],
      data: [],
      footerKeys: [],
      footer: [],
      colSpan: 0,
      title: "",
    },
  ];

  const COUNTS = [
    {
      count: 0,
      title: "Camera Active (In Process)",
      icon: "mdi:camera-wireless",
      color: "primary",
    },
    {
      count: 0,
      title: "ACTIVE AGENTS",
      icon: "mdi:account-group",
      color: "info",
    },
    {
      count: 0,
      title: "CATEGORY 1",
      icon: "mdi:alert-octagon",
      color: "error",
    },
    {
      count: 0,
      title: "CATEGORY 2",
      icon: "mdi:bell-alert",
      color: "warning",
    },
    {
      count: 0,
      title: "CATEGORY 3",
      icon: "mdi:account-alert",
      color: "warning",
    },
    {
      count: 0,
      title: "NOT ASSIGNED",
      icon: "mdi:account-clock",
      color: "primary",
    },
  ];

  const [
    getClientwiseData,
    getCamerawiseData,
    getAgentwiseData,
    getAgnetConsolidatedData,
    getHourlyEvents,
    getSearchEvent,
    getBasicCountsReport,
    getActiveAgentCount,
  ] = useDashboardReports();
  const FILTER_DEFAULT_VALUES = {
    latency: newLatencyVal,
    noOfAudits: noOfAudits,
    dayNoOfAudity: dayNoOfAudityUs,
    noOfAuditsTypes: typeOfAudits,
    eventNo: "",
  };
  const [filterForms, setFilterForms] = useState({
    reportDate: dayjs(new Date()),
    ...FILTER_DEFAULT_VALUES,
  });

  // const [filterTrack, setFilterTrack] = useState({
  //   reportDate: dayjs(new Date())
  // });

  const [basicCounts, setBasicCounts] = useState([...COUNTS]);
  const [displayPagination, setDisplayPagination] = useState(true);

  const hadnleActiveReport = (state, action) => {
    setFilterForms({ ...filterForms, ...FILTER_DEFAULT_VALUES });
    switch (action.activekey) {
      case 0:
        setDisplayPagination(true);
        return { ...TABS[0], data: action.data };
      case 1:
        setDisplayPagination(true);
        return { ...TABS[1], data: action.data, footer: action.footer };
      case 2:
        setDisplayPagination(true);
        return { ...TABS[2], data: action.data };
      case 3:
        setDisplayPagination(true);
        return { ...TABS[3], data: action.data, footer: action.footer };
      case 4:
        setDisplayPagination(true);
        return { ...TABS[4], data: action.data };
      case 5:
        setDisplayPagination(true);
        return { ...TABS[5], data: action.data };
      default:
        setDisplayPagination(true);
        return { ...TABS[0], data: action.data };
    }
  };

  const [activeTab, setActiveTab] = useReducer(hadnleActiveReport, {
    ...TABS[0],
  });
  const [actualActiveTab, setActualActiveTab] = useState(0);

  useEffect(() => {
    setActualActiveTab(activeTab.tab);
  }, [activeTab.tab]);

  const handleFilterBox1 = (event, _ = null, name = null) => {
    if (name) filterForms[name] = event;
    // else if(event.target.name === 'latency')
    else filterForms[event.target.name] = event.target.value;
    if (event.target.name === "latency") {
      setlatancyVal(event.target.value);
    }

    setFilterForms({ ...filterForms });
  };

  const handleFilterBox = (event, _ = null, name = null) => {
    if (name) {
      filterForms[name] = event;

      // if(name === "reportDate"){
      //   setFilterForms((prv)=>({
      //     ...prv,
      //     [name]:event
      //   }));
      // }


    } else if (event && event.target) {
      const { name, value } = event.target;
      filterForms[name] = value;

      if (name === "latency") {
        setlatancyVal(value);
      } else if (name === "noOfAudits") {
        setNoOfAudits(value);
      } else if (name === "noOfAuditsTypes") {
        setTypeOfAuidts(value);
      } else if (name === "dayNoOfAudity") {
        setDayNoOfAudity(value);
      }

      setFilterForms({ ...filterForms });
    }
  };

  // useEffect(()=>{
  //   //debugger
  //   console.log(filterTrack)
  // },[filterTrack])

  const stringTimeToTime = (timee) => {
    let now = new Date(0);
    const [h, m, s] = timee.split(":").map((item) => Number(item));
    now.setHours(
      now.getHours() + h,
      now.getMinutes() + m,
      now.getSeconds() + s
    );
    return now.getTime();
  };

  const timeToString = (time) =>
    String(new Date(time).toISOString()).substring(11, 19);

  //     const handleResponseData = (data, columns, mainColumn, suTotalCols=[], timeSubTotalCols=[]) => {
  //         //debugger
  //         const finalResult = []
  //         const lastValues = {}
  //         const subTotals = {}
  //         columns.forEach((val) => lastValues[val] = { keyName: '', count: 0, index: null})
  //         // suTotalCols.forEach((val) => subTotals[val] = 0)
  //         data.forEach((row) => {

  //             let item = {...row}
  //             let isFinesh = false
  //             columns.forEach((column) => {
  //                 if (lastValues[column].keyName === item[column]) {
  //                     item[column] = { rowSpam: 0, columnValue: ''}
  //                     // if (finalResult[lastValues[column].index][column].rowSpam === 0)
  //                     //     finalResult[lastValues[column].index][column].rowSpam = 1
  //                     finalResult[lastValues[column].index][column].rowSpam += 1
  //                 } else {
  //                     if (lastValues[column].keyName !== '' && mainColumn === column)
  //                         isFinesh = true
  //                     //     finalResult[lastValues[column].index][column].rowSpam = lastValues[column].count
  //                     console.log(lastValues[column].keyName, '|', item[column])
  //                     lastValues[column].keyName = item[column]
  //                     // lastValues[column].count = 1
  //                     lastValues[column].index = finalResult.length
  //                     item[column] = {rowSpam: 1, columnValue: item[column]}
  //                 }
  //                 // lastValues[column].count += 1
  //             })

  //             if (isFinesh) {
  //                 columns.forEach((column) => {
  //                     if (column !== mainColumn) {
  //                         if (finalResult.length > lastValues[column].index)
  //                             finalResult[lastValues[column].index][column].rowSpam -= 1
  //                         lastValues[column].keyName = ''
  //                     }
  //                     lastValues[column].index = finalResult.length+1
  //                     item[column] = {rowSpam: 1, columnValue: row[column]}
  //                 })
  //                 // finalResult.push({...row, client: 'Sub Total'})
  //             }

  //             const subTot = {...row}
  //             suTotalCols.concat(timeSubTotalCols).forEach((val) => {
  //                 if (!subTotals.hasOwnProperty(val))
  //                     subTotals[val] = 0
  //                 if (isFinesh) {
  //                     subTot[val] = subTotals[val]
  //                     if (timeSubTotalCols.includes(val))
  //                         subTot[val] = timeToString(subTotals[val])
  //                     subTotals[val] = 0
  //                 }
  //                 let value = Number(row[val]) || 0
  //                 if (timeSubTotalCols.includes(val)){
  //                     value = stringTimeToTime(row[val])
  //                     // console.log('value', value, row[val])
  //                 }
  //                 subTotals[val] += value
  //             })

  //             if (isFinesh) {
  //                 // console.log('Finish')
  //                 finalResult.push({...subTot, client: 'Sub Total'})
  //             }

  // //       // Adjust rowspan based on search and set empty values
  // //       if (item.client.columnValue === '' && item.date.columnValue === '') {
  // //         item.client.columnValue = subTot.client;
  // //         item.date.columnValue = subTot.date;
  // //         finalResult.push(item);
  // //       } else {
  // //         finalResult.push(item);
  // //       }
  // //     });

  //             // console.log('item', item)
  //             finalResult.push(item)
  //         })
  //         // console.log('finalResult', finalResult)
  //         return finalResult
  //     }

  function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return [hours, minutes, seconds]
      .map((unit) => String(unit).padStart(2, "0"))
      .join(":");
  }

  const handleResponseData = (
    data,
    columns,
    mainColumn,
    suTotalCols = [],
    timeSubTotalCols = []
  ) => {
    setSelectedDate("");
    // //debugger
    let finalResult = [];
    let subTotals = {};
    let currentClient = "";
    let isFirstRow = true;
    let sno = 1;

    let generatedeventscount = 0,
      completedeventscount = 0,
      latency = 0;

    data.forEach((row, index) => {
      const newRow = { ...row };

      generatedeventscount += newRow.generatedeventscount;
      completedeventscount += newRow.completedeventscount;
      latency += newRow.latency;

      // Check if it's the first row of a new client
      if (row.client !== currentClient) {
        // If it's not the first client, push subtotals for the previous client
        if (currentClient) {
          const subtotalRow = {
            client: "Sub Total",
            date: "",
            hour: "Sub Total",
          };
          suTotalCols.concat(timeSubTotalCols).forEach((col) => {
            if (!subTotals[col]) {
              if (col === "averagetimeperaudit") {
                subTotals[col] = "";
              } else {
                subTotals[col] = 0;
              }
            }
            subtotalRow[col] = subTotals[col];
          });
          finalResult.push(subtotalRow);
        }

        currentClient = row.client;
        isFirstRow = true; // Reset to true for the new client group

        // Push client name and date followed by values only for the first row of each client group
        const clientHeaderRow = {
          sno: isFirstRow ? sno++ : "",
          client: isFirstRow ? { rowSpan: 0, columnValue: row.client } : null,
          date: isFirstRow ? { rowSpan: 0, columnValue: row.date } : null,
          ...row,
        };
        finalResult.push(clientHeaderRow);

        // Reset subtotals for the new client
        subTotals = {};
      } else {
        // Remove client and date properties from the row if it's not the first row of the group
        delete newRow.DisplayName;
        delete newRow.date;
      }

      // For the last row of each client, calculate subtotals
      if (
        index === data.length - 1 ||
        data[index + 1]?.client !== currentClient
      ) {
        suTotalCols.concat(timeSubTotalCols).forEach((col) => {
          if (!subTotals[col]) {
            subTotals[col] = 0;
          }
          if (col === "generatedeventscount") {
            subTotals[col] = generatedeventscount;
          } else if (col === "completedeventscount") {
            subTotals[col] = completedeventscount;
          } else if (col === "latency") {
            subTotals[col] = latency;
          } else if (col === "averagetimeperaudit") {
            subTotals[col] = " ";
          }
        });
        generatedeventscount = 0;
        completedeventscount = 0;
        latency = 0;
      }

      // Push the current row if it's not the first row of the group
      if (!isFirstRow) {
        finalResult.push(newRow);
      } else {
        isFirstRow = false; // Reset isFirstRow flag for subsequent rows in the group
      }
    });

    // Push subtotals for the last client
    if (currentClient) {
      const subtotalRow = { client: "Sub Total", date: "", hour: "Sub Total" };
      suTotalCols.concat(timeSubTotalCols).forEach((col) => {
        if (!subTotals[col]) {
          subTotals[col] = 0;
        }
        subtotalRow[col] = subTotals[col];
      });
      finalResult.push(subtotalRow);
      subTotals = {};
    }

    let generatedeventscountSum = 0;
    let completedeventscountSum = 0;
    let latencySum = 0;

    let GrandTotal = finalResult.filter((item) => item.client === "Sub Total");

    GrandTotal.forEach((item) => {
      // console.log("Item of avg time ", item.averagetimeperaudit)
      generatedeventscountSum += item.generatedeventscount;
      completedeventscountSum += item.completedeventscount;
      latencySum += item.latency;
    });
    const grandTotal = {
      client: "Grand Total",
      hour: "Grand Total",
      generatedeventscount: generatedeventscountSum,
      completedeventscount: completedeventscountSum,
      latency: latencySum,
    };
    // console.log("Gand Total",grandTotal)
    if (finalResult.length > 0) {
      finalResult.push(grandTotal);
    }

    setSelectedDate(filterForms.reportDate.format("YYYY-MM-DD"));
    setlatancyVal(filterForms.latency);
    return finalResult;
  };
  const handleResponseDataAgent = (
    data,
    suTotalCols = [],
    timeSubTotalCols = []
  ) => {
    setSelectedDate("");
    // //debugger
    let finalResult = [];
    let subTotals = {};
    let currentClient = "";
    let isFirstRow = true;
    let sno = 1;

    let generatedeventscount = 0,
      completedeventscount = 0,
      latency = 0;

    data.forEach((row, index) => {
      const newRow = { ...row };
      generatedeventscount += newRow.eventscount;
      completedeventscount += newRow.trueevents;
      latency += newRow.falseevents;
      // Check if it's the first row of a new client
      if (row.agentid !== currentClient) {
        if (currentClient) {
          const subtotalRow = {
            client: "Sub Total",
            date: "",
            agenthour: "Sub Total",
          };
          suTotalCols.concat(timeSubTotalCols).forEach((col) => {
            if (!subTotals[col]) {
              if (col === "averagetimeperaudit") {
                subTotals[col] = "";
              } else {
                subTotals[col] = 0;
              }
            }
            subtotalRow[col] = subTotals[col];
          });
          finalResult.push(subtotalRow);
        }

        currentClient = row.agentid;
        isFirstRow = true; // Reset to true for the new client group

        // Push client name and date followed by values only for the first row of each client group
        const clientHeaderRow = {
          sno: isFirstRow ? sno++ : "",
          agentname: isFirstRow
            ? { rowSpan: 1, columnValue: row.agentname }
            : null,
          ...row,
        };
        finalResult.push(clientHeaderRow);

        // Reset subtotals for the new client
        subTotals = {};
      } else {
        // Remove Agent from the row if it's not the first row of the group
        delete newRow.agentname;
      }
      // For the last row of each client, calculate subtotals
      if (
        index === data.length - 1 ||
        data[index + 1]?.agentid !== currentClient
      ) {
        suTotalCols.concat(timeSubTotalCols).forEach((col) => {
          if (!subTotals[col]) {
            subTotals[col] = 0;
          }
          if (col === "eventscount") {
            subTotals[col] = generatedeventscount;
          } else if (col === "trueevents") {
            subTotals[col] = completedeventscount;
          } else if (col === "falseevents") {
            subTotals[col] = latency;
          } else if (col === "averagetimeperaudit") {
            subTotals[col] = " ";
          }
        });
        generatedeventscount = 0;
        completedeventscount = 0;
        latency = 0;
      }

      // Push the current row if it's not the first row of the group
      if (!isFirstRow) {
        finalResult.push(newRow);
      } else {
        isFirstRow = false; // Reset isFirstRow flag for subsequent rows in the group
      }
    });

    // Push subtotals for the last client
    if (currentClient) {
      const subtotalRow = {
        client: "Sub Total",
        date: "",
        agenthour: "Sub Total",
      };
      suTotalCols.concat(timeSubTotalCols).forEach((col) => {
        if (!subTotals[col]) {
          subTotals[col] = 0;
        }
        subtotalRow[col] = subTotals[col];
      });
      finalResult.push(subtotalRow);
      subTotals = {};
    }

    let generatedeventscountSum = 0;
    let completedeventscountSum = 0;
    let latencySum = 0;

    let GrandTotal = finalResult.filter((item) => item.client === "Sub Total");

    GrandTotal.forEach((item) => {
      // console.log("Item of avg time ", item.averagetimeperaudit)
      generatedeventscountSum += item.eventscount;
      completedeventscountSum += item.trueevents;
      latencySum += item.falseevents;
    });
    const grandTotal = {
      agenthour: "Grand Total",
      eventscount: generatedeventscountSum,
      trueevents: completedeventscountSum,
      falseevents: latencySum,
    };
    // console.log("Gand Total",grandTotal)
    if (finalResult.length > 0) {
      finalResult.push(grandTotal);
    }
    setSelectedDate(filterForms.reportDate.format("YYYY-MM-DD"));
    setlatancyVal(filterForms.latency);
    return finalResult;
  };

  function groupData(data, groupKeys) {
    const grouped = {};
    data.forEach((item) => {
      const key = groupKeys.map((key) => item[key]).join("-");
      if (!grouped[key]) {
        grouped[key] = { ...item };
      }
    });
    return Object.values(grouped);
  }

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + ":" : "00:";
    var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + ":" : "00:";
    var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
    return hDisplay + mDisplay + sDisplay;
  }

  const getReport = () => {
    // console.log(activeTab.tab)

    let intervalId;

    switch (activeTab.tab) {
      case 0:
        setIsDataAvailable(false);
        getClientwiseData(
          {
            date: filterForms.reportDate.format("YYYY-MM-DD"),
            latency: filterForms.latency,
          },
          (response) => {
            // console.log(response.status, response.data)
            if (response.status === 200) {
              if (response.data && response.data.length > 0) {
                setIsDataAvailable(true);
                const result = handleResponseData(
                  [...response.data],
                  ["DisplayName", "date"],
                  "DisplayName",
                  ["generatedeventscount", "completedeventscount", "latency"],
                  ["averagetimeperaudit"]
                );
                setActiveTab({ activekey: 0, data: result, footer: [] });
              }
            } else {
              setIsDataAvailable(false);
            }
          }
        );
        break;
      case 1:
        setIsDataAvailable(false);
        getCamerawiseData(
          {
            date: filterForms.reportDate.format("YYYY-MM-DD"),
            latency: filterForms.latency,
          },
          (response) => {
            if (response.data) {
              if (response.status === 200) {
                if (response.data.length > 0) {
                  setIsDataAvailable(true);
                  setActiveTab({
                    activekey: 1,
                    data: response.data,
                    footer: [],
                  });
                }
              } else {
                setIsDataAvailable(false);
              }
            }
          }
        );
        break;
      case 2:
        setIsDataAvailable(false);
        getAgentwiseData(
          {
            date: filterForms.reportDate.format("YYYY-MM-DD"),
          },
          (response) => {
            // console.log(response.status, response.data)
            if (response.status === 200) {
                if(response.data.length === 0 ) {
                  setIsDataAvailable(false);
                }
                else {
               
                  setIsDataAvailable(true);
                  const result = handleResponseDataAgent(
                    [...response.data],
                    ["eventscount", "trueevents", "falseevents"]
                  );
                  setActiveTab({ activekey: 2, data: result, footer: [] });

              }
            } else {
              setIsDataAvailable(false);
            }
          }
        );
        break;
      case 3:
        setIsDataAvailable(false);
        getAgnetConsolidatedData(
          {
            date: filterForms.reportDate.format("YYYY-MM-DD"),
          },
          (response) => {
            // console.log(response.status, response.data)
            if (response.status === 200) {
              if(response.data.length === 0){
                setIsDataAvailable(false);

              }else {
                setIsDataAvailable(true);
                if (response.data.length) {
                  setActiveTab({
                    activekey: 3,
                    data: response.data,
                    footer: [],
                  });
                }
              }
            } else {
              setIsDataAvailable(false);
            }
          }
        );
        // const groupedData = groupData(response.data, ['agentname', 'date']);
        // console.log("Result....2", groupedData);
        // setActiveTab({ activekey: 2, data: groupedData, footer: [] });

        break;
      // if (intervalId) {
      //     clearInterval(intervalId);
      // }

      // const updateData = () => {
      //     getAgnetConsolidatedData({
      //         date: filterForms.reportDate.format('YYYY-MM-DD')
      //     }, (response) => {
      //         if (response.status === 200) {
      //             if (actualActiveTab === activeTab.tab) {
      //                 setActiveTab({
      //                     activekey: 3,
      //                     data: response.data.hasOwnProperty('Counts') ? response.data.Counts : [],
      //                     footer: response.data.hasOwnProperty('Totals') ? [response.data.Totals] : [],
      //                 });
      //             } else {
      //                 clearInterval(intervalId);
      //                 intervalId = null;
      //             }
      //         }
      //     });
      // };

      // // Initial call to fetch data
      // updateData();

      // // Set interval to update data every second
      // intervalId = setInterval(() => {
      //     if (actualActiveTab === activeTab.tab) {
      //         updateData();
      //     } else {
      //         clearInterval(intervalId);
      //         intervalId = null;
      //     }
      // }, 1000);

      case 4:
        setIsDataAvailable(false);
        getHourlyEvents(
          {
            date: filterForms.reportDate.format("YYYY-MM-DD"),
          },
          (response) => {
            // console.log(response.status, response.data)
            if (response.status === 200) {
              if (response.data) {
                if (response.data.length > 0) {
                  setIsDataAvailable(true);

                  setActiveTab({
                    activekey: 4,
                    data: response.data,
                    footer: [],
                  });
                }
              }
            } else {
              setIsDataAvailable(false);
            }
          }
        );
        break;
      case 5:
        getSearchEvent(
          {
            EventNo: filterForms.eventNo,
          },
          (response) => {
            // console.log(response.status, response.data)
            if (response.status === 200) {
              if (response.data) {
                if (response.data.length) {
                  console.log(response.data);
                  const updatedData = response.data.map((event) => ({
                    ...event,
                    Latency: secondsToHms(event.Latency),
                    reviewedby:
                      event.reviewedby != null ? event.reviewedby : "",
                    IsAnnounced: event.IsAnnounced ? "Yes" : "No",
                    IsCalled: event.IsCalled ? "Yes" : "No",
                  }));
                  setActiveTab({ activekey: 5, data: updatedData, footer: [] });
                  //setIsDataAvailable(true)
                } else {
                  //setIsDataAvailable(false)
                }
              } else {
                //setIsDataAvailable(false)
              }
            }
          }
        );
        break;
      default:
        return null;
        break;
    }
    // console.log(filterForms, activeTab.tab)
  };

  const handleChange = (_, newValue) => {
    ////debugger
    setIsDataAvailable(true);
    setActiveTab({ activekey: newValue, data: [], footer: [] });
    setCount(1);
    // setFilterForms((prevState) => ({
    //   ...prevState,
    //   reportDate: dayjs(new Date()),
    // }));
  };

  useEffect(() => {
    if (count === 1) {
      getReport();
      setCount(0);
    }

    if(activeTab.tab!==5){
      ////debugger
      //console.log(filterForms)
    }
  }, [activeTab.tab]);

  useEffect(() => {
    //setActiveTab({ activekey: 0, data: [], footer: [] });
    getReport();
    getBasicCountsReport((response) => {
      const resultKeys = [
        "activecameras",
        "activeagents",
        "category1",
        "category2",
        "category3",
        "notassigned",
      ];
      if (response.status === 200 || response.status === 201) {
        resultKeys.forEach((item, idx) => {
          if (item !== "activeagents")
            basicCounts[idx].count = response.data[0][item];
        });
        getActiveAgentCount((response) => {
          if (response.status === 200 || response.status === 201)
            basicCounts[1].count = response.data.count;
          // basicCounts[1].count = response.data.length > 0 && response.data[0].hasOwnProperty('activeagents') ? response.data[0].activeagents: 0
          setBasicCounts([...basicCounts]);
        });
      }
    });
  }, []);

  // console.log("Basic Counts",basicCounts);

  return (
    <Page title="Dashboard | Live">
      <Container maxWidth="xxl">
        <Grid container spacing={3} sx={{ mb: 3 }}>
          {Object.values(basicCounts).map((row, index) => (
            <Grid key={index} item xs={6} sm={6} md={2}>
              <div onClick={() => handleClickOpen(row)}>
                <CounterCard
                  title={row.count}
                  subtitle={row.title}
                  icon={row.icon}
                  color={row.color}
                />
              </div>
            </Grid>
          ))}
        </Grid>

        <Card>
          <Tabs
            value={activeTab.tab}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{ backgroundColor: "rgb(244, 246, 248)" }}
          >
            {TABS.map((item, index) => (
              <Tab key={index} label={item.name} />
            ))}
          </Tabs>

          <FilterBox
            activeTab={activeTab}
            filterForms={filterForms}
            handleFilterBox={handleFilterBox}
            getReport={getReport}
          />
          <ResultTable
            activeTab={activeTab}
            filterForms={filterForms}
            shoulDsiplayPagination={displayPagination}
            isResponseAvailable={isDataAvailable}
          />
        </Card>
      </Container>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isopen}
        //   onClose={handleClose}
      >
        <DialogContent open={isopen}>
          <CardCountDetails close={handleClose} dialogContent={dialogContent} />
        </DialogContent>
      </Dialog>
    </Page>
  );
}