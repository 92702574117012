import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";

const BackgroundIcon = styled("div")(({ theme }) => ({
  opacity: 0.12,
  position: "absolute",
  right: "3px",
  top: "4px",
  fontSize: 80,
}));

export default function CounterCard({
  icon,
  title,
  subtitle,
  color = "primary",
}) 
{
 
  return (
    <Card
      sx={{
        minWidth: 100,
        minHeight: 100,
        color: (theme) => theme.palette[color].lighter,
        bgcolor: (theme) => theme.palette[color].darker,
        position: "relative",
        p: 3,
        // textAlign: "center",
        // border: (theme) => `1px solid ${theme.palette[color].darker}`
      }}
    >
      {/* <Iconify icon={icon}/> */}
      <BackgroundIcon>
        <Iconify icon={icon} />
      </BackgroundIcon>
      <Typography variant="h3">{title ? title : 0 }</Typography>
      <Typography
        variant="subtitle2"
        sx={{ opacity: 0.72, textTransform: "capitalize" }}
      >
        {String(subtitle).toLowerCase()}
      </Typography>
    </Card>
  );
}
