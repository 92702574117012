import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Switch,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import axios from "src/axios";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import qs from "qs";
import * as Yup from "yup";
import { addShift, getLocations } from "src/api/shiftsMaster";

export default function ShiftTimeAdd({ isOpen, onClose, updateDataCallback }) {
  const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
  // const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
  const [apiData, setApiData] = useState(null); // New state for storing API data

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [shiftName, setShiftName] = useState("");
  const [entryFromTime, setEntryFromTime] = useState(null);
  const [entryToTime, setEntryToTime] = useState(null);
  const [exitFromTime, setExitFromTime] = useState(null);
  const [exitToTime, setExitToTime] = useState(null);

  const [loading, setLoading] = useState(false);

  console.log("entryFromTime", entryFromTime)

  // const schema = Yup.object().shape({
  //   selectedLocation: Yup.string().required('Location is required'),
  //   shiftName: Yup.string().required('Shift is required'),
  //   exitFromTime: Yup.string().required('Server is required'),
  //   entryToTime: Yup.string().required('Camera View is required'),
  // });

  // const [errors, setErrors] = useState({
  //   selectedLocation: '',
  //   shiftName: '',
  //   entryFromTime: '',
  //   entryToTime: '',
  //   exitFromTime: '',
  //   exitToTime: '',
  // });

  // useEffect(() => {

  //   if(selectedLocation){
  //     setErrors({
  //       selectedLocation: ,
  //   })
  // }
  // },[])

  useEffect(() => {
      getLocations((response)=> {
        if (response.status === 200) {
          const extractedLocationNames = response.data.map(
            (item) => item.LocationName
          );
          setLocationNames(extractedLocationNames);  
          setApiData(response.data);
        }
      })
  }, []);

  const formatTime = (time) => {
    return time ? `${time}:00` : ""; // Append ":00" to the time if it's not null or empty
  };

  const handleAddNewShift = async () => {
    setLoading(true);

    const enft = new Date(formatTime(entryFromTime));
    const formattedEntryFromTime = enft.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const entt = new Date(formatTime(entryToTime));
    const formattedEntryToTime = entt.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const exft = new Date(formatTime(exitFromTime));
    const formattedExitFromTime = exft.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const extt = new Date(formatTime(exitToTime));
    const formattedExitToTime = extt.toLocaleTimeString("en-US", {
      hour12: false,
    });

    console.log("api data", apiData);
    const locationIDtest = apiData.find(
      (item) => item.LocationName === selectedLocation
    );

    console.log("data", locationIDtest.Id);

    const data = {
      LocationId: locationIDtest.Id,
      ShiftName: shiftName,
      EntryFromTime: formattedEntryFromTime,
      EntryToTime: formattedEntryToTime,
      ExitFromTime: formattedExitFromTime,
      ExitToTime: formattedExitToTime,
    };

    console.log("data", data);

    // const API_ENDPOINT = "http://54.184.152.88:8080/shift-mon-timings/add";

    addShift(data, (response) => {
      updateDataCallback();
      onClose();
      setLoading(false);
    })


    // try {
    //   axios
    //     .post(API_ENDPOINT, jsonToFormData(data), {
    //       headers: {
    //         accept: "application/json",
    //         "Content-Type": "application/x-www-form-urlencoded",
    //         // Authorization: AUTH_TOKEN
    //       },
    //     })
    //     .then((response) => {
    //       console.log("API Response:", response.data);
    //       updateDataCallback();
    //       onClose();
    //     })
    //     .catch((error) => {
    //       console.error("API Error:", error);
    //     });
    // } catch (error) {
    //   console.error("Error:", error);
    // }

   

    setSelectedLocation("");
    setShiftName("");
    setEntryFromTime(null);
    setEntryToTime(null);
    setExitFromTime(null);
    setExitToTime(null);
    onClose();
  };

  // const handleAddNewShift = async () => {
  //   try {
  //     await schema.validate(
  //       {
  //         shiftName,
  //         exitFromTime,
  //         entryToTime,
  //       },
  //       { abortEarly: false }
  //     );
  //     // Clear any previous errors
  //     setErrors({
  //       shiftName: '',
  //       exitFromTime: '',
  //       entryToTime: '',
  //     });
  //     // Proceed with saving
  //     console.log('Form is valid. Saving...');
  //   } catch (error) {
  //     const newErrors = {};
  //     error.inner.forEach((err) => {
  //       newErrors[err.path] = err.message;
  //     });
  //     setErrors(newErrors);
  //   }
  // };

  const handleClose = () => {
    // Clear the entered values when the dialog is closed
    setSelectedLocation(null);
    setShiftName("");
    setEntryFromTime(null);
    setEntryToTime(null);
    setExitFromTime(null);
    setExitToTime(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add New Shift</DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={locationNames}
              getOptionLabel={(option) => option}
              value={selectedLocation}
              onChange={(event, newValue) => setSelectedLocation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Location"
                  fullWidth
                  margin="normal"
                  // error={!!errors.selectedLocation}
                  // helperText={errors.selectedLocation}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Shift Name"
              value={shiftName}
              onChange={(e) => setShiftName(e.target.value)}
              fullWidth
              margin="normal"
              // error={!!errors.shiftName}
              // helperText={errors.shiftName}
            />
          </Grid>
          <Box sx={{ my: 3 }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Entry Time
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Entry From Time"
                    value={entryFromTime}
                    onChange={(newValue) => setEntryFromTime(newValue)}
                    fullWidth
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Entry To Time"
                    value={entryToTime}
                    onChange={(newValue) => setEntryToTime(newValue)}
                    fullWidth
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Box sx={{ my: 3 }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Exit
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Exit From Time"
                    value={exitFromTime}
                    onChange={(newValue) => setExitFromTime(newValue)}
                    fullWidth
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Exit To Time"
                    value={exitToTime}
                    onChange={(newValue) => setExitToTime(newValue)}
                    fullWidth
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          // entryFromTime
          onClick={handleAddNewShift}
          // loading={loading}
        >
          Add Shift
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
