import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components

import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import AgentDataCard from 'src/sections/agentreports/AgentDataCard';
import useTotalEscAgc from 'src/api/AgentConsolidated/useTotalEscAgc';

// ----------------------------------------------------------------------
const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function TotalEscalatedAGC() {


  const [reportData] = useTotalEscAgc()
  
  return (
    <Page title="Dashboard: Blog | Minimal-UI">
      <Container maxWidth="xxl">
        <Grid container spacing={3}>
            <AgentDataCard reportData={reportData}/>
        </Grid>
      </Container>
    </Page>
  );
}

