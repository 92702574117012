import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
// mock
// import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
//import NavSection from '../../components/NavSection';
import NavSection from 'src/NavSection';
//
import navConfig from './NavConfig';
import deployNavConfig from './DeployNavConfig';
import navConfigInd from './NavConfigIND';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, userType, userData }) {
 
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [user, setUser] = useState({
    displayName: '',
    photoURL: null,
    role: '',
    email: '',
    displayLatter:''
  })

  useEffect(() => {
    let displayName = localStorage.getItem('displayname')
    let logo_url = localStorage.getItem('logo_url')
    let displayLatter = displayName ? displayName.charAt(0).toUpperCase() : ''
    let photoURL = logo_url && logo_url !== "null" ? logo_url : null
    setUser({...user, displayName, displayLatter, photoURL})
  }, [localStorage.getItem('displayname')])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  console.log('deployNavConfig',deployNavConfig)

  let opsConfig = [ 
    {
      "title": "Live",
      "path": "/cameras-live",
      "icon": {
          "key": null,
          "ref": null,
          "props": {
              "icon": "eva:video-outline",
              "width": 22,
              "height": 22
          },
          "_owner": null,
          "_store": {}
      },
      "isHideInXs": false,
      "positiveAccess": [],
      "positiveUserRoles": [],
      "negativeUserRoles": [],
      "negativeAccess": []
  }]


  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 4, display: 'inline-flex',  alignItems: "center" }}>
        <Logo />
        {/* {userType} */}
        {/* {user.photoURL !== null && (
          <Logo url={user.photoURL} sx={{
            backgroundColor: "#ededed",
            borderRadius: "8px",
            padding: "10px",
            ml:1
          }} />
        )} */}
        {user.photoURL !== null && userType !== '9' && (
          <Logo 
            url={user.photoURL} 
            sx={{
              backgroundColor: "#ededed",
              borderRadius: "8px",
              padding: "10px",
              ml: 1
            }} 
          />
        )}

        
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
          <Avatar
            sx={{ bgcolor: deepOrange[500] }}
            alt={user.displayName}
            // src={user.photoURL}
          >
            {user.displayLatter}
          </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary',  textTransform: "capitalize" }}>
                {user.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
{/* {console.log(Number(userType),userData.IsAgentBased)} */}
      <NavSection navConfig={ ((Number(userType) === 9) || (Number(userType) === 8)) ? deployNavConfig :  Number(userData.IsAgentBased) === 0 ? navConfigInd : navConfig} userData={userData}/>

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}