import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,Checkbox,
  TextField,Table,TableHead,TableCell,TableContainer,TableRow,
  Button,
  useRadioGroup,
  createFilterOptions,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormHelperText,
} from '@mui/material';
import Scrollbar from "src/components/Scrollbar";

import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { getClientList } from 'src/api/analytics';
import { getUserDetails } from 'src/api/addAgent';
import { getCamerasList } from 'src/api/analytics';
import { setClientAgent } from 'src/api/analytics';
import { getClientAssignData } from 'src/api/analytics';
import { assign } from 'lodash';
import client from 'src/api/clients';
import { faL } from '@fortawesome/free-solid-svg-icons';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;


function ClientAssign() {
  // State variables
  const [clientType, setClientType] = useState('');
  const [dilogeClientType,setDilogClientType] = useState('')
  const [selectedclients, setSelectedcllientList] = useState([]);
  const [dilogclients, setdilogcllientList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);

  // response Data
  const [clientList,setClientList] = useState([]);
  const [clientListType,setSelectedClientListType] = useState([]);
  const [dilogListType,setdilogListType] = useState([]);
  const [assignedData,setClientAssignedData] = useState([]);
  const [agentList,setAgentList] = useState([]);
  const [userTypeList,setUserTypeData] = useState([]);

  const [camerasList,setCamerasList] = useState([]);
  const [clientCameraList,setClientCameraList] =  useState([]);

  const [editedData,setEditedData] = useState([])
  const [openDialog,setOpenDialog] = useState(false)

  const [clientTypeError, setClientTypeError] = useState('');
  const [track,setTrack] = useState(false)

  const [checkValue,setCheckValue]=useState(false)
  const [inputValue, setInputValue] = useState([]);
  const [activeCameras, setActiveCameras] = useState([])
  const [copyCams,setCopyCams] = useState([])

  const [error,setError] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

  const [tableDialog,setTableDilog] = useState(false)
  const [tableData,setTableData] = useState([])

  const [clientError, setClientError] = useState('');
  const [agentError, setAgentError] = useState('');
  const [cameraError, setCameraError] = useState('');
  const [dilogCameras,setDilogCameras] = useState([])

  const [clientTypeEditError, setClientTypeEditError] = useState(false);
  const [clientEditError, setClientEditError] = useState(false);
  const [agentEditError, setAgentEditError] = useState(false);
  const [activeEditError,setActiveEditError] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 
  
  const [disableAgent,setDisableAgent] = useState(false)


 useEffect(()=>{
  getClientList((response) => {
    if (response.status === 200) {
      setClientList(response.data);
    }
  });
  getUserDetails((response) => {
    if (response.status === 200) {
      // Filter cameras where usertype is 4
    
      // console.log(response.data)
      // console.log()
      let filteredAgent = response.data.filter(val => val.Status === 1)
      setAgentList(filteredAgent);
    }
  });
  
  getCamerasList((response) =>{
    if(response.status === 200){
      setCamerasList(response.data);
    } 
  })

  getClientAssignData((response) =>{
    if(response.status === 200){
      setClientAssignedData(response.data);
    }
  })
 },[])

//  useEffect(()=>{
//   debugger
//   console.log(selectedAgent)
//  },[selectedAgent])

 useEffect(()=>{
  const selectedUsers = agentList
  setUserTypeData(selectedUsers);
 },[agentList])

 useEffect(()=>{
  setSelectedAgent([])
  const Type = editedData.details && 
                     editedData.details.ClientId && 
                     editedData.details.ClientId.length > 0 
                     ? clientList.find(client => client.ID === editedData.details.ClientId[0])?.ClientType 
                     : null;

  const agentId = editedData && editedData.details ? editedData.details.AgentId :''
  const selectedAgents = agentList.filter((agents)=>agents.ID === agentId)
  setSelectedAgent(selectedAgents)

  setDilogClientType(Type || '')
  if(Type === "INDIA"){
   const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA')
   setdilogListType(indiaClients)
  }
  else if(Type === "US"){
   const usClients = clientList.filter((row) => row.ClientType === 'US');
   setdilogListType(usClients)
  }
 },[openDialog])

 useEffect(()=>{
  setdilogListType([])

  if(dilogeClientType === "INDIA"){
    const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
    setdilogListType(indiaClients)
  }
  else if(dilogeClientType === "US"){
    const usClients = clientList.filter((row) => row.ClientType === 'US');
    setdilogListType(usClients)
  }

  if(dilogeClientType){
   
    const clientIds = clientList.map(client => client.ID && client.ClientType === dilogeClientType);
    let allCameras = Object.keys(editedData).length > 0 
    ? camerasList.filter((camera) => editedData.details.ClientId.includes(camera.clientid)) 
    : [];

    let uniqueId = [...new Set(allCameras.map(item => item.clientid))]
    let ct = clientList.filter((clients)=>uniqueId.includes(clients.ID) && clients.ClientType === dilogeClientType).map(val => val.ID)

    let clientCameras = allCameras.filter(client =>ct.includes(client.clientid))

    setDilogCameras(clientCameras)

    const deviceIds = editedData.details.camera_details.flat().map(camera => camera.DeviceId);
    const filteredCameras = camerasList.filter(camera => deviceIds.includes(camera.deviceid));
    const selectedClients = dilogclients.filter(clients => clients.ClientType === dilogeClientType).map(id=>id.ID)
    const activeCams = filteredCameras.filter (cams =>selectedClients.includes(cams.clientid)).map(divid => divid.deviceid)
    const copyCams = filteredCameras.filter (cams =>!selectedClients.includes(cams.clientid)).map(divid => divid.deviceid)
    setActiveCameras(activeCams)
    setCopyCams(copyCams)
  }
 },[dilogeClientType])

  useEffect(() =>{
    if(clientType === "INDIA"){
     
     const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
  
     setSelectedClientListType(indiaClients)
    }
    else if(clientType === "US") {
     const usClients = clientList.filter((row) => row.ClientType === 'US');
     setSelectedClientListType(usClients)
    }
  },[clientType])

  useEffect(()=>{
    // console.log("slected clients ",selectedclients);
    // console.log("CamersaList",camerasList)
    const selectedClientIds = selectedclients.map(client => client.ID);   

    const selectedCameras = camerasList.filter(camera => selectedClientIds.includes(camera.clientid));
    
    // camerasList
    setClientCameraList(selectedCameras);
  },[selectedclients])


  // Handlers
  const handleClientTypeChange = (event) => {
    setClientType(event.target.value);
    setClientTypeError(event.target.value ? '' : 'Client Type is required');
    setActiveCameras([])
    setClientCameraList([])
    setSelectedAgent([]);
    setSelectedcllientList([])
  };

 

  const handleAdd = () =>{
  
    setClientTypeError('');
    setClientError('');
    setAgentError('');
    setCameraError('');

    setErrorMessage('')
    setError(false)
    setOpenDialog(false)

    setClientType('')
    


    if (!clientType) {
      setClientTypeError('Please Select Client Type');
    }

    if (selectedclients.length === 0) {
      setClientError('Please Select Client');
    }

    if (selectedAgent.length === 0) {
      setAgentError('Please Select Agent');
    }

    if (activeCameras.length === 0) {
      setCameraError('Please Select Cameras');
    }

    if (clientTypeError || clientError || agentError || cameraError) {
      return;
    }

    const AgentIds = selectedAgent.map(agent => agent.ID);

    if(selectedclients.length === 0 || activeCameras.length === 0 || AgentIds.length === 0){

    }

    const result =assignedData && assignedData.reduce((acc, item) => {
      if (AgentIds.includes(item.details.AgentId)) {
          acc[item.details.AgentId] = item.details.camera_details
              .flat() 
              .map(camera => camera.DeviceId);
      }
      return acc;
    }, {});
  
    let List = [];let error = []
    selectedAgent.forEach((item,inedx)=>{
      const agentId = item.ID;
      if(result && Object.keys(result).length>0){
        if(result[agentId]){
          const existingCameras = result[agentId]
          const currentCameras = activeCameras

          const commonCameras = existingCameras.filter(camera => currentCameras.includes(camera));

          if(commonCameras.length>0){
            let cameraNames = commonCameras.join(', ')
              const deviceNames = commonCameras.map(deviceId => {
              const device = camerasList.find(device => device.deviceid === deviceId);
              return device ? device.devicename : null; 
            }).filter(name => name !== null);
            
            const deviceNamesString = deviceNames.length > 0 ? deviceNames.join(', ') : '';
            
            error.push(`Agent :${item.displayname} is already assigned to the Camera: ${deviceNamesString}`)
          }

          const mergedCams = [...existingCameras,...currentCameras]

          List.push({
            AgentId: agentId,
            CameraId: mergedCams.join(','),
            Type:"add"
          });

        }
        else{
          List.push({
            AgentId: agentId,
            CameraId: activeCameras.join(','),
            Type:"add"
          });
        }
      }
      else{
        List.push({
          AgentId: agentId,
          CameraId: activeCameras.join(','),
          Type:"add"
        });
      }
    })

    if(error.length>0){
      
     // let message = error.join('\n'); 
      setErrorMessage(error)
      setError(true)
      return;
    }

    console.log(List)

    if(List.length>0){
        setClientAgent(List,(response)=>{
        if(response.status === 200){
          console.log("clilnet agnet ");       
          getClientAssignData((response) =>{
            if(response.status === 200){
              setClientAssignedData(response.data);
              handleClear()
            }
          })
        }
      })
    }
  }


    //let isClientAvail = assignedData.filter(item=>item.details.)

  // const handleAdd = () => {
  //   debugger
  //    const AgentIds = selectedAgent.map(agent => agent.ID);
  //    console.log(AgentIds)
  //    console.log(assignedData)
  //    const matchedData = assignedData.filter(item => 
  //     AgentIds.includes(item.details.AgentId)
  //     );
  //     console.log(matchedData)

  //     if (matchedData.length > 0) {
  //       let allClientNames = matchedData.flatMap(item => item.details.ClientName);
  //       let uniqueClientNames = [...new Set(allClientNames)];
  
  //       let clientNames = uniqueClientNames.join(', ');
  //       let agentNames = matchedData.map(item => item.details.agent_name).join(', ');
    
  //       console.log("Client Names:", clientNames);
  //       console.log("Agent Names:", agentNames);

  //       if(agentNames && clientNames){
  //       setErrorMessage(`${agentNames} are already assigned to the Client : ${clientNames}`)
  //       setError(true)
  //       return;
  //       }

  //       const agentCameraMap = new Map();

  //       matchedData.forEach(item => {
  //         const { AgentName, CameraDetails } = item.details;
  //         if (!agentCameraMap.has(AgentName)) {
  //             agentCameraMap.set(AgentName, []);
  //         }
  //         agentCameraMap.get(AgentName).push(...CameraDetails);
  //       });
  
  //     const agentCameraDetails = Array.from(agentCameraMap.entries()).map(([agent, cameras]) => ({
  //         agent,
  //         cameras: [...new Set(cameras)]
  //       }));

  //       console.log(agentCameraDetails)
  //     }
  
  //   // const CameraIds = selectedCameras.map(camera => camera.deviceid);
  //   const CameraIds = activeCameras.join(',').toString();
  //    const data = {
  //     "AgentId": `${AgentIds}`,
  //     "CameraId": `${CameraIds}`
  //   }

  //   // setClientAgent(data,(response)=>{
  //   //   if(response.status === 200){
  //   //     console.log("clilnet agnet ");       
  //   //     getClientAssignData((response) =>{
  //   //       if(response.status === 200){
  //   //         setClientAssignedData(response.data);
  //   //       }
  //   //     })
  //   //   }
  //   // })
  // };

  // const handleClear = () => {
  //   setClientType('');
  //   //setsele(null);
  //   setSelectedAgent(null);
  //   //setSelectedCamera(null);
  //   setClientTypeError('');
  // };

  const handleClear = () => {
    setClientType('');
    setSelectedcllientList([]);
    setSelectedAgent([]);
    setActiveCameras([]);
    setClientTypeError('');
    setClientError('');
    setAgentError('');
    setCameraError('');
    setDilogClientType('');
    setdilogcllientList([]);
    setDilogCameras([]);
    setCheckValue(false);
    setSelectedClientListType([])
    setCopyCams([])
    setDisableAgent(false)
  };

  const handleClientChange = (event,newClient) =>{
    //debugger
    setCheckValue(false)
    setClientError('')
    setActiveCameras([])
    if (event.key === 'Backspace') {
      return; 
  }
    setSelectedcllientList(newClient)
  }

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);

    return [{ title: 'Select All', all: true }, ...filtered];
  };



  const handleDilogCameraSelection = (_, selectedCameras) => {
    const containsAll = selectedCameras.some(obj => obj.hasOwnProperty('all'));

    if (containsAll) {
        if (activeCameras.length === dilogCameras.length) {
            setActiveCameras([]);
        } else {
            const allCameraIds = dilogCameras.map(camera => camera.deviceid);
            setActiveCameras(allCameraIds);
        }
    } else {
        const selectedCameraIds = selectedCameras.map(camera => camera.deviceid);
        setActiveCameras(selectedCameraIds);
    }
};

  const handleCameraSelection = (event, value) =>{
    setCameraError('')
    let actCams=[];
  if (value.some(obj => obj.hasOwnProperty('all'))){
    if(activeCameras.length===clientCameraList.length){
      setActiveCameras([])
      setCheckValue(false)
    }
    else{
      clientCameraList.forEach(item=>{
        actCams.push(item.deviceid)
      })
      setActiveCameras(actCams)
      setCheckValue(true)
    }
  }
  else{
    let id=value[0].deviceid;
    if(activeCameras.includes(id)){
      let filterArr = activeCameras.filter(devid => devid !== id);
      //console.log(typeof activeClients[0]);
      setActiveCameras(filterArr);
      if(checkValue){
        setCheckValue(false)
      }
    }
    else{
      setActiveCameras((prv)=>[...prv,id])
    }
  }
  }

  useEffect(()=>{
    if(activeCameras.length===clientCameraList.length && activeCameras.length>0){
      setCheckValue(true)
    }
  },[activeCameras])

  const handleUpdate = ()=>{
    
    
    setClientTypeEditError(false)
    setClientEditError(false)
    setAgentEditError(false)
    setActiveEditError(false)
    
    let List = [];let error = []

    let hasError = false;

    if (!dilogeClientType) {
        setClientTypeEditError(true);
        hasError = true;
    }

    // if (selectedAgent.length === 0) {
    //   setAgentEditError(true);
    //   hasError = true;
    // }

    if (hasError) {
        return; 
    }
    //debugger
    setIsUpdating(true);
    setIsButtonDisabled(true);
    const AgentIds = selectedAgent.map(agent => agent.ID);
    const existenceAgentId = editedData.details.AgentId;
    let existingCamers = editedData.details.camera_details.flat().map(item=>item.DeviceId)
    const remaingCamers = copyCams
    existingCamers = remaingCamers.length > 0 ? existingCamers.filter(value => !remaingCamers.includes(value)) : existingCamers
    const selectedAgents = editedData && editedData.details ? editedData.details.AgentId : [];
    const removedAgent = AgentIds.filter(agentID => selectedAgents === (agentID));

    const isContainsMultipleTypes = [... new Set(dilogclients.map(val => val.ClientType))].length
    const remaingCams = copyCams
    const newAgents = AgentIds.filter (agent => existenceAgentId !== agent)

    selectedAgent.forEach((item,inedx)=>{    
      const agentId = item.ID;    
      if(existenceAgentId === agentId){
            List.push({
                      AgentId: agentId,
                      CameraId:isContainsMultipleTypes > 1 && remaingCams.length > 0 ? [...remaingCams,...activeCameras].join(',') : activeCameras.join(','),
                      Type : 'add'
              })
            const removableCameras = existingCamers.filter(val => !activeCameras.includes(val));
            if(existingCamers.length !== activeCameras.length || removableCameras.length>0){
              let removedCams = existingCamers.filter(val=>!activeCameras.includes(val));
              if(removedCams.length > 0){
                List.push({
                  AgentId: agentId,
                  CameraId: removedCams.join(','),
                  Type : 'remove'
                })
              }
            }
      }
      else{
        const deviceIds = [];

        if(assignedData.length>0){
          assignedData.forEach(agent => {
            if (agent.details.AgentId === agentId) {
                agent.details.camera_details.forEach(clientCameras => {
                    clientCameras.forEach(camera => {
                        deviceIds.push(camera.DeviceId);
                    });
                });
            }
          });
        }
        console.log(deviceIds)
        if(deviceIds.length > 0){
            List.push({
              AgentId: agentId,
              CameraId: deviceIds.join(','),
              Type : 'remove'
            })
        }
        List.push({
          AgentId: agentId,
          CameraId: isContainsMultipleTypes > 1 && remaingCams.length > 0 ? [...remaingCams,...activeCameras].join(',') : activeCameras.join(','),
          Type : 'add'
        })
      }
    })

    if(activeCameras.length === 0){
      List = [];
      if(AgentIds.length > 0){
        AgentIds.forEach((val)=>{
          if(existingCamers.length!==0){
            List.push({
              AgentId : val,
              CameraId : existingCamers.join(','),
              Type : 'remove'
            })
          }
        })
      }
    }
    
    
    if(error.length>0){
      setErrorMessage(error)
      setError(true)
      setIsUpdating(false);
      setIsButtonDisabled(false);
      return
    }
    console.log(List)
      if(List.length>0){
        setClientAgent(List,(response)=>{
        if(response.status === 200){
          console.log("clilnet agnet ");       
          getClientAssignData((response) =>{
            if(response.status === 200){
              setClientAssignedData(response.data);
              setErrorMessage(['Agent Information Updated Successfully'])
              setError(true)
              setOpenDialog(false)
              setIsUpdating(false);
              setdilogListType([]);
              setDilogClientType('');
              setdilogcllientList([]);
              setEditedData([]);
              setOpenDialog(false);
              setActiveCameras([])
              setCheckValue(false)
              setClientTypeEditError(false)
              setClientEditError(false)
              setAgentEditError(false)
              setActiveEditError(false)
              setDilogCameras([])
              handleClear();
            }
          })
        }
        })
      }

      else if(List.length === 0){  //  need to remove client 
        const removeList = [];
        removeList.push({
          AgentId: existenceAgentId,
          CameraId:existingCamers.join(','),
          Type : 'remove'
        })
        setClientAgent(removeList,(response)=>{
          if(response.status === 200){
            console.log("clilnet agnet ");       
            getClientAssignData((response) =>{
              if(response.status === 200){
                setClientAssignedData(response.data);
                setErrorMessage(['Agent Information Removed Successfully'])
                setError(true)
                setOpenDialog(false)
                setIsUpdating(false);
                setdilogListType([]);
                setDilogClientType('');
                setdilogcllientList([]);
                setEditedData([]);
                setOpenDialog(false);
                setActiveCameras([])
                setCheckValue(false)
                setClientTypeEditError(false)
                setClientEditError(false)
                setAgentEditError(false)
                setActiveEditError(false)
                setDilogCameras([])
                handleClear();
              }
            })
          }
          })
      }
      setIsUpdating(false);
      setIsButtonDisabled(false);
      setDisableAgent(false)
    }


  const handleCellClick = (item) => {
   // debugger
    //console.log(item.details.camera_details)
    const flattenedArray = item.details.camera_details.flat();
    setTableData(flattenedArray)
    setTableDilog(true)
  };

  const dilogClientChange = (_,val)=>{
    
    let uniqueValues = (clientList.filter(item => editedData.details.ClientId.includes(item.ID)).map(item => item.ClientType)).filter((value, index, self) => self.indexOf(value) === index);
    
    const newEntry = val.filter(
      (item2) => !dilogclients.some((item1) => item1.ID === item2.ID)
    );

    let remiaingClients = dilogclients.filter(val =>val.ClientType !== dilogeClientType)

    if(newEntry.length > 0){
      if(disableAgent){
        setDisableAgent(!disableAgent)
      }
      let clientId = newEntry.map((id)=> id.ID);
      const cameras = camerasList.filter((camera) => clientId.includes(camera.clientid))  
      const clientCameras = [...dilogCameras,...cameras]   
      
      if(remiaingClients.length > 0){
        setdilogcllientList([...val,...remiaingClients]) 
      }
      else{
        setdilogcllientList(val) 
      }
                
      setDilogCameras(clientCameras)
    }
    else{
      if(val.length === 0){
        if(!disableAgent){
          setDisableAgent(true)
        }
      }
      const IdEntry = dilogclients.filter(
        (item2) => !val.some((item1) => item1.ID === item2.ID)
      );
      let Id = IdEntry.map((id)=> id.ID);
      let filteredCams = dilogCameras.filter((cams)=>!Id.includes(cams.clientid))
      setDilogCameras(filteredCams)
      let active = filteredCams.filter(deviceIds => activeCameras.includes(deviceIds.deviceid))
      if(active.length > 0){
        const data = active.map(id => id.deviceid)
        setActiveCameras(data)
      }
      else{
        setActiveCameras([])
      }
      if(remiaingClients.length > 0){
        if(val.length > 0){
          setdilogcllientList([...val,...remiaingClients])
        }
        else{
          setdilogcllientList(remiaingClients)
        }
      }
      else{
        setdilogcllientList(val)
      }
    }
  }

 

  return (
    <>
      <Card variant="outlined" sx={{ p: 3 }}>
        <CardContent>
          <Typography variant="h4">Client Assign to Agent</Typography>
          <Grid container spacing={2} alignItems="center" marginTop={4}>

            {/* Client Type Dropdown */}
            <Grid item md={2}>
              <FormControl fullWidth error={Boolean(clientTypeError)}>
                <InputLabel id="client-type-label" >Client Type</InputLabel>
                <Select
                  labelId="client-type-label"
                  id="client-type"
                  value={clientType}
                  label="Client Type"
                  onChange={handleClientTypeChange}
                >
                  <MenuItem value="US">US</MenuItem>
                  <MenuItem value="INDIA">INDIA</MenuItem>
                </Select>
                {clientTypeError && (
                  <Typography sx={{ fontSize: '12px',marginTop:'1px'}} color="error">{clientTypeError}</Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item md={2}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={clientListType}
                getOptionLabel={(option) => option.displayname}
                value={selectedclients} 
                isOptionEqualToValue={(option, value) => option.ID === value.ID}
                //onChange={(event, newValue) => setSelectedcllientList(newValue)}
                onChange={handleClientChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.ID}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                      style={{ marginRight: 8 }}
                    />
                    {option.displayname}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Clients"
                    error={Boolean(clientError)}
                    helperText={clientError}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                          '&::placeholder': {
                              color: clientError ? '#FF2400' : 'gray', 
                              opacity: 1, 
                          },
                      },
                  }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                        color: clientError ? '#FF2400' : 'gray', 
                        opacity: 1, 
                    },
                }}
                  />
                )}
                renderTags={() => null}  // Prevents displaying selected items in the input
              />

            </Grid>

            <Grid item md={2}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                value={selectedAgent}
                disableClearable={true}
                options={userTypeList}
                getOptionLabel={(option) => option.displayname}
                isOptionEqualToValue={(option, value) => option.ID === value.ID}
                onChange={(event, newValue) => {
                  setAgentError('')
                  setSelectedAgent(newValue)
                }}
                renderOption={(props, option, { selected }) => {
                  const isSelected = selectedAgent.some((agent) => agent.ID === option.ID);
                  return (
                  <li {...props} key={option.ID}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={isSelected}
                      style={{ marginRight: 8 }}
                    />
                    {option.displayname}
                  </li>
                )}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Agents"
                    error={Boolean(agentError)}
                    helperText={agentError}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                          '&::placeholder': {
                              color: agentError ? '#FF2400' : 'gray', 
                              opacity: 1, 
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                        color: agentError ? '#FF2400' : 'gray', 
                        opacity: 1, 
                        },
                      }}
                  />
                )}
                renderTags={() => null} 
              />
              {/* Display count of selected items */}

            </Grid>

            <Grid item md={2}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={clientCameraList}
                getOptionLabel={(option) =>(option.all && clientCameraList.length===activeCameras.length)?'Select All':`${option.deviceid} ${option.devicename}`}
                isOptionEqualToValue={(option, value) => option.uid === value.uid}
                onChange={handleCameraSelection}
                value={[]}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option.all ? (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={checkValue}
                          onChange={() => setCheckValue(!checkValue)}
                        />
                        {option.title}
                      </>
                    ) : (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={activeCameras.includes(option.deviceid)}
                          onChange={() => {
                            const isChecked = activeCameras.includes(option.deviceid);
                            const newActiveCameras = isChecked
                              ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                              : [...activeCameras, option.deviceid];
                            setActiveCameras(newActiveCameras);
                          }}
                        />
                        {`${option.devicename}`}
                      </>
                    )}
                  </li>
                )}
                filterOptions = {handleFilterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Cameras"
                    error={Boolean(cameraError)}
                    helperText={cameraError}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                          '&::placeholder': {
                              color: cameraError ? '#FF2400' : 'gray', 
                              opacity: 1, 
                          },
                      },
                    }}
                    sx={{
                    '& .MuiInputBase-input::placeholder': {
                        color: cameraError ? '#FF2400' : 'gray', 
                        opacity: 1, 
                      },
                    }}
                  />
                )}
                renderTags={() => null}  // Prevents displaying selected items in the input
              />
              {/* Display count of selected items */}

            </Grid>

            {/* Buttons */}
            <Grid item xs={2} sx={{ height: "100%", marginTop: 2 }}>
              <Button variant="contained" onClick={handleAdd}>
                Add
              </Button>
              <Button color="error" variant="contained" onClick={handleClear} sx={{ marginLeft: 1 }}>
                Clear
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ marginTop: "1rem" }}
          >
            <Scrollbar>
              <TableContainer 
              >
  
                <Table stickyHeader>
                  <TableHead sx={{background: '#f3f3f3' }}>
                    <TableRow sx={{background: '#f3f3f3' }}>
                      <TableCell>SNo</TableCell>
                      <TableCell>Agent Name</TableCell>
                      <TableCell>ClientName</TableCell>
                      <TableCell>Cameras</TableCell>
                    
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {assignedData &&
                    assignedData.map((item, index) => {
                        let deiviceCount = item.details.DevicesCount
                        const sum = deiviceCount.reduce((acc,val)=>{
                            return acc+val;
                        },0)
                        
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.details.agent_name}</TableCell>
                          <TableCell>{item.details.ClientName.join(', ')}</TableCell>
                          <TableCell>
                            <Box onClick={() => handleCellClick(item)} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
                              {sum}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button variant="contained" onClick={()=>{
                             
                              setEditedData(item)
                              setOpenDialog(true)
                              //console.log(item.details.ClientId.map(clientId => clientList.find(client => client.ID === clientId)))
                              setdilogcllientList(item.details.ClientId.map(clientId => clientList.find(client => client.ID === clientId)));
                             
                              // const flattenedArray = item.details.camera_details.flat();
                              // setActiveCameras(flattenedArray.map(camera => camera.DeviceId));   
                            }}>Edit</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
          
        </CardContent>
      </Card>
      <Dialog open={openDialog} fullWidth>
      <DialogTitle>Edit Agent Information</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{marginTop:'5px'}}>
            <FormControl fullWidth error={clientTypeEditError}>
              <InputLabel id="client-type-label">Client Type</InputLabel>
                <Select
                  labelId="client-type-label"
                  id="client-type"
                  value={dilogeClientType}
                  label="Client Type"
                  onChange={(e) => {
                    if(editedData && editedData.details){
                      //let type = dilogeClientType
                      let cameraInfo =activeCameras.filter(id =>!editedData.details.camera_details.flat().map(item=>item.DeviceId).includes(id)) 
                      if(cameraInfo.length !== 0){
                        setError(true)
                        setErrorMessage(['Please Assign the selected cameras to the agent before changing the client type.'])
                        return
                      }
                      else{
                        setDilogClientType(e.target.value)
                      }
                    }                   
                  }}
                  displayEmpty
                >
                  <MenuItem value="INDIA">INDIA</MenuItem>
                  <MenuItem value="US">US</MenuItem>
                </Select>
                {clientTypeEditError && <FormHelperText>Please Select Client Type</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} sx={{marginTop:'5px'}}>
            <Autocomplete
              multiple
              disabled={disableAgent} 
              key={selectedAgent.length}
              disableCloseOnSelect
              options={userTypeList}
              getOptionLabel={(option) => option.displayname}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              value={selectedAgent}
              onChange={(event, newValue) => setSelectedAgent(newValue)}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.ID}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ marginRight: 8 }}
                  />
                  {option.displayname}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Agents"
                  error={agentEditError}
                  helperText={agentEditError ? "Please Select Agent" : ""}
                />          
              )}
              renderTags={() => null}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={dilogListType}
              getOptionLabel={(option) => option.displayname}
              value={dilogclients} 
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              onChange={(e,val)=>{
                const item = val.filter(item => item.ClientType === dilogeClientType)
                dilogClientChange(e,item)
              }}
              renderOption={(props, option, { selected }) => {
                return(
                <li {...props} key={option.ID}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ marginRight: 8 }}
                  />
                  {option.displayname}
                </li>
              )}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Clients"
                  error={clientEditError}
                  helperText={clientEditError ? "Please Select Client." : ""}
                />
              )}
              renderTags={() => null}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={dilogCameras}
              getOptionLabel={(option) => option.devicename}
              isOptionEqualToValue={(option, value) => option.uid === value.uid}
              value={dilogCameras.filter(camera => activeCameras.includes(camera.deviceid))}
              onChange={handleDilogCameraSelection}
              filterOptions={handleFilterOptions}
              renderOption={(props, option, { selected }) => { 
               
                const isAllSelected = activeCameras.length === dilogCameras.length; 
                return (
                  <li {...props}>
                    {option.all ? (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isAllSelected} 
                          onChange={() => {
                            if (isAllSelected) {
                              setActiveCameras([]); 
                            } else {
                              setActiveCameras(dilogCameras.map(cam => cam.deviceid)); 
                            }
                          }}
                        />
                        {option.title}
                      </>
                    ) : (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={activeCameras.includes(option.deviceid)} // Check if this camera is selected
                          onChange={() => {
                            const isChecked = activeCameras.includes(option.deviceid);
                            const newActiveCameras = isChecked
                              ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                              : [...activeCameras, option.deviceid];
                            setActiveCameras(newActiveCameras);
                          }}
                        />
                        {`${option.deviceid} ${option.devicename}`}
                      </>
                    )}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Cameras"
                  error={activeEditError}
                  helperText={activeEditError ? "Please Select Cameras" : ""}
                />
              )}
              renderTags={() => null}
            />
          </Grid>
        </Grid>
      </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleUpdate} disabled={isButtonDisabled}>
           {isUpdating ? 'Updating...' : 'Save'}
          </Button>
          <Button variant='contained' color='error' onClick={() => {
            setdilogListType([]);
            setDilogClientType('');
            setdilogcllientList([]);
            setEditedData([]);
            setOpenDialog(false);
            setActiveCameras([])
            setCheckValue(false)
            setClientTypeEditError(false)
            setClientEditError(false)
            setAgentEditError(false)
            setActiveEditError(false)
            setDilogCameras([])
            handleClear()
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={error}>
        <DialogContent>
          {/* {errorMessage} */}
          {errorMessage && errorMessage.map((item, index) => {
            return ( 
              <Typography key={index} variant="body1" gutterBottom>
                {item}
              </Typography>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={() => setError(false)}>
          Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth open={tableDialog}>
        <DialogTitle>Device Information</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Device Id</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>ClientName</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {tableData && tableData.map((val, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{val.DeviceId}</TableCell>
                <TableCell>{val.DeviceName}</TableCell>
                <TableCell>{val.ClientName}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={()=>{setTableDilog(false)}}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientAssign;