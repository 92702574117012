import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { AgentReportCard } from '../sections/agentreports';
//
import useAgentReport from 'src/api/useAgentReport';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function AgentWiseReport() {
  const [reportData] = useAgentReport()
  return (
    <Page title="Dashboard: Blog | Minimal-UI">
      <Container maxWidth="xxl">
        <Grid container spacing={3}>
            <AgentReportCard reportData={reportData}/>
        </Grid>
      </Container>
    </Page>
  );
}
