import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
// import Iconify from './Iconify';
import Iconify from './components/Iconify';
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active,userType }) {



  // if(userType && userType === 8){
  //   const path = item.children
  //   console.log(path)
  // }

  // else{

  // }

  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, isHideInXs } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  const checkHideInXs = (isHide) => isHide ? {display: { xs: 'none', sm: 'flex' }} : {}


  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          style={{dispaly: "none"}}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        ...checkHideInXs(isHideInXs)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, userData, ...other }) {

  console.log('navConfig',navConfig)
  const { pathname } = useLocation();

  const match = (path) => {
    return (path ? !!matchPath({ path }, pathname) : false)
  };

  const clientId = Number(userData.client_id)
  const userType = Number(userData.user_type)

  let verificationStatus = localStorage.getItem('verification_status')

  verificationStatus = verificationStatus === 'null' ? false : true

  const [filteredNavConfig, setFilteredNavConfig] = useState([]);
  const [updatedDeploymentMenuItems, setupdatedDeploymentMenuItems] = useState([]);
  const [updatedMenuItems, setupdatedMenuItems] = useState([]);

useEffect(() => {
  debugger
  const newNavConfig = [];
  console.log(navConfig)
  if (userType === 8) {
    const settingsItem = navConfig.find(item => item.title === "Settings");
    const opsDashboardItem = navConfig.find(item => item.title === "DashBoard");
    const eventsReportItem = navConfig.find(item => item.title === "Event Reports");

    if (opsDashboardItem) newNavConfig.push(opsDashboardItem);
    if (eventsReportItem) newNavConfig.push(eventsReportItem);
    if (settingsItem) {
      newNavConfig.push(...settingsItem.children.filter(child => child.title === "Add Agent"));
      newNavConfig.push(...settingsItem.children.filter(child => child.title === "client Assign"));
    }

    setFilteredNavConfig(newNavConfig);
  } else {
    const MenuItems = navConfig.filter(item => item.title !== "Analytics" && item.title !== "Add Camera");

    setupdatedMenuItems(MenuItems)

    const deploymentMenuItems = navConfig.filter(item => item.title !== "DashBoard" && item.title !== "Event Reports");

    let items = deploymentMenuItems.map(item => {
      if (item.title === "Settings" && item.children) {
        return {
          ...item,
          children: item.children.filter(child => child.title !== "Add Agent" && child.title !== "client Assign")
        };
      }
      return item;
    });

    setupdatedDeploymentMenuItems(items)
  }

  
}, [userType, navConfig]);

  // let filteredNavConfig = [];
  // let updatedDeploymentMenuItems = [];
  // let updatedMenuItems = [];
 
  // if (userType === 8) {
  //   ////debugger
  //   const settingsItem = navConfig.find(item => item.title === "Settings");
  //   const opsDashboardItem = navConfig.find(item => item.title === "DashBoard");
  //   const eventsReportItem = navConfig.find(item=>item.title === "Event Reports")
  //   if (opsDashboardItem) {
  //     filteredNavConfig.push(opsDashboardItem);
  //   }
  //   if(eventsReportItem){
  //     filteredNavConfig.push(eventsReportItem);
  //   }
  //   if (settingsItem) {
  //     filteredNavConfig.push(...settingsItem.children.filter(child => child.title === "Add Agent"));
  //   } 

  // }

  // else{
  //   updatedMenuItems = navConfig.filter(item => item.title !== "Analytics" && item.title !== "Add Camera");

  //   const deploymentMenuItems = navConfig.filter(item => item.title !== "DashBoard" && item.title !== "Event Reports");
  
  //   updatedDeploymentMenuItems = deploymentMenuItems.map(item => {
  //     if (item.title === "Settings" && item.children) {
  //         item.children = item.children.filter(child => child.title !== "Add Agent");
  //     }
  //     return item;
  // });
  // }


  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {((!verificationStatus && userType === 9) || (verificationStatus && userType === 2)  || (userType === 37) 
        || (userType === 33)  || (userType === 30)  || (userType === 31)  || (userType === 32)  || (userType === 35)  || (userType === 7) ||(userType === 38)) 
        && (updatedDeploymentMenuItems.map((item) => ((item.positiveAccess.length === 0 || (item.positiveAccess.includes(clientId) && (item.positiveUserRoles.includes(userType) || item.positiveUserRoles.length === 0))) && (!item.negativeUserRoles.includes(userType)) && !item.negativeAccess.includes(clientId)) && (
          <NavItem key={item.title} item={item} active={match} userType={userType}/>
        )))}


        {!verificationStatus && userType===2 && (updatedMenuItems.map((item) => ((item.positiveAccess.length === 0 || (item.positiveAccess.includes(clientId) && (item.positiveUserRoles.includes(userType) || item.positiveUserRoles.length === 0))) && (!item.negativeUserRoles.includes(userType)) && !item.negativeAccess.includes(clientId)) && (
          <NavItem key={item.title} item={item} active={match} userType={userType}/>
        )))}

        {(!verificationStatus && userType === 8) && (filteredNavConfig.map((item) => ((item.positiveAccess.length === 0 || (item.positiveAccess.includes(clientId) && (item.positiveUserRoles.includes(userType) || item.positiveUserRoles.length === 0))) && (!item.negativeUserRoles.includes(userType)) && !item.negativeAccess.includes(clientId)) && (
          <NavItem key={item.title} item={item} active={match} userType={userType}/>
        )))}
        
      </List>
    </Box>
  );
}