import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
const setReleaseAudit = (body,callback) => {
    axios({
        method: 'post',
        url: getURL( `cacheaudits/release_audits_by_audit_no?`,true),
       
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


// const getMonitoringTimings = (body,callback) => {
//     axios({
//         method: 'get',
//         url: getURL('/monitor-timings/by-device?device_id='+body),
//         //data: body,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }

const setReponAudit = (body,callback) => {
    axios({
        method: 'post',
        url: getURL( `/cacheaudits/re-open-audit`,true),
       
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


export { setReleaseAudit ,setReponAudit }
