import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import PropTypes from 'prop-types';
import Iconify from '../../../components/Iconify';
import axios from '../../../axios'
import { handleAccess } from 'src/utils/responseHandler';
import { getURL } from 'src/utils/config';


// ----------------------------------------------------------------------

export default function LoginForm({setToken, setAccess, setUserType, setuserData}) {

  const [showPassword, setShowPassword] = useState(false);
  const [isError, setErrorMsg ] = useState({status: false, msg:""})

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });

 
  const jsonToFormData = (data) => {
    let formdata = new FormData()
    for (let x in data) 
      formdata.append(x, data[x])
    return formdata
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      grant_type: 'password'
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setErrorMsg((er) => er.status = false)
      handleLogin(jsonToFormData(values))      
    }
  });

  const handleLogin = (body, type='form') => {
    localStorage.clear()
    setToken(null)
    setUserType(null)
    setAccess(false)
    setuserData({
      "access_token": null,
      "token_type": null,      
      "ID": null,
      "IsLicenseBase": null,
      "IsAgentBased": null,
      "name": null,
      "displayname": null,
      "logo_url": null,
      "user_type": null,
      "client_id": null
  })
    axios({
      method: 'post',
      url: getURL('token'),
      data: body,
      validateStatus: function (status) {
        return status >= 200 || status === 400;
      }
    }).then(function (response) {
      debugger
      console.log(response.data)
      //console.log(response)

      if(response.data[0]?.VerificationStatus === 0){
        setSubmitting(false)
        setErrorMsg({status: true, msg: (response.data.error_description ?? 'Please verify your email')})
        return
      }

      if (type === 'form')
        setSubmitting(false)
      if (
        response.status === 200 && 
        ('access_token' in response.data) && (response.data.user_type === 9 ||response.data.user_type === 2 || response.data.user_type === 37 ||  response.data.user_type === 8 
          || response.data.user_type === 33 || response.data.user_type === 30|| response.data.user_type === 31|| response.data.user_type === 32|| response.data.user_type === 35|| response.data.user_type === 7|| response.data.user_type === 38)
        ) {
        const res = response.data
        for (let x in res)
          localStorage.setItem(x, res[x])

        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
        handleAccess (setAccess, AUTH_TOKEN)
        axios.defaults.headers.common.Authorization = AUTH_TOKEN;


        setToken(parseInt(response.data.user_type))
        setUserType(localStorage.getItem('user_type'))
        setuserData({
          "access_token": res['access_token'],
          "token_type": res['token_type'],
          "ID": res['ID'],
          "IsLicenseBase": res['IsLicenseBase'],
          "IsAgentBased": res['IsAgentBased'],
          "name": res['name'],
          "displayname": res['displayname'],
          "logo_url": res['logo_url'],
          "user_type": res['user_type'],
          "client_id": res['client_id']
      })
  
        // navigate('/', { replace: true })
      } else if (type === 'form')
        setErrorMsg({status: true, msg: (response.data.error_description ?? 'The user name or password is incorrect')})            
    })
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  

  return (
    <FormikProvider value={formik}>
      {(isError.status) && (
        <Alert severity="error" sx={{mb:3}}>{isError.msg}</Alert>
      )}      
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>        
        <Stack spacing={3} sx={{mb:3}}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Username"
            placeholder='Enter Username'
            InputLabelProps={{ shrink: true }}  
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            placeholder='Enter Password'
            {...getFieldProps('password')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

LoginForm.propTypes = {
  setToken: PropTypes.func.isRequired
}