import React, { useEffect, useState } from 'react';
import {
  Card, Stack, Container, Typography, CardContent, Box, Divider, Grid, Alert, IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import dayjs from 'dayjs';
import { getMonitoringTimings, postMonitoringTimings,addMonitoringTimings } from 'src/api/analytics';
import Page from 'src/components/Page';
import client from 'src/api/clients';
import { faL } from '@fortawesome/free-solid-svg-icons';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const cardColors = ['#FFE0E0', '#E0F5FF', '#E0FFE0', '#FFF5E0', '#F0E0FF', '#E0E8FF', '#FFE0F0'];

export default function MonTimings({ DeviceInfo,ClientInfo,close }) {
 // //debugger

  const [timings, setTimings] = useState(
    daysOfWeek.map(() => [])
  );
  const [status, setStatusBase] = useState(false);
  const [message, setMessage] = useState('');
  const [error,setError] = useState(false);
  const [errorMessages,setErrorMessages] = useState([])
  const [res,setRes] = useState([])
  const [hideRemove, setHideRemove] = useState(daysOfWeek.map(() => true)); 
  const [dayIndex,setDayIndex] = useState(null)
  const [slotIndex,setSlotIndex] = useState(null)

  useEffect(() => {
    const selectedDevices = DeviceInfo;
        getMonitoringTimings(selectedDevices, (response) => {
          if (response.status === 200) {
            ////debugger
            const fetchedTimings = response.data;
            setRes(response.data)
            console.log(response.data)
            if(selectedDevices.length === 1){            
                  setTimings((prevTimings) => {
                    const updatedTimings = [...prevTimings];
                      fetchedTimings.forEach((timing) => {
                        //const id = timing.Id
                        const { Dayno } = timing; 
                        if (Dayno >= 0 && Dayno < daysOfWeek.length) {
                          // Append each timing slot for the corresponding day
                          updatedTimings[Dayno].push({
                            fromTime: dayjs(timing.FromHour, 'HH:mm:ss'),
                            toTime: dayjs(timing.ToHour, 'HH:mm:ss'),
                            Id:timing.Id,
                            deviceId : timing.CameraId
                          });
                        }
                      }); 
                      const updatedHideRemove = updatedTimings.map(daySlots => daySlots.length <= 1);
                      setHideRemove(updatedHideRemove);                           
                    return updatedTimings;
                  }); 
                 
            }
            else{
              //console.log(timings)
              const defaultTimings = daysOfWeek.map(() => [{
                fromTime: dayjs('00:00:00', 'HH:mm:ss'),
                toTime: dayjs('23:59:59', 'HH:mm:ss'),
                Id:0
              }]);
              setTimings(defaultTimings);
            }                                      
          }
        });
  }, []);

  const handleAddTimeSlot = (index) => {
    //setHideRemove(false)
    setTimings((prev) => {
      const updated = [...prev];
      updated[index].push({ fromTime: dayjs().startOf('day'), toTime: dayjs().endOf('day'),Id:0 });
      setHideRemove((prevHide) => {
        //debugger
        const updatedHide = [...prevHide];
        updatedHide[index] = false; 
        return updatedHide;
      });
      return updated;
    });
  };

  useEffect(() => {
    //debugger
    // const updatedHideRemove = timings.map(daySlots => daySlots.length <= 1);
    // setHideRemove(updatedHideRemove);
    if(dayIndex!==null){
      if(timings[dayIndex].length === 1){
        setHideRemove(true)
      }
    }
    console.log(timings)

  }, [timings]);

  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    //debugger
    //console.log(dayIndex,slotIndex,timings[slotIndex].length,timings)
    // setDayIndex(dayIndex)
    // setSlotIndex(slotIndex)
    // if(timings[dayIndex].length !== 1){
    //   setTimings((prev) => {
    //     const updated = [...prev];
    //     updated[dayIndex] = updated[dayIndex].filter((_, idx) => idx !== slotIndex);
    //     return updated;
    //   });
    // }
    setTimings((prev) => {
      const updated = [...prev];
      updated[dayIndex] = updated[dayIndex].filter((_, idx) => idx !== slotIndex);
      if (updated[dayIndex].length <= 1) {
        setHideRemove((prevHide) => {
          const updatedHide = [...prevHide];
          updatedHide[dayIndex] = true; 
          return updatedHide;
        });
      }
      return updated;
    });
  };

  // const handleTimingChange = (dayIndex, slotIndex, field, value) => {
  //   setTimings((prev) => {
  //     const updated = [...prev];
  //     updated[dayIndex][slotIndex][field] = value;
  //     return updated;
  //   });
  // };


  const handleTimingChange = (dayIndex, slotIndex, field, value) => {
    setTimings((prev) => {
      const updated = [...prev];
      updated[dayIndex][slotIndex][field] = value;
  
      // Validate time slots after update
      const errorList = validateTimeSlots(updated);
  
      // Update errors for the specific day
      const updatedErrorMessages = { ...errorMessages };  // Make a copy of existing errors
      updatedErrorMessages[dayIndex] = errorList;  // Update the errors for the specific day
      setErrorMessages(updatedErrorMessages);
  
      // If there are no errors, remove error messages for that day
      if (errorList.length === 0) {
        delete updatedErrorMessages[dayIndex];
      }
  
      return updated;
    });
  };
  
  
  const validateTimeSlots = (updatedTimings) => {
    const errorList = [];
    
    updatedTimings.forEach((daySlots, dayIndex) => {
      const dayName = days[dayIndex];
      const sortedSlots = daySlots.slice().sort((a, b) => new Date(a.fromTime) - new Date(b.fromTime));
  
      const hasStartOfDay = sortedSlots.length > 1 && sortedSlots.some(item => 
        new Date(item.fromTime).getHours() === 0 && 
        new Date(item.fromTime).getMinutes() === 0 && 
        new Date(item.fromTime).getSeconds() === 0 &&
        new Date(item.toTime).getHours() === 0 && 
        new Date(item.toTime).getMinutes() === 0 && 
        new Date(item.toTime).getSeconds() === 0
      );
  
      if (hasStartOfDay) {
        errorList.push({
          Dayno: dayIndex,
          Message: `To set ${dayName} as non-monitoring, please remove the remaining slots.`
        });
      }
  
      for (let i = 0; i < sortedSlots.length; i++) {
        const slot = sortedSlots[i];
        const fromTime = new Date(slot.fromTime);
        const toTime = new Date(slot.toTime);
  
        // Check if From Time >= To Time
        if (fromTime >= toTime) {
          if (sortedSlots.length !== 1 && !areBothTimesStartOfDay(fromTime, toTime)) {
            errorList.push({
              Dayno: dayIndex,
              Message: `From Time exceeds or equals To Time at ${dayName}`
            });
            continue;
          }
        }
  
        // Check for overlapping time slots
        if (i > 0 && fromTime < new Date(sortedSlots[i - 1].toTime)) {
          if (sortedSlots.length !== 1 && !areBothTimesStartOfDay(fromTime, toTime)) {
            errorList.push({
              Dayno: dayIndex,
              Message: `Time slots are overlapping within the same day at ${dayName}`
            });
            continue;
          }
        }
      }
    });
    console.log("==================ErrorList==========",errorList)
    return errorList;
  };
  



  const convertTimeStringToDate = (timeString) => {
   // //debugger
    const dateObj = new Date(timeString);

    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
   
    const time= `${hours}:${minutes}:${seconds}`;

    return time
  };

  const areBothTimesStartOfDay = (fromTime, toTime) => {
    const startOfDay = new Date(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate());
    return fromTime.getTime() === startOfDay.getTime() && toTime.getTime() === startOfDay.getTime();
  };

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const handleSaveTime = () => {
   debugger
    const resultList = [];
    const errorList = [];
    setError(false)
  
    ClientInfo.forEach((device) => {
      //ClientInfo.forEach((client)=>{
        timings.forEach((daySlots, dayIndex) => {
          //debugger
          const dayName = days[dayIndex]; 
          const sortedSlots = daySlots.slice().sort((a, b) => new Date(a.fromTime) - new Date(b.fromTime));
          console.log(sortedSlots)
          const hasStartOfDay =sortedSlots.length > 1 && sortedSlots.some(item => new Date(item.fromTime).getHours() === 0 && new Date(item.fromTime).getMinutes() === 0 && new Date(item.fromTime).getSeconds() === 0 &&
                                       new Date(item.toTime).getHours() === 0 && new Date(item.toTime).getMinutes() === 0 && new Date(item.toTime).getSeconds() === 0);
          
          if(hasStartOfDay){
            errorList.push({
              // CameraId: device,
               Dayno: dayIndex,
               Message: `To set ${dayName} as non-monitoring, please remove the remaining slots.`
             });
          }                            
          for (let i = 0; i < sortedSlots.length; i++) {
            const slot = sortedSlots[i];
            const fromTime = new Date(slot.fromTime);
            const toTime = new Date(slot.toTime);

            const fromDate = new Date(fromTime);
            const toDate = new Date(toTime);
            
            if (fromTime >= toTime) {
              if(sortedSlots.length !== 1 && !areBothTimesStartOfDay(fromTime,toTime)){
                errorList.push({
                  // CameraId: device,
                   Dayno: dayIndex,
                   Message: `From Time exceeds or equals To Time at ${dayName}`
                 });
                 continue;
              }
            }
            
            if (i > 0 && fromTime < new Date(sortedSlots[i - 1].toTime)) {
              if(sortedSlots.length !== 1 && !areBothTimesStartOfDay(fromTime,toTime)){
                errorList.push({
                  // CameraId: device,
                   Dayno: dayIndex,
                   Message: `Time slots are overlapping within the same day at ${dayName}`
                 });
                 continue;
              }
            } 
           
            resultList.push({
              //Id : slot.Id,
              CameraId:device.deviceId.toString(),
              ClientId:device.clientId,
              Dayno: dayIndex,
              FromHour:convertTimeStringToDate(fromTime),
              ToHour:convertTimeStringToDate(toTime),
            });
          }
        });
      //})
    });
   
    if (errorList.length > 0) {
     setError(true)
      const uniqueErrors = errorList.reduce((acc, error) => {
        //debugger
        const existingError = acc.find(e =>e.Dayno === error.Dayno);
      
        if (existingError) {
          if (existingError.Message !== error.Message) {
            existingError.Message = `Time slots are overlapping within the same day at ${daysOfWeek[error.Dayno]}`;
          }
        } else {
          acc.push({
            CameraId: error.CameraId,
            Dayno: error.Dayno,
            Message: error.Message
          });
        }

        console.log("==================ErrorList==========",errorList)
        return acc;
      }, []);
      // setErrorMessage(uniqueErrors)
    } else {
      console.log(resultList)
      
      addMonitoringTimings(resultList, (response) => {
          if (response.data) {
            setStatusBase(true);
            setMessage('Monitoring Timings Inserted Successfully!!');
            setError(false)

            setTimeout(close, 2000);
          }
      });
    }
  };
  

  // const handleSaveTime = () => {
  //   //debugger     
  //   const resultList = [];
  //   const errorList = [];
  //   let slots = 0;
  //   console.log(DeviceInfo)
  //   console.log(timings)

  //   DeviceInfo.forEach((device) => {
  //     timings.forEach((daySlots, dayIndex) => {
  //       daySlots.forEach((slot) => {
  //         //debugger          
  //         if(slot.fromTime > slot.toTime){
  //           errorList.push({
  //             CameraId:device,
  //             Dayno : dayIndex,
  //             Message : 'From Time Should not Exceeds To Time'
  //           })
  //         }
  //         resultList.push({
  //           CameraId: device,
  //           Dayno: dayIndex,
  //           FromHour: slot.fromTime.format('HH:mm:ss'),
  //           ToHour: slot.toTime.format('HH:mm:ss'),
  //         });
  //       });
  //     });
  //   });

   
  //   console.log('post timings ', resultList);

  //   // postMonitoringTimings(resultList, (response) => {
  //   //   if (response.data) {
  //   //     setStatusBase(true);
  //   //     setMessage('Monitoring Timings Inserted Successfully!!');
  //   //   }
  //   // });
  // };

  return (
<Page title="Monitoring Times">
  <Container>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
        Monitoring Times
      </Typography>
    </Stack>
    <Card sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {daysOfWeek.map((day, dayIndex) => (
          <Grid item xs={12} key={dayIndex}>
            <Card sx={{ py: 2, boxShadow: 0, bgcolor: cardColors[dayIndex] }}>
              <CardContent>
                <Typography variant="h6" sx={{ textAlign: 'left', mb: 2 }}>
                  {day}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  {timings[dayIndex].map((slot, slotIndex) => (
                    <Grid item xs="auto" key={slotIndex}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          views={['hours', 'minutes', 'seconds']}
                          label={`From (${slotIndex + 1})`}
                          value={slot.fromTime}
                          onChange={(newValue) =>
                            handleTimingChange(dayIndex, slotIndex, 'fromTime', newValue)
                          }
                          format="HH:mm:ss"
                          ampm={false}
                          sx={{ width: 150 }}
                        />
                        <Box m={1} />
                        <TimePicker
                          views={['hours', 'minutes', 'seconds']}
                          label={`To (${slotIndex + 1})`}
                          value={slot.toTime}
                          onChange={(newValue) =>
                            handleTimingChange(dayIndex, slotIndex, 'toTime', newValue)
                          }
                          format="HH:mm:ss"
                          ampm={false}
                          sx={{ width: 150 }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  ))}
                  <Grid item xs="auto">
                    <IconButton onClick={() => handleAddTimeSlot(dayIndex)}>
                      <AddCircleOutlineIcon color="primary" />
                    </IconButton>
                    {!hideRemove[dayIndex] && (
                      <IconButton
                        color="error"
                        onClick={() =>
                          handleRemoveTimeSlot(dayIndex, timings[dayIndex].length - 1)
                        }
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Display errors for the day */}
            {errorMessages[dayIndex] && errorMessages[dayIndex].length > 0 && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                              {errorMessages[dayIndex].map((item, idx) => (
                                // Check if dayIndex and item.Dayno are the same
                                item.Dayno === dayIndex && (
                                  <div key={idx}>
                                    <div>{item.Message}</div>
                                  </div>
                                )
                              ))}
                            </Alert>
                          )}

          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <LoadingButton variant="contained" color="error" onClick={close}>
          Close
        </LoadingButton>
        <LoadingButton variant="contained" onClick={handleSaveTime}>
          Save
        </LoadingButton>
      </Stack>
    </Card>
  </Container>
</Page>

  );
}