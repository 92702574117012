import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer, InputLabel, Select, MenuItem,
  Card,
  Autocomplete, createFilterOptions,
  TextField,
  FormControl,
  Grid,
  CardContent, Stack, DialogActions,
  Dialog,
  IconButton, Checkbox,
  InputAdornment,
  DialogContent,

} from "@mui/material";

import Scrollbar from "src/components/Scrollbar";


import Iconify from "src/components/Iconify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getClientList } from "src/api/analytics";
import { newSubLogins, getSubLoginDetails, updateLoginDetails } from "src/api/newSublogin";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { faL } from "@fortawesome/free-solid-svg-icons";

const ClientConsolidationLogin = () => {

  //variable
  const [value3, setValue3] = useState([]);
  const [agentName, setAgentName] = useState("");
  const [userNewName, setNewUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //error 
  const [error, setError] = useState('')
  const [agentNameError, setagentNameError] = useState('');
  const [userNewNameError, setuserNewNameError] = useState('');
  const [passwordError, setpasswordTypeError] = useState('');
  const [confirmPasswordError, setconfirmPasswordError] = useState('');


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [visiblePasswordIndex, setVisiblePasswordIndex] = useState(null);


  const [userDetails, setUserDetails] = useState([]);

  //Edit Dailog box
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const [isEdited, setIsEdited] = useState(false)
  const [prvValue, setPrvValue] = useState('')

  const [rowData, setId] = useState([])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  const [openMessage, setOpenMessage] = useState(false)
  const [isClientSelected, setIsClientSelected] = useState(false)
  const [clientList, setClientList] = useState([])
  const [subLogin, setSubLoginList] = useState([])
  const [subLoginSelect, setSubLoginSelectList] = useState([])

  //Multiple selection

  const [activeClients, setActiveClients] = useState([])
  const [openClientAuto, setOpenClientAuto] = useState(false)
  const [openEditClientAuto, setOpenEditClientAuto] = useState(false)
  const iconClient = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIconClient = <CheckBoxIcon fontSize="small" />;
  const [checkClientValue, setCheckClientValue] = useState(false)
  const [activeSelectedClients, setActiveSelectedClients] = useState([]);

  const [clientType, setClientType] = useState('')
  const [clientTypeerror, setClientTypeError] = useState('')
  const [setTypeClient, setSelectedTypeClient] = useState([]);
  const [clientEdit, setEditclient] = useState([]);
  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);

    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeClients.includes(option.ID));
    const unselectedOptions = filtered.filter(option => !activeClients.includes(option.ID));

    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];

    return [{ title: 'Select All', selectall: true }, ...filtered];
  };

  const handleClientChange = (event, value) => {
    //debugger
    let actClients = [];

    if (value.some(obj => obj.hasOwnProperty('selectall'))) {
      // //debugger
      if (activeClients.length === setTypeClient.length) {
        setActiveClients([])
        setCheckClientValue(false)

        //alert('unchecked all')
        //tabRef.current = true;
        //handleDisplay()
      }
      else {
        setTypeClient.forEach(item => {
          actClients.push(item.ID)
        })
        setActiveClients(actClients)
        setCheckClientValue(true)


      }
    }
    else {
      ////debugger
      let id = value[0].ID;
      if (activeClients.includes(id)) {
        let filterArr = activeClients.filter(client => client !== id);

        setActiveClients(filterArr);
        if (filterArr.length === 0) {
          //alert('unchecked all')
          // tabRef.current = true;
          //handleDisplay()
        }

        if (checkClientValue) {
          setCheckClientValue(false)
        }
      }
      else {
        setActiveClients((prv) => [...prv, id])
      }
    }
  }

  useEffect(() => {

    if (activeClients.length === setTypeClient.length ) {
      setCheckClientValue(true);
    }
  }, [activeClients])

  // const handleClientChange = (event,value) =>{

  //   if(value==="add"){
  //     setDialogData({ ...dialogData, ClientID: event.target.value });
  //     setValue3(event.target.value)
  //   }
  //   else{
  //     seteditedData({...editedData,ClientID:event.target.value})
  //   }

  // }

  useEffect(() => {
    setCheckClientValue(false)
    getClientList((response) => {
      if (response.status === 200) {
        setClientList(response.data)
      }
    })
    const data = {
      type: 'merge'
    }
    getSubLoginDetails(data, (response) => {
      if (response.status === 200) {
        console.log("getmergeLoginDetails", response.data);
        setSubLoginList(response.data);


      }
    })

  }, [])



  useEffect(() => {
    ////debugger
    console.log("sub login", subLogin);
    setCheckClientValue(false)
    if (clientType === "INDIA") {
      console.log("clientType", clientType)
      const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
      setSelectedTypeClient(indiaClients)
      const indiaClientsSub = subLogin.filter((row) => row.ClientType === 'INDIA');
      console.log("indian clients list ",indiaClientsSub);
      setSubLoginSelectList(indiaClientsSub)
    }
    else if (clientType === "US") {
      const usClients = clientList.filter((row) => row.ClientType === 'US');
      setSelectedTypeClient(usClients)
      const usClientsSub = subLogin.filter((row) => row.ClientType === 'US');
      setSubLoginSelectList(usClientsSub)
    }

  }, [clientType])

  const [dialogData, setDialogData] = useState({
    // userType: '',
    agentName: '',
    mobileNumber: '',
    userNewName: '',
    password: '',
    confirmPassword: '',

  });


  const [editedData, seteditedData] = useState({
    Id: '',
    UserID: '',
    agentName: '',
    userNewName: '',
    password: '',
    confirmPassword: '',
  });

  const handleAgentNameChange = (event, val) => {
    if (val === "add") {
      setDialogData({ ...dialogData, agentName: event.target.value });
      setAgentName(event.target.value)
    }
    else {
      seteditedData({ ...editedData, agentName: event.target.value })
    }

  };


  const handleUsername = (event, val) => {
    if (val === "add") {
      setDialogData({ ...dialogData, userNewName: event.target.value });
      setNewUsername(event.target.value)
    }
    else {
      setIsEdited(true)
      setPrvValue(editedData.userNewName)
      seteditedData({ ...editedData, userNewName: event.target.value })
    }
  };

  const handlePasswordChange = (event, val) => {
    if (val === "add") {
      setDialogData({ ...dialogData, password: event.target.value });
      setPassword(event.target.value)
    }
    else {
      seteditedData({ ...editedData, password: event.target.value })
    }
  };

  const handleConfirmPasswordChange = (event, val) => {
    if (val === "add") {
      setDialogData({ ...dialogData, confirmPassword: event.target.value });
      setConfirmPassword(event.target.value)
    }
    else {
      seteditedData({ ...editedData, confirmPassword: event.target.value })
    }

  };



  const handleEditedImageChange = (event) => {
    ////debugger
    console.log(event.target.files[0])
    seteditedData({ ...editedData, image: event.target.files[0] });
  };

  function isUniqueValue(newDisplayName, newUserName, subLogin) {
    for (const login of subLogin) {
        if (login.DisplayName === newDisplayName) {
          setagentNameError("ProfileName Already Exist");
          return false

        }
        if(login.UserName === newUserName){
          setuserNewNameError("UserName Already Exist");
          return false
        }
    }

    if(newDisplayName === newUserName){
      setagentNameError("ProfileName and UserName Should be Different");
      setuserNewNameError("ProfileName and UserName Should be Different");
      return false;
    }
    return true; // Return true if no match is found
}

function isUniqueEditValue(newDisplayName, newUserName, subLogin) {
  //debugger
  console.log(editedData)
  let id = editedData.Id
  for (const login of subLogin) {
      if (login.DisplayName === newDisplayName && login.Id !== id) {
        setEditProfileNameError("Please Enter the ProfileName");
        return false

      }
      if(login.UserName === newUserName && login.Id !== id){
        setEditNameError("Please Enter the UserName");
        return false
      }      
  }

  if(newDisplayName === newUserName){
    setEditProfileNameError("ProfileName and UserName should be different");
    setEditNameError("ProfileName and UserName should be different");
    return false
  }
  return true; // Return true if no match is found
}




  const handleSubmit = () => {
    console.log("values ", activeClients, agentName, userNewName, password, confirmPassword, clientType)
    ////debugger
    let Count = 0;
    // setuserTypeError('')
    setError('')
    setagentNameError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
 

    if (activeClients.length === 0) {
      setError("please select the client")
    }

    // if(!clientTypeerror){
    //   setClientTypeError("Please select the client Type")
    //   Count = 1;
    // }


    if (!agentName.trim()) {
      setagentNameError("Please Enter the ProfileName");
      Count = 1;
    }
    
    if (!userNewName.trim()) {
      setuserNewNameError("Please Enter the UserName");
      Count = 1;
    }
    
    if (!password.trim()) {
      setpasswordTypeError("Please Enter the Password");
      Count = 1;
    }
    
    if (!confirmPassword.trim()) {
      setconfirmPasswordError("Please Enter the ConfirmPassword");
      Count = 1;
    }
    
    if (password.trim() !== confirmPassword.trim()) {
      setconfirmPasswordError("Password and ConfirmPassword not Matching");
      Count = 1;
    }


    if (Count === 0) {

      if (!isUniqueValue(agentName, userNewName, subLogin)) {
        console.log("The agentName or userNewName already exists in the list.");
        // Modify the new values to be unique
        // For example, append some random number or string to ensure uniqueness

      }
      else{
        const data = {
          UserID: `${activeClients}`,
          displayname: agentName,
          Name: userNewName,
          Password: password,
          Type: "merge"
        }

        
        console.log("data", data);
        newSubLogins(data, (response) => {
          if (response.status === 200) {
            console.log("new merge login data ", response.data)
            //handleClear();

            setAgentName('');
            setNewUsername('');
            setPassword('');
            setConfirmPassword('');

            const data = {
              type: 'merge'
            }
            getSubLoginDetails(data, (response) => {
              if (response.status === 200) {
                console.log("getSubLoginDetails", response.data);
                setSubLoginList(response.data);
                ////debugger
                let result = response.data;
                if (clientType === "INDIA") {
                  const indiaClientsSub = result.filter((row) => row.ClientType === 'INDIA');
                  setSubLoginSelectList(indiaClientsSub)
                }
                else {
                  const usClientsSub = result.filter((row) => row.ClientType === 'US');
                  setSubLoginSelectList(usClientsSub)
                }
                setActiveClients([])
              }
            })
          }
        })
      }

    }
  };

  const handleClear = () => {
    // setUserType('');
    ////debugger
    setError('')
    setAgentName('');
    // setMobileNumber('');
    setNewUsername('');
    setPassword('');
    setConfirmPassword('');
    // setImage(null);
    setClientType('');
    setClientTypeError('')
    // setuserTypeError('')
    setagentNameError('')
    // setmobileNumberError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
    //setSubLoginList([])
    //setSubLoginSelectList([])
    // setImageError('')
    console.log(subLoginSelect)
    if (subLoginSelect.length > 0) {
      setSubLoginSelectList([])
    }

  }

  const handleEdit = (data) => {
    debugger
    console.log("data to be edited ", data);
    editclientList(data.ClientType);

    const filteredClients = clientList.filter(client =>
      data.ClientId.includes(client.ID)
    );

    const initialSelectedClients = filteredClients.map((client) => client.ID);
    setActiveSelectedClients(initialSelectedClients);

    console.log("filteredClients", filteredClients);
    console.log("selectedClient ", data.ClientId);

    ////debugger //Image
    setShowMessage(false)
    // if(data.Password === confirmPassword)
    seteditedData({
      Id: data.Id,
      agentName: data.DisplayName,
      userNewName: data.UserName,
      password: data.Password,
      confirmPassword: data.Password,
      UserID: filteredClients
    })
    setOpenDialogEdit(true);
  };

  const editclientList = (type) => {
    console.log("type ===", type);

    if (type === "INDIA") {
      console.log("clientType", clientType)
      const indiaClients = clientList.filter((row) => row.ClientType === 'INDIA');
      setEditclient(indiaClients)

    }
    else if (type === "US") {
      const usClients = clientList.filter((row) => row.ClientType === 'US');
      setEditclient(usClients)
    }


  }

  const [editclientError, setEditClientError] = useState('')
  const [editNameError, setEditNameError] = useState('')
  const [editProfileNameError, setEditProfileNameError] = useState('')

  // const [mobileError, setMobileError] = useState('')
  const [editedPasswordError, setEditedPasswordError] = useState('')
  const [editedConfirmPasswordError, setEditedConfirmPasswordError] = useState('')

  const [showMessage, setShowMessage] = useState(false)

  const handleUpdated = () => {
    debugger
    setEditClientError('');
    setEditNameError('');
    setEditProfileNameError('');
    setEditedPasswordError('')
    setEditedConfirmPasswordError('');
    
    console.log("activeSelectedClients ", activeSelectedClients);
    let count = 0


    // const userIds = Object.values(activeSelectedClients).map(item => item.ID);
    // console.log("User IDs:", userIds); // Output: [1, 2, 3]


    ////debugger
    //setShowMessage(false)
    const updateData = {
      ID: editedData.Id,
      UserID: `${activeSelectedClients}`,
      displayname: editedData.agentName,
      Name: editedData.userNewName,
      Password: editedData.password,
      Type: "merge",
      Status: 1
    }
    console.log("updateData==", updateData)
    if (activeSelectedClients.length === 0) {
      setEditClientError("please select the client")
      count = 1

    }

    if (!editedData.agentName.trim()) {
      setEditProfileNameError("Please Enter the ProfileName");
      count = 1
    }
    
    if (!editedData.userNewName.trim()) {
      setEditNameError("Please Enter the UserName");
      
      count = 1
    }
    
    if (!editedData.password.trim()) {
      setEditedPasswordError("Please Enter the Password");
      count = 1
    }
    
    if (!editedData.confirmPassword.trim()) {
      setEditedConfirmPasswordError("Please Enter the ConfirmPassword");
      count = 1
    }
    
    if (editedData.password.trim() !== editedData.confirmPassword.trim()) {
      setEditedPasswordError("Password and ConfirmPassword not Matching");
      setEditedConfirmPasswordError("Password and ConfirmPassword not Matching");
      count = 1
    }
    


    if ( count === 0 && editedData.password === editedData.confirmPassword && updateData.UserID !== '' && updateData.UserID !== null && editedData.password !== '' && editedData.password !== null && editedData.agentName !== '' && editedData.agentName !== null && editedData.userNewName !== '' && editedData.userNewName !== null) {
     

      if (!isUniqueEditValue(editedData.agentName, editedData.userNewName, subLogin)) {
        console.log("The agentName or userNewName already exists in the list.");
        // Modify the new values to be unique
        // For example, append some random number or string to ensure uniqueness

      }
      else{
        updateLoginDetails(updateData, (response) => {
          if (response.status === 200) {
            setOpenDialogEdit(false);
  
            const data = {
              type: 'merge'
            }
            getSubLoginDetails(data, (response) => {
              if (response.status === 200) {
                console.log("getSubLoginDetails", response.data);
                setSubLoginList(response.data);
                let result = response.data;
                if (clientType === "INDIA") {
                  const indiaClientsSub = result.filter((row) => row.ClientType === 'INDIA');
                  setSubLoginSelectList(indiaClientsSub)
                }
                else {
                  const usClientsSub = result.filter((row) => row.ClientType === 'US');
                  setSubLoginSelectList(usClientsSub)
                }
              }
            })
          }
        })
      }


    }




    


  }

  const handleClose = () => {
    setOpenDialogEdit(false);
    setIsEdited(false)
    setPrvValue('')
    setEditNameError('')
    setEditedPasswordError('')
    setShowMessage(false)
    setOpenMessage(false)
    setEditClientError('');
    setEditProfileNameError('');
    setEditedConfirmPasswordError('');
  };




  const handleDisable = (data) => {
    // Add your disable logic here
    console.log("data...", data);
    setOpenMessage(true)
    // let Id=data.ID?data.ID:0
    setId(data)

  };

  const disableUser = () => {

    console.log("rowData", rowData);
    const disable = {
      ID: rowData.Id,
      UserID: `${rowData.ClientId}`,
      displayname: rowData.DisplayName,
      Name: rowData.UserName,
      Password: rowData.Password,
      Type: "merge",
      Status: rowData.Status === 0 ? 1 : 0,
    }

    console.log("disbale option ", disable)

    updateLoginDetails(disable, (response) => {

      if (response.status === 200) {
        setOpenMessage(false)
        const data = {
          type: 'merge'
        }
        getSubLoginDetails(data, (response) => {

          if (response.status === 200) {
            console.log("getSubLoginDetails", response.data);
            setSubLoginList(response.data);
            //console.log(subLoginSelect)
            let result = response.data;
            if (clientType === "INDIA") {
              const indiaClientsSub = result.filter((row) => row.ClientType === 'INDIA');
              setSubLoginSelectList(indiaClientsSub)
            }
            else {
              const usClientsSub = result.filter((row) => row.ClientType === 'US');
              setSubLoginSelectList(usClientsSub)
            }

          }
        })
      }
    })



  }


  // Function to handle the icon click
  const handlePasswordVisibilityToggle = (index) => {
    setVisiblePasswordIndex(visiblePasswordIndex === index ? null : index);
  };




  const sortedClients = clientEdit.sort((a, b) => {
    const isSelectedA = activeSelectedClients.includes(a.ID);
    const isSelectedB = activeSelectedClients.includes(b.ID);
    return isSelectedB - isSelectedA; // Move selected ones to the top
  });



  const handleClientType = (event) => {
    // console.log("client type",event.target.value)
    //debugger
    setActiveClients([])

    setClientType(event.target.value)

    setError('')
    setAgentName('');
    // setMobileNumber('');
    setNewUsername('');
    setPassword('');
    setConfirmPassword('');
    // setImage(null);
    // setClientType('');
    // setClientTypeError('')
    // setuserTypeError('')
    setagentNameError('')
    // setmobileNumberError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')

  }

  return (

    <Card variant="outlined" sx={{ p: 3 }}>
      <CardContent>
        <Typography variant="h4" >Client Consolidation Login</Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid container spacing={2} alignItems="center" marginTop={4}>
            <Grid item md={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-labell">Client Type</InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clientType}
                  label="Client Type"
                  onChange={handleClientType}
                  // helperText={touched.ClientType && errors.ClientType}
                  error={clientTypeerror ? true : false}
                >

                  <MenuItem value="US">US</MenuItem>
                  <MenuItem value="INDIA">INDIA</MenuItem>

                </Select>
                {clientTypeerror && <Typography variant="body2" color="error">{clientTypeerror}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              {/* <Autocomplete
                      multiple
                      fullWidth
                      id="client"
                      options={setTypeClient}
                      value={[]}
                      getOptionLabel={(option) =>
                        option.selectall && setTypeClient.length === activeClients.length
                          ? 'Select All'
                          : `${option.displayname}`
                      }
                      onChange={handleClientChange}
                      disableCloseOnSelect={openClientAuto}
                      open={openClientAuto}
                      onOpen={() => setOpenClientAuto(true)}
                      onClose={() => setOpenClientAuto(false)}
                      filterOptions={filterOptions}
                      renderOption={(props, option, { selected }) => {
                        const key = `camid-${option.ID}`;
                        
                        const handleCheckboxChange = () => {
                          const isChecked = activeClients.includes(option.ID);
                          const newActiveClients = isChecked
                            ? activeClients.filter((clientId) => clientId !== option.ID)
                            : [...activeClients, option.ID];
                          setActiveClients(newActiveClients);
                        };

                        return (
                          <li
                            {...props}
                            key={key}
                            onClick={(event) => {
                              event.preventDefault(); // Prevent default behavior (like closing the dropdown)
                              handleCheckboxChange(); // Trigger checkbox change
                            }}
                          >
                            <Checkbox
                              icon={iconClient}
                              checkedIcon={checkedIconClient}
                              style={{ marginRight: 8 }}
                              checked={
                                option.selectall
                                  ? activeClients.length === setTypeClient.length
                                  : activeClients.includes(option.ID)
                              }
                            />
                            {option.selectall ? option.title : option.displayname}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client"
                          onFocus={() => setOpenClientAuto(true)}
                          error={error ? true : false}
                        />
                      )}
                    /> */}
              {/* setTypeClient */}

              <Autocomplete
                multiple
                fullWidth
                id="client"
                options={setTypeClient}
                value={[]}
                getOptionLabel={(option) => (option.selectall && setTypeClient.length === activeClients.length) ? 'Select All' : `${option.displayname}`}
                onChange={handleClientChange}
                disableCloseOnSelect={openClientAuto}
                open={openClientAuto}
                onOpen={() => setOpenClientAuto(true)}
                onClose={() => setOpenClientAuto(false)}
                filterOptions={filterOptions}
                renderOption={(props, option, { selected }) => {
                  const key = `camid-${option.ID}`;
                  return (
                    <li {...props} key={key}>
                      {option.selectall ? (
                        <>
                          <Checkbox
                            icon={iconClient}
                            checkedIcon={checkedIconClient}
                            style={{ marginRight: 8 }}
                            checked={checkClientValue}
                          //onChange={() => setCheckClientValue(!checkClientValue)}
                          />
                          {option.title}
                        </>
                      ) : (
                        <>
                          <Checkbox
                            icon={iconClient}
                            checkedIcon={checkedIconClient}
                            style={{ marginRight: 8 }}
                            checked={activeClients.includes(option.ID)
                              // || selectedClient.some(client => client.ID === option.ID)
                            }
                            onChange={() => {
                              const isChecked = activeClients.includes(option.ID);
                              const newActiveClients = isChecked
                                ? activeClients.filter((clientId) => clientId !== option.ID)
                                : [...activeClients, option.ID];
                              setActiveClients(newActiveClients);
                            }}
                          />
                          {`${option.displayname}`}
                        </>
                      )}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Client" onFocus={() => setOpenClientAuto(true)} />
                )}
              />


              {error && <Typography variant="body2" color="error">{error}</Typography>}
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  id="agent-name"
                  variant="outlined"
                  value={agentName}
                  label="Profile Name"
                  onChange={(e) => handleAgentNameChange(e, "add")}
                  error={agentNameError ? true : false}
                />
                {agentNameError && <Typography color="error" variant="body2"  >{agentNameError}</Typography>}
              </FormControl>
            </Grid>


            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  id="userdisplayName"
                  label="User Name"
                  variant="outlined"
                  value={userNewName}
                  onChange={(e) => handleUsername(e, "add")}
                  error={userNewNameError ? true : false}

                />
                {userNewNameError && <Typography color="error" variant="body2"  >{userNewNameError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>


                <TextField
                  fullWidth
                  name="userpassword"
                  label="Password"
                  onChange={(e) => handlePasswordChange(e, "add")}
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  error={passwordError ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    autoComplete: 'new-password'
                  }}
                />
                {passwordError && <Typography color="error" variant="body2"  >{passwordError}</Typography>}


              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>

                <TextField
                  fullWidth
                  name="userpassword"
                  label="Confirm Password"
                  onChange={(e) => handleConfirmPasswordChange(e, "add")}
                  value={confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={confirmPasswordError ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {confirmPasswordError && <Typography color="error" variant="body2"  >{confirmPasswordError}</Typography>}



              </FormControl>
            </Grid>

            <Grid item xs={2} sx={{ height: "100%", marginTop: 2 }}>
              <Button variant="contained" onClick={handleSubmit} disabled={isButtonDisabled}>
                Add
              </Button>
              <Button color="error" variant="contained" onClick={handleClear} sx={{ marginLeft: 1 }}>
                Clear
              </Button>

            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Scrollbar>
            <TableContainer
            >

              <Table stickyHeader>
                <TableHead sx={{ background: '#f3f3f3' }}>
                  <TableRow sx={{ background: '#f3f3f3' }}>
                    <TableCell>SNo</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>ProfileName</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Password</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Your table rows go here */}
                  {subLoginSelect.map((data, index) => (
                    <TableRow key={index}
                    // style={{ backgroundColor: data.Status === 0 ? '#F5B7B1' : 'inherit' }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{data.ID}</TableCell> */}
                      <TableCell>
                        {data.ClientName.map(client => (
                          <>
                            <span>{client}</span>
                            <br />
                          </>
                        ))}</TableCell>
                      {/* <TableCell>{data.UserType === 4 ? "Agent" :data.UserType === 3 ? "Supervisor" : data.UserType ===  9 ? 'Deployment':'' }</TableCell> */}
                      <TableCell>{data.DisplayName}</TableCell>
                      <TableCell>{data.UserName}</TableCell>

                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2" style={{ marginRight: 8 }}>
                            {visiblePasswordIndex === index ? data.Password : '••••••••'}
                          </Typography>
                          <IconButton
                            onClick={() => handlePasswordVisibilityToggle(index)}
                            edge="end"
                          >
                            {visiblePasswordIndex === index ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </div>
                      </TableCell>

                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              disabled={data.Status === 0}
                              style={{
                                backgroundColor: data.Status === 0 ? "gray" : "",
                                color: "white"
                              }}
                              variant="contained"
                              onClick={() => handleEdit(data)}>
                              Edit
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button

                              variant="contained"
                              style={{
                                backgroundColor: data.Status === 1 ? "red" : "green",
                                color: "white"
                              }}
                              onClick={() => handleDisable(data)}
                            >
                              {data.Status === 1 ? "Disable" : "Enable"}
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>

        <Dialog open={openDialogEdit} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
          <DialogContent>

            <Grid container spacing={2} alignItems="center">
              <Grid container spacing={2} alignItems="center" marginTop={4}>


                <Grid item xs={3}>


                  {/* <Autocomplete
                multiple
                fullWidth
                id="clientEdit"
                options={clientList}
                value={[]}
                getOptionLabel={(option) =>
                  option.selectall && clientList.length === activeClients.length
                    ? 'Select All'
                    : `${option.displayname}`
                }
                onChange={(e)=>handleClientChange(e,"edit")}
                disableCloseOnSelect={openEditClientAuto}
                open={openEditClientAuto}
                onOpen={() => setOpenEditClientAuto(true)}
                onClose={() => setOpenEditClientAuto(false)}
                filterOptions={filterOptions}
                renderOption={(props, option, { selected }) => {
                  const key = `camid-${option.ID}`;
                  
                  const handleCheckboxChange = () => {
                    const isChecked = activeClients.includes(option.ID);
                    const newActiveClients = isChecked
                      ? activeClients.filter((clientId) => clientId !== option.ID)
                      : [...activeClients, option.ID];
                    setActiveClients(newActiveClients);
                  };

                  return (
                    <li
                      {...props}
                      key={key}
                      onClick={(event) => {
                        event.preventDefault(); // Prevent default behavior (like closing the dropdown)
                        handleCheckboxChange(); // Trigger checkbox change
                      }}
                    >
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={
                          option.selectall
                            ? activeClients.length === clientList.length
                            : editedData.UserID.includes(option.ID) || activeClients.includes(option.ID)
                        }
                      />
                      {option.selectall ? option.title : option.displayname}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    onFocus={() => setOpenEditClientAuto(true)}
                    error={error ? true : false}
                  />
                )}
              /> */}

                  {/* <Autocomplete
      multiple
      fullWidth
      id="clientEdit"
      options={clientList}
      value={[]}
      getOptionLabel={(option) =>
        option.selectall && clientList.length === activeSelectedClients.length
          ? 'Select All'
          : `${option.displayname}`
      }
      onChange={(e) => handleClientChange(e, 'edit')}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        const key = `camid-${option.ID}`;

        const handleCheckboxChange = () => {
          const isChecked = activeSelectedClients.includes(option.ID);
          const newSelectedClients = isChecked
            ? activeSelectedClients.filter((clientId) => clientId !== option.ID)
            : [...activeSelectedClients, option.ID];
          setActiveSelectedClients(newSelectedClients);
        };

        return (
          <li
            {...props}
            key={key}
            onClick={(event) => {
              event.preventDefault(); // Prevent dropdown from closing
              handleCheckboxChange(); // Update selection
            }}
          >
            <Checkbox
              style={{ marginRight: 8 }}
              checked={activeSelectedClients.includes(option.ID)}
            />
            {option.displayname}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client"
          onFocus={() => {}}
        />
      )}
    /> */}


                  <Autocomplete
                    multiple
                    fullWidth
                    id="clientEdit"
                    options={sortedClients}
                    value={[]}
                    getOptionLabel={(option) =>
                      option.selectall && sortedClients.length === activeSelectedClients.length
                        ? 'Select All'
                        : `${option.displayname}`
                    }
                    onChange={(e) => handleClientChange(e, 'edit')}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => {
                      const key = `camid-${option.ID}`;

                      const handleCheckboxChange = () => {
                        const isChecked = activeSelectedClients.includes(option.ID);
                        const newSelectedClients = isChecked
                          ? activeSelectedClients.filter((clientId) => clientId !== option.ID)
                          : [...activeSelectedClients, option.ID];
                        setActiveSelectedClients(newSelectedClients);
                      };

                      return (
                        <li
                          {...props}
                          key={key}
                          onClick={(event) => {
                            event.preventDefault(); // Prevent dropdown from closing
                            handleCheckboxChange(); // Update selection
                          }}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={activeSelectedClients.includes(option.ID)}
                          />
                          {option.displayname}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client"
                        onFocus={() => { }}
                      />
                    )}
                  />



                  {editclientError && <Typography variant="body2" color="error">{editclientError}</Typography>}
                </Grid>


                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="agent-name"
                      variant="outlined"
                      value={editedData.agentName}
                      label="Name"
                      onChange={(e) => handleAgentNameChange(e, "edit")}
                      error={editProfileNameError ? true : false}
                    />
                {editProfileNameError && <Typography color="error" variant="body2"  >{editProfileNameError}</Typography>}

                  </FormControl>
                </Grid>
                

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="userdisplayName"
                      label="User Name"
                      variant="outlined"
                      value={editedData.userNewName}
                      onChange={(e) => handleUsername(e, "edit")}
                      error={editNameError ? true : false}
                    />
                    {editNameError && <Typography color="error" variant="body2"  >{editNameError}</Typography>}

                  </FormControl>
                  {/* {editNameError && (<Typography color='error' fontSize={12}>{editNameError}</Typography>)} */}
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="userpassword"
                      label="Password"
                      variant="outlined"
                      value={editedData.password}
                      onChange={(e) => handlePasswordChange(e, "edit")}
                      error={editedPasswordError ? true : false}
                    />
                    {editedPasswordError && <Typography color="error" variant="body2"  >{editedPasswordError}</Typography>}
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      value={editedData.confirmPassword}
                      onChange={(e) => handleConfirmPasswordChange(e, "edit")}
                      error={editedConfirmPasswordError ? true : false}
                    />
                    {editedConfirmPasswordError && <Typography color="error" variant="body2"  >{editedConfirmPasswordError}</Typography>}
                  </FormControl>
                </Grid>



                <Grid item xs={12} sx={{ height: "100%", textAlign: 'right' }}>
                  <Button color="error" variant="contained" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="contained" sx={{ marginLeft: 1 }} onClick={handleUpdated}>
                    Save
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={4} marginLeft={1}>
                {showMessage && (<Typography color='#3f51b5'>Details Updated Successfully!!</Typography>)}
              </Grid>
            </Grid>



          </DialogContent>
        </Dialog>



        <Dialog
          open={openMessage}
          onClose={handleClose}>
          <DialogContent>
            <Stack
              //  direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ maxWidth: 350, textAlign: "center" }}
            >
              <Iconify
                icon={"eva:alert-triangle-outline"}
                sx={{ fontSize: 92, color: "#ffb2a3" }}
              />
              <Typography sx={{ color: "gray" }} variant="h5">
                {" "}
                Are you sure you want to {rowData.Status === 1 ? "Disable" : "Enable"}  the user :  {rowData.DisplayName}?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
            >
              No
            </Button>
            <Button variant="contained" onClick={disableUser}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

      </CardContent>
    </Card>

  );
};

export default ClientConsolidationLogin;