import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components

import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import { AgentReportCard } from 'src/sections/agentreports';
import useAgentLatencyReport from 'src/api/Agentwise/useAgentLatencyReport';
// ----------------------------------------------------------------------
const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function AgentwiseLatency() {
  const [reportData] = useAgentLatencyReport()
  return (
    <Page title="Dashboard: Blog | Minimal-UI">
      <Container maxWidth="xxl">
        <Grid container spacing={3}>
            <AgentReportCard reportData={reportData}/>
        </Grid>
      </Container>
    </Page>
  );
}
