import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
  Box,
  Stack,Button,
  Drawer,
  InputLabel,Select,MenuItem,FormControl,FormControlLabel,Checkbox,
  Slider,
  Card,
  Alert,
  DialogActions,
  DialogContent,
  Grid,
  createFilterOptions
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';
import { useEffect, useState,useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { postArchiveTimings,postMonitoringTimings,getMonitoringTimings,getArchiveTimings } from 'src/api/analytics';
import Modal from '@mui/material/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getUpdateCategory } from 'src/api/devices'
import axios from 'src/axios';
import MonTimings from 'src/pages/MonTimings';
import ArchiveTimings from 'src/pages/ArchiveTimings';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { filter } from 'lodash';

const iconClient = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIconClient = <CheckBoxIcon fontSize="small" />;
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginLeft: 15,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

DeviceListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

function valuetext(value) {
  return `${value} Hr`;
}

const marks = [
  {
    value: 0,
    label: '0 Hr',
  },
  {
    value: 23,
    label: '23 Hr',
  }
];


export default function DeviceListToolbar({ handleReloadPage, selectedClient, onClientChange,totalData,numSelected, filterName, onFilterName,reloadClientsData,clientInfo,getClientid,deviceInfo,totalInfo,handleDisplay,clientVal }) {

  // console.log('selectedClient',selectedClient)

 
  useEffect(()=>{
    getClientid({selectedClient})
    reloadClientsData()
  },[selectedClient])

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [state, setState] = useState({right: false});
  const [archiveState, setArchiveState] = useState({right: false});

  const [monTimings,setMonTimins]=useState([])
  const [archiveTimings,setArchiveTimins]=useState([])

  const [disableCameras,setDisableCameras]=useState([])

  const [value, setValue] = useState([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]]);
  const [monValue,setMonValue]=useState([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]])
  const [status, setStatusBase] = useState(false);
  const [message,setMessage]=useState('')
  const [groupDilog,setGroupDilog]=useState(false)

  const [groups,setGroups]=useState(null)

  const [openGrp,setopenGrp]=useState(false)

  const [openCategory,SetOpenCategory]=useState(false)
  const [category,setCategory]=useState('')

  const [openMonitoringTime,SetOpenMonitoringTime]=useState(false)
  const [openArchiveTimings,setOpenArchiveTimings]=useState(false)

  const [selectedCamera, setSelectedCamera] = useState('');
  const [fromHour, setFromHour] = useState('');
  const [toHour, setToHour] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [setMonFromHour,setFromTimeValue] = useState('');

  const [activeClients, setActiveClients] = useState([])
  const [activeClientsValue,setActiveClientsValue]=useState([])
  const [openClientAuto,setOpenClientAuto]=useState(false)
  const [checkClientValue,setCheckClientValue]=useState(false)

  const tabRef = useRef(false)


  const val = [
    {groupid:1,groupname:'test1'},
    {groupid:2,groupname:'test2'},
    {groupid:3,groupname:'test3'}
  ]

  const cat = [
    {catid:'1',catname:'Category 1'},
    {catid:'2',catname:'Category 2'},
    {catid:'3',catname:'Category 3'}
  ]

  
  // const toggleDrawer = (anchor, open) => (event) => {
  //  ////debugger
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //     setState({ ...state, [anchor]: open });
  //     if(open){
  //       if(deviceInfo.length>1){  // if more than 1 device is selected need to show default values
  //           setMonValue([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]])
  //           const refreshData = [
  //             { Dayno: 0, FromHour: 2, ToHour: 11 },
  //             { Dayno: 1, FromHour: 2, ToHour: 11 },
  //             { Dayno: 2, FromHour: 2, ToHour: 11 },
  //             { Dayno: 3, FromHour: 2, ToHour: 11 },
  //             { Dayno: 4, FromHour: 2, ToHour: 11 },
  //             { Dayno: 5, FromHour: 2, ToHour: 11 },
  //             { Dayno: 6, FromHour: 2, ToHour: 11 }
  //           ];
  //           setMonTimins(refreshData)
  //       }
  //       else{
  //             getMonitoringTimings(deviceInfo,(response)=>{
  //               let newData=response.data               
  //               if(newData.length>0){  // if single device is selected need to populate existing data to scroll bar
  //                 setMonTimins(newData)
  //                 let updateData=monValue.map((item,index)=>{
  //                   let matchedData=newData.find((i)=>i.Dayno===index)
  //                   if(matchedData){
  //                     return [matchedData.FromHour,matchedData.ToHour]
  //                   }
  //                   else{
  //                     return item
  //                   }
  //                 })
  //                 setMonValue(updateData)
  //               }
  //               else{  // if no previous data is existed default values need to be shown
  //                 setMonValue([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]])
  //                 const refreshData = [
  //                   { Dayno: 0, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 1, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 2, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 3, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 4, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 5, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 6, FromHour: 2, ToHour: 11 }
  //                 ];
  //                 setMonTimins(refreshData)
  //               }
  //             })
  //       }
  //     }
    
  // };

  // const toggleArchivesDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   let is_archive=totalData.filter(item=>(item.IsActive===0 ||item.IsActive===null)  && deviceInfo.includes(item.deviceid))

  //   if(is_archive.length>0){
  //     setOpen(true)
  //     setDisableCameras(is_archive)
  //     return
  //   }
  //   else{
      
  //     setArchiveState({ ...archiveState, [anchor]: open });
  //     if(open){
  //       if(deviceInfo.length>1){  // if more than 1 device is selected need to show default values
  //           setValue([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]])
  //           const refreshData = [
  //             { Dayno: 0, FromHour: 2, ToHour: 11 },
  //             { Dayno: 1, FromHour: 2, ToHour: 11 },
  //             { Dayno: 2, FromHour: 2, ToHour: 11 },
  //             { Dayno: 3, FromHour: 2, ToHour: 11 },
  //             { Dayno: 4, FromHour: 2, ToHour: 11 },
  //             { Dayno: 5, FromHour: 2, ToHour: 11 },
  //             { Dayno: 6, FromHour: 2, ToHour: 11 }
  //           ];
  //           setArchiveTimins(refreshData)
  //       }
  //       else{
  //             getArchiveTimings(deviceInfo,(response)=>{
  //             //  //debugger
  //               let newData=response.data               
  //               if(newData.length>0){  // if single device is selected need to populate existing data to scroll bar
  //                 setArchiveTimins(newData)
  //                 let updateData=value.map((item,index)=>{
  //                   let matchedData=newData.find((i)=>i.Dayno===index)
  //                   if(matchedData){
  //                     return [matchedData.FromHour,matchedData.ToHour]
  //                   }
  //                   else{
  //                     return item
  //                   }
  //                 })
  //                 setValue(updateData)
  //               }
  //               else{  // if no previous data is existed default values need to be shown
  //                 setValue([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]])
  //                 const refreshData = [
  //                   { Dayno: 0, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 1, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 2, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 3, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 4, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 5, FromHour: 2, ToHour: 11 },
  //                   { Dayno: 6, FromHour: 2, ToHour: 11 }
  //                 ];
  //                 setArchiveTimins(refreshData)
  //               }
  //             })
  //       }
  //     }
  //   }    
  // };



  //const [value, setValue] = useState([2, 11]);
  
  // const handleChange = (index) => (event, newValue) => {
  //   setValue((prevValue) => {
  //     const updatedValue = [...prevValue];
  //     updatedValue[index] = newValue;
  //     return updatedValue;
  //   });
  // };

  // const handleMonChange = (index) => (event, newValue) => {
  //   setMonValue((prevValue) => {
  //     const updatedMonValue = [...prevValue];
  //     updatedMonValue[index] = newValue;
  //     return updatedMonValue;
  //   });
  // };


  // const handleCategoryChange = ()  => {

  //   setCategory()
  // }

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
};

const jsonToFormData = (data) => {
  let formdata = new FormData()
  for (let x in data)
    formdata.append(x, data[x])
  return formdata
}


 const handleSaveCategory = () => {
  let result = ''
  // console.log('Selected Category:', category,deviceInfo);

  let categoryValue ;
  switch (category) {
    case 'Category 2':
      categoryValue = '2';
      break;
    case 'Category 1':
      categoryValue = '1';
      break;
    case 'Category 3':
      categoryValue = '3';
      break;
    default:
      categoryValue = '0';
  }
  // console.log('Selected Category:', categoryValue,deviceInfo);
  result = deviceInfo.join(',');
  
  // console.log(result);
  

  const data = {
    deviceid : result,
    Category : categoryValue,
  }

  // console.log('after modification :', data);

   getUpdateCategory((response) => {
    if (response.status === 200 || response.status === 201) {
      // setUpdate(false)
      // getInputsData()
      handleReloadPage()
    }
  }, data)

  SetOpenCategory(false)



 }

  // const handleArchiveTimings = () =>{
  //   const changedIndexes = compareObjects(archiveTimings, value);

  //   const formattedArchTimings = deviceInfo.map(deviceId => (
  //     changedIndexes.map(index => ({
  //       CameraId: deviceId,
  //       Dayno: index,
  //       FromHour: value[index][0],
  //       ToHour: value[index][1]
  //     }))
  //   )).flat();
    
  //   postArchiveTimings(formattedArchTimings,(response)=>{
  //    // //debugger
  //     if(response.data){
  //       setStatusBase(true)
  //       setMessage('Archive Timings Inserted Successfully!!')
  //       handleReloadPage()
  //       setTimeout(() => {
  //         console.log(status)
  //         setStatusBase(false); 
  //       }, 5000);
  //     }
  //   })
  // }

  const compareObjects = (obj1,obj2)=>{
    const changedIndexes = [];
    obj1.forEach((item, index) => {
        const { Dayno, FromHour, ToHour } = item;
        const [newFromHour, newToHour] = obj2[Dayno];
        if (FromHour !== newFromHour || ToHour !== newToHour) {
            changedIndexes.push(index);
        }
    });
    return changedIndexes;
  }

  const handleMonitoringTimings = () =>{
   // //debugger
    const changedIndexes = compareObjects(monTimings,monValue);

    const formattedMonTimings = deviceInfo.map(deviceId => (
      changedIndexes.map(index => ({
        CameraId: deviceId,
        Dayno: index,
        FromHour: monValue[index][0],
        ToHour: monValue[index][1]
      }))
    )).flat();
    
    postMonitoringTimings(formattedMonTimings,(response)=>{
      if(response.data){
        setStatusBase(true)
        setMessage('Monitoring Timings Inserted Successfully!!')
        // handleReloadPage()
        getClientid({selectedClient})
          setTimeout(() => {
          //console.log(status)
          setStatusBase(false); 
        }, 5000);
        }
    })
    
  }


  const groupDilogClose = () => {setGroupDilog(false)}
  const OpenGroups = ()=>{

        let uniqueClinetId = totalData
                              .filter(item=>deviceInfo.includes(item.deviceid))
                              .map(values=>values.clientid)
        uniqueClinetId = new Set(uniqueClinetId).size>1

        if(!uniqueClinetId){
          setGroupDilog(true)
        }
       else{
        setopenGrp(true)
       }
  }


  const handleGrpClose = () => {setopenGrp(false)}
  const handleCategory = () =>{
    SetOpenCategory(true)
  }

  const openCategoryClose = () =>{
    SetOpenCategory(false)
  }

  // Monitoring time------

  
  const handleMonitoringTimingsDailog = () =>{
    // console.log("deviceInfo",deviceInfo);

    SetOpenMonitoringTime(true)
  }

  const openMonitoringClose = () => {
    SetOpenMonitoringTime(false)
    
  }

  const handleArchiveTimings= () =>{
    console.log("Archive timings ",totalData);

    let is_archive=totalData.filter(item=>item.IsActive===1 && deviceInfo.includes(item.deviceid))

   //let is_archive=totalData.filter(item=>deviceInfo.includes(item.deviceid))
   //deviceInfo

   if(is_archive.length>0 && is_archive.length===deviceInfo.length){
      setOpenArchiveTimings(true)
   }
   else{
    ////debugger
    let cameras= deviceInfo.filter(deviceId => !is_archive.some(obj => obj.deviceid === deviceId));
    setDisableCameras(cameras)
    setOpen(true)    
   }

  }

  const closeArchiveTimings = ()=>{
    setOpenArchiveTimings(false)
  } 

  const handleDayChange = (day) => {
    if (day === 'All') {
      if (selectedDays.length === 7) {
        setSelectedDays([]);
      } else {
        setSelectedDays(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);
      }
    } else {
      if (selectedDays.includes(day)) {
        setSelectedDays(selectedDays.filter(item => item !== day));
      } else {
        setSelectedDays([...selectedDays, day]);
      }
    }
  };

  const handleClientChange = (event,value) =>{
    //debugger
    let actClients=[];

    handleDisplay()

    if (value.some(obj => obj.hasOwnProperty('selectall'))){
     // //debugger
      if(activeClients.length===clientInfo.length){
        setActiveClients([])
        setActiveClientsValue([])
        setCheckClientValue(false)

        //alert('unchecked all')
        //tabRef.current = true;
        //handleDisplay()
      }
      else{
        clientInfo.forEach(item=>{
          actClients.push(item.ID)
        })
        setActiveClients(actClients)
        setActiveClientsValue(clientInfo)
        setCheckClientValue(true)

        
      }
    }
    else{
      ////debugger
      let id=value[0].ID;
      if(activeClients.includes(id)){
        let filterArr = activeClients.filter(client => client !== id);
        let filterArrNew=activeClientsValue.filter(cid => cid.ID !== id);
        // console.log("filterArr",filterArr);

        setActiveClients(filterArr);
        if(filterArr.length === 0){
          //alert('unchecked all')
          // tabRef.current = true;
          //handleDisplay()
        }
        setActiveClientsValue(filterArrNew)
        if(checkClientValue){
          setCheckClientValue(false)
        }
      }
      else{
        setActiveClients((prv)=>[...prv,id])
        setActiveClientsValue((prv)=>[...prv,value[0]])
      }
    }
  }

  useEffect(()=>{
    ////debugger
    if(activeClients.length===clientInfo.length && activeClients.length>0){
      setCheckClientValue(true)
     // onClientChange([])
    }

    if(activeClientsValue.length>0){
      onClientChange(activeClientsValue)
    }
    else if(activeClients.length===0){
      onClientChange([])
      
      //tabRef.current = false;
    }
  },[activeClients])

  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeClients.includes(option.ID));
    const unselectedOptions = filtered.filter(option => !activeClients.includes(option.ID));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];

    return [{ title: 'Select All', selectall: true }, ...filtered];
  };

  if(activeClients.length>0 && selectedClient.length>0){
   // //debugger
    const list=selectedClient
    .filter(client => !activeClients.includes(client.ID))
    .map(client => client.ID);
    console.log(list)
  }

  useEffect(()=>{
    ////debugger
   // //debugger
    // if(tabRef.current){
    //   alert('load all')
    // }
    console.log(selectedClient)
    console.log(activeClients)
    if(selectedClient.length!==0){     
      onClientChange(selectedClient)
      let id = selectedClient.map(item=>item.ID);
      setActiveClients(id)
      setActiveClientsValue(selectedClient)
     }
  },[])
  

  return (
      <Stack
        direction="row"       
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        >
        <RootStyle>       
          <Box sx={{ minWidth: 240 }}>
           
            <Autocomplete
              multiple
              fullWidth
              id="client"
              options={clientInfo}
              value={[]}
              getOptionLabel={(option) => (option.selectall && clientInfo.length === activeClients.length) ? 'Select All' : `${option.displayname}`}
              onChange={handleClientChange}
              disableCloseOnSelect={openClientAuto}
              open={openClientAuto}
              onOpen={() => setOpenClientAuto(true)}
              onClose={() => setOpenClientAuto(false)}
              filterOptions={filterOptions}
              renderOption={(props, option, { selected }) => {
                const key = `camid-${option.ID}`;
                return (
                  <li {...props} key={key}>
                    {option.selectall ? (
                      <>
                        <Checkbox
                          icon={iconClient}
                          checkedIcon={checkedIconClient}
                          style={{ marginRight: 8 }}
                          checked={checkClientValue}
                          //onChange={() => setCheckClientValue(!checkClientValue)}
                        />
                        {option.title}
                      </>
                    ) : (
                      <>
                        <Checkbox
                          icon={iconClient}
                          checkedIcon={checkedIconClient}
                          style={{ marginRight: 8 }}
                          checked={activeClients.includes(option.ID) 
                           // || selectedClient.some(client => client.ID === option.ID)
                          }
                          onChange={() => {
                            const isChecked = activeClients.includes(option.ID);
                            const newActiveClients = isChecked
                              ? activeClients.filter((clientId) => clientId !== option.ID)
                              : [...activeClients, option.ID];
                            setActiveClients(newActiveClients);
                          }}
                        />
                        {`${option.displayname}`}
                      </>
                    )}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Client" onFocus={() => setOpenClientAuto(true)} />
              )}
            />

          </Box>
          
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />     
    
        </RootStyle>
        <Stack direction="row" spacing={1} sx={{ pr:2 }}>

        {/* archives Grid */}

        {/* <Drawer
            anchor={'right'}
            open={archiveState['right']}
            onClose={toggleArchivesDrawer('right', false)}
          >
            <Box
              sx={{ width: 300 }}
              role="presentation"
              onClick={toggleArchivesDrawer('right', false)}
              onKeyDown={toggleArchivesDrawer('right', false)}
            >
              
            </Box>
            <Box>
            
            <Typography variant="h6" sx={{px: 10,py:2}}>Archive Timings</Typography>

            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
              <Card key={index} sx={{ mx: 2, px: 1, mt: index !== 0 ? 2 : 0 }}>
                <Typography sx={{ py: 2 }}>{day}</Typography>
                <Box sx={{ px: 1 }}>
                  <Slider
                    getAriaLabel={() => `${day} temperature range`}
                    value={value[index]}
                    onChange={handleChange(index)}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    max={23}
                    marks={marks}
                  />
                </Box>
              </Card>
            ))}

          {(status) &&
          <Stack direction="row" sx={{ paddingTop: 2 }}>
            <Alert severity="success">{message}</Alert>
          </Stack> 
          }

            <Typography sx={{py: 2,px:8}} >
            <LoadingButton
              sx={{ mt: 1, ml: 'auto' }}
                type="submit"
                variant="contained"
                onClick={(e)=>
                { e.preventDefault()
                handleArchiveTimings()}}>
             Save Archives Timings
            </LoadingButton>
            </Typography>
            </Box>
        </Drawer> */}

         {/* monitoring Grid */}

        
        {/* <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            <Box
              sx={{ width: 300 }}
              role="presentation"
              onClick={toggleDrawer('right', false)}
              onKeyDown={toggleDrawer('right', false)}
            >
              
            </Box>
            <Box>
            
            <Typography variant="h6" sx={{px: 10,py:2}}>Monitoring Timings</Typography>

            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
              <Card key={index} sx={{ mx: 2, px: 1, mt: index !== 0 ? 2 : 0 }}>
                <Typography sx={{ py: 2 }}>{day}</Typography>
                <Box sx={{ px: 1 }}>
                  <Slider
                    getAriaLabel={() => `${day} temperature range`}
                    value={monValue[index]}
                    onChange={handleMonChange(index)}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    max={23}
                    marks={marks}
                  />
                </Box>
              </Card>
            ))}
          
          {(status) &&
          <Stack direction="row" sx={{ paddingTop: 2 }}>
            <Alert severity="success">{message}</Alert>
          </Stack> 
          }
          
            <Typography sx={{py: 2,px:8}} >
            <LoadingButton
              sx={{ mt: 1, ml: 'auto' }}
                type="submit"
                variant="contained"
                onClick={(e)=>{
                  e.preventDefault()
                  handleMonitoringTimings()
                }}>
             Save Monitoring Timings
            </LoadingButton>
            </Typography>
            </Box>
        </Drawer> */}



        {/* <Button variant='outlined' color='primary' disabled={numSelected ? false : true} onClick={toggleArchivesDrawer('right', true)}>Set Protocols</Button> */}
        <Button variant='outlined' color='primary' disabled={numSelected ? false : true} onClick={handleArchiveTimings}>Archives Timings</Button>
        {/* <Button size="small" variant='outlined' color='primary' disabled={numSelected ? false : true} onClick={toggleDrawer('right', true)}>Monitoring Timings</Button> */}
        <Button size="small" variant='outlined' color='primary' disabled={numSelected ? false : true} onClick={handleMonitoringTimingsDailog}>Monitoring Timings</Button>

        <Button size="small" variant='outlined' color='primary' disabled={numSelected ? false : true} onClick={handleCategory}>Category</Button>

        {/* <Button variant='contained' color='secondary' disabled={numSelected ? false : true} onClick={OpenGroups}>Set Group</Button> */}
        </Stack>

          {/* Add/Edit Groups close */}

         <Dialog onClose={handleGrpClose} open={openGrp}>
            <DialogTitle> 
              <Typography id="modal-modal-description" sx={{fontWeight:'bold'}}>
                    Groups change can be done only for the camera's of same client
              </Typography>
            </DialogTitle>
            <DialogActions>
                <Button color='error' variant='outlined' onClick={handleGrpClose}>Cancel</Button>
            </DialogActions> 
          </Dialog> 
          {/* `Enable Archives For Camera */}
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle> 
              <Typography id="modal-modal-description" sx={{fontWeight:'bold'}}>
                    {`Enable Archives For Camera : ${disableCameras}`}
              </Typography>
            </DialogTitle>
            <DialogActions>
                <Button color='error' variant='outlined' onClick={handleClose}>Cancel</Button>
            </DialogActions> 
          </Dialog> 
          
          {/* Add/Edit Groups */}
          <Dialog 
            fullWidth
            onClose={groupDilogClose} 
            open={groupDilog}>
                
                <DialogTitle> 
                  <Typography id="modal-modal-description" sx={{fontWeight:'bold'}}>
                       Add/Edit Groups
                  </Typography>
                </DialogTitle>
                
                
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{marginTop:1}}>
                      <Typography variant="subtitle2" component="div">
                        Groups{' '}
                      <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{ color: 'red', display: 'inline' }}
                      >
                          *
                      </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Autocomplete
                    value={groups}
                    onChange={(event, newValue) => {
                        setGroups(newValue);
                    }}
                    options={val}
                    getOptionLabel={(option) => option.groupname}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        placeholder="Select Groups"
                        variant="outlined"
                        />
                    )}
                    />
                    
                    </Grid>
                   
                  </Grid>
                </DialogContent>
                
                
                <DialogActions>
                    <Button color='error' variant='outlined' onClick={groupDilogClose}>Cancel</Button>
                    <LoadingButton
                    type="submit"
                    variant="contained"
                    >
                        Save Changes
                    </LoadingButton>
                </DialogActions> 
          
          </Dialog> 

          {/* Add/Edit Category */}

          <Dialog 
            fullWidth
            onClose={openCategoryClose} 
            open={openCategory}>
                
                <DialogTitle> 
                  <Typography id="modal-modal-description" sx={{fontWeight:'bold'}}>
                       Add/Edit Category
                  </Typography>
                </DialogTitle>
                <Grid item md={8}>
                </Grid>
                
                <DialogContent>
                  <Grid container spacing={2}>
           
                    <Grid item xs={6}>
                    <FormControl fullWidth>

                    <InputLabel id="category-label">Select Category</InputLabel>
                <Select
                    labelId="category-label"
                    id="category"
                    value={category}
                    onChange={handleCategoryChange}
                    label="Select Category"
                >
                    <MenuItem value="Not Assigned">Not Assigned</MenuItem>
                    {cat.map((option,index) => (
                        <MenuItem key={index} value={option.catname}>
                            {option.catname}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>

                    
                    </Grid>
                   
                  </Grid>
                </DialogContent>
                
                
                <DialogActions>
                    <Button color='error' variant='outlined' onClick={openCategoryClose}>Cancel</Button>
                    <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={handleSaveCategory}
                    >
                        Save
                    </LoadingButton>
                </DialogActions> 
          
          </Dialog> 

          {/* Add Monitoring Timings */}

        <Dialog fullWidth maxWidth="md" open={openMonitoringTime}>
          <DialogContent open={openMonitoringTime}>
            <MonTimings DeviceInfo={deviceInfo} ClientInfo={clientVal} close={openMonitoringClose}>

            </MonTimings>

            </DialogContent>
        </Dialog> 

        {/* Add Archive Timings */}
         <Dialog fullWidth maxWidth="md" open={openArchiveTimings}>
          <DialogContent open={openArchiveTimings}>
            <ArchiveTimings DeviceInfo={deviceInfo} close={closeArchiveTimings}>

            </ArchiveTimings>

            </DialogContent>
        </Dialog> 
       
      </Stack>

  );
}