// import PropTypes from 'prop-types';
// material
import {  styled } from '@mui/material/styles';
import { Card, Grid, Avatar, Typography, CardContent, Box } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import ReportTable from './ReportTable';

// ----------------------------------------------------------------------
const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});
const TitleStyle = styled(Typography)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});
const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 80,
  height: 80,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));
// const InfoStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'flex-end',
//   marginTop: theme.spacing(3),
//   color: theme.palette.text.disabled
// }));
const CoverImgStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

export default function AgentReportCard({ reportData}) {  

  const { data, username, avgTime, eventsCount } = reportData
  // console.log("agent Report true",reportData)

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card >
        <Box sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{          
            pt: {
              xs: 'calc(100% * 4 / 3)',
              sm: 'calc(100% * 3 / 40)'
            }           
          }}
        >        
          <AvatarStyle
            sx={{ bgcolor: lightGreen[700] }}
            alt={username}
            src=''         
          >
            {username ? username.slice(0, 2).toUpperCase(): ''}
          </AvatarStyle>         
          <CoverImgStyle sx={{ background: (theme) => theme.palette.gradients.primary }} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pl: "124px",           
            bottom: 0,
            left: 0,
            width: '100%',
            position: 'absolute'
          }}

          style={{ paddingBottom:0 }}
        >
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ color: '#92daff', display: 'block', m: 0 }}
              >
                Avg Review Time: {avgTime}
              </Typography>
              <TitleStyle
                color="white"
                variant="subtitle2"
                sx={{
                  typography: 'h5',
                  textTransform: 'capitalize'
                }}
              >
                {username}
              </TitleStyle>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography
                sx={{ color: 'white', display: 'block', typography: 'h3', lineHeight: 0.8 }}
              >
                {eventsCount}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ color: '#92daff', display: 'block' }}
              >
                Audits
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        </Box>
        
        <CardContent sx={{ p:0 }}>
              <ReportTable reportData={data}/>
        </CardContent>
      </Card>
    </Grid>
  );
}
