import { getURL, jsonToParam ,jsonToFormData} from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import axios from 'src/axios'

// get cameras data
const getCamesByCat = (body, callback) => {
    axios({
        method: 'get',
        url: `${getURL('/camera-reports/active-cameras-by-category')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getAllCameras = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/camera-reports/active-cameras')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch(error => {
        console.log(error);     
    });
}

const getActiveAgentCount = (body,callback) => {
    axios({
        method: 'get',
        url: getURL(`agent-reports/active-agents?${jsonToParam(body)}`),

        // url: getURL(`agent-reports/active-agents?date=${currentDate}`),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


export { getAllCameras , getCamesByCat ,getActiveAgentCount }