// import { useState } from 'react'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

const FilterBox = ({ activeTab, filterForms, handleFilterBox, getReport }) => {
  // const [latanceyVal,setlatancy()]

  // const [value, setValue] = useState(dayjs('2014-08-18T21:11:54'));
  // const [reportType, setReportType] = useState('Hourly');
  // const handleChange = (newValue) => {
  //     setValue(newValue);
  // };

  // const handleChangeType = (event) => {
  //     setReportType(event.target.value)
  // }

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 2, py: 3 }}>
      {activeTab.name !== "Search Event" && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Report Date"
            format="YYYY-MM-DD"
            value={filterForms.reportDate}
            onChange={(e) => handleFilterBox(e, null, "reportDate")}
            name="reportDate"
            renderInput={(params) => <TextField {...params} />}
            maxDate={dayjs()}
          />
        </LocalizationProvider>
      )}
      {(activeTab.name === "Client Wise" ||
        activeTab.name === "Camera Wise") && (
        <TextField
          value={filterForms.latency}
          onChange={handleFilterBox}
          name="latency"
          label="Enter Latency (in Minutes)"
          variant="outlined"
        />
      )}
      {activeTab.name === "Agent wise" && (
        <>
          {(filterForms.noOfAuditsTypes === "Hourly" ||
            filterForms.noOfAuditsTypes === "Both") && (
            <TextField
              name="noOfAudits"
              label="No of Audits (Hourly)"
              variant="outlined"
              value={filterForms.noOfAudits}
              onChange={handleFilterBox}
            />
          )}
          {(filterForms.noOfAuditsTypes === "FullDay" ||
            filterForms.noOfAuditsTypes === "Both") && (
            <TextField
              name="dayNoOfAudity"
              label="No of Audits (Full Day Shift)"
              variant="outlined"
              value={filterForms.dayNoOfAudity}
              onChange={handleFilterBox}
            />
          )}

          <FormControl sx={{ minWidth: 50 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterForms.noOfAuditsTypes}
              label="Type"
              name="noOfAuditsTypes"
              onChange={handleFilterBox}
            >
              <MenuItem value={"Hourly"}>Hourly</MenuItem>
              <MenuItem value={"FullDay"}>Full Day Shift</MenuItem>
              <MenuItem value={"Both"}>Both</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      {activeTab.name === "Search Event" && (
        <TextField
          name="eventNo"
          value={filterForms.eventNo}
          onChange={handleFilterBox}
          label="Event No"
          variant="outlined"
        />
      )}

      <FormControl>
        <Button onClick={getReport} sx={{ px: 3 }}>
          Search <SearchIcon />
        </Button>
      </FormControl>
    </Stack>
  );
};

export default FilterBox;
