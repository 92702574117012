import React, { useState } from "react";
import { useEffect } from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Grid,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import Page from "src/components/Page";
import {
  getAllCameras,
  getCamesByCat,
  getActiveAgentCount,
} from "src/api/cardCountDetails";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

export default function CardCountDetails({ close, dialogContent }) {
  const [allCamDetails, setAllCamsDetails] = useState([]);
  const [catData, setCatData] = useState([]);
  const [agentData, setAgentdate] = useState([]);
  const [dailogHeadder, setDailogHeadder] = useState("");

  const [currentDate, setCurrentDate] = useState(dayjs().startOf("day"));
  function formatDate(dateObj) {
    const date = dateObj.$d;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const formattedDate = formatDate(currentDate);

  useEffect(() => {
    //   console.log("dialogContent",dialogContent)
    if (dialogContent.title === "Camera Active (In Process)") {
      setDailogHeadder(dialogContent.title);
      getAllCameras((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            setAllCamsDetails(response.data);
          }
        }
      });
    } else {
      if (
        dialogContent.title === "CATEGORY 1" ||
        dialogContent.title === "CATEGORY 2" ||
        dialogContent.title === "CATEGORY 3" ||
        dialogContent.title === "NOT ASSIGNED"
      ) {
        let catValue = 0;
        if (dialogContent.title === "CATEGORY 1") {
          catValue = 1;
        }
        if (dialogContent.title === "CATEGORY 2") {
          catValue = 2;
        }
        if (dialogContent.title === "CATEGORY 3") {
          catValue = 3;
        }
        const body = {
          category: catValue,
        };
        setDailogHeadder(dialogContent.title);

        getCamesByCat(body, (response) => {
          if (response.status === 200) {
            if (response.data != null) {
              setCatData(response.data);
            }
          }
        });
      } else {
        if (dialogContent.title === "ACTIVE AGENTS") {
          setDailogHeadder(dialogContent.title);
          const data = {
            date: formattedDate,
          };
          getActiveAgentCount(data, (response) => {
            if (response.status === 200) {
              if (response.data != null) {
                // console.log("all cameras",response.data)
                setAgentdate(response.data);
              }
            }
          });
        }
      }
    }
  }, []);

  //   const handleClose = () => {
  //     onClose()
  //   }
  const handleClose = () => {
    close();
  };

  return (
    <Page>
      <Container
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          paddingBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <p style={{ color: "black", fontSize: "25px", fontWeight: "bold" }}>
              {dailogHeadder}
            </p>
          </Grid>
          <Grid item md={6}>
            <p
              style={{
                textAlign: "right",
                color: "white",
                fontSize: "25px",
                paddingBlockEnd: "10px",
              }}
            >
              <Button
                onClick={handleClose}
                style={{
                  textAlign: "right",
                  color: "black",
                  fontSize: "25px",
                  paddingBlockEnd: "10px",
                }}
              >
                <CloseIcon />
              </Button>
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container style={{ height: '60vh', overflowY: 'auto', marginBottom: '64px' }}>
                <Grid>
                    {allCamDetails.length > 0 ? (
                        <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Client Name</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Camera Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allCamDetails.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.ClientName}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.CameraName}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        (catData.length !== 0) ? (
                            <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Client Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Camera Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Category</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {catData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{row.ClientName}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{row.CameraName}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>Category {row.Category}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            (agentData.count > 0) ? (
                                <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>Agent Name</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>LogIn Time</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {agentData.details.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>{row.displayname}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>{row.RecentCreatedOn}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                (dailogHeadder === 'ACTIVE AGENTS') ? (
                                    <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>Agent Name</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>LogIn Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">
                                                        <Typography variant="h6" color="textSecondary">No Data</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    (dailogHeadder === 'Camera Active (In Process)') ? (
                                        <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>Client Name</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>Camera Name</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={9} align="center">
                                                            <Typography variant="h6" color="textSecondary">No Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <TableContainer style={{ height: '100%', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: 'center' }}>S.No</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>Client Name</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>Camera Name</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>Category</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={9} align="center">
                                                            <Typography variant="h6" color="textSecondary">No Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                )
                            )
                        )
                    )}
                </Grid>
            </Container> */}

      {/* <Container style={{ maxHeight: '60vh', overflowY: 'auto', marginBottom: '64px' }}> */}
      <TableContainer component={Paper} style={{ maxHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>S.No</TableCell>
              <TableCell style={{ textAlign: "center" }}>Client Name</TableCell>
              <TableCell style={{ textAlign: "center" }}>Camera Name</TableCell>
              {catData.length !== 0 && (
                <TableCell style={{ textAlign: "center" }}>Category</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allCamDetails.length > 0 ? (
              allCamDetails.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.ClientName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.CameraName}
                  </TableCell>
                </TableRow>
              ))
            ) : catData.length !== 0 ? (
              catData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.ClientName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.CameraName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Category {row.Category}
                  </TableCell>
                </TableRow>
              ))
            ) : agentData.count > 0 ? (
              agentData.details.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.displayname}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.RecentCreatedOn}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography variant="h6" color="textSecondary">
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Container> */}

      <Container
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 1000,
          backgroundColor: "white",
        }}
      >
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
      </Container>
    </Page>
  );
}
