import { useState, useEffect } from 'react';
import axios from '../axios'
import { format } from 'date-fns';
import { handleResponse } from 'src/utils/responseHandler';
import { getURL } from 'src/utils/config';
import { ceil } from 'lodash';


const useDashboard = () => {

    const baseURL = '/dashboard'
    const RUN_FREQ_TIME = 60 //Sec
    const ANALYTICS_ICONS = {
        'person': 'mdi:biathlon',
        'vehicle': 'mdi:bus',
        'fire': 'mdi:fire',
        'smoke': "material-symbols:detector-smoke",
        'accident': 'maki:road-accident',
        'clapping': 'emojione-v1:clapping-hands',
        'cycling': "game-icons:cycling",
        'drinking': 'cil:drink',
        'sleepatwork': 'iconoir:sleeper-chair',
        'eating': 'fluent-mdl2:eat-drink',
        'listening_to_music': 'arcticons:google-music',
        'running': 'cil:running',
        'sitting': 'game-icons:sitting-dog',
        'texting': 'bi:chat-text',
        'using_laptop': 'emojione:laptop-computer',
        'animal': 'game-icons:elephant',
        'cops': 'fluent-emoji:man-police-officer',
        'delivery': 'emojione:delivery-truck',
        'spillage': 'openmoji:oil-spill',
        'firesmoke': 'emojione:fire',
        'social_distance': 'ic:sharp-social-distance',
        'sefty_jocket': 'game-icons:life-jacket',
        'helmet ': 'emojione:rescue-workers-helmet',
        'gloves': 'noto:gloves',
        'dark': 'arcticons:dark',
        'person': 'el:person',
        'vehicle': 'fluent:vehicle-cab-16-regular',
        'mob': 'fluent:people-audience-20-regular',
        'face_recognition': 'emojione:anguished-face',
        'attendence': 'charm:person',
        'vehicle_speed': 'fluent:top-speed-24-regular',
        'license_plate_recognition': 'bx:car',
        'smoking': 'cil:smoking-room'
    }

    const [alldata, seData] = useState({
        isGetData: true,
        response: [],
        data: []
    })

    const [graph, setGraph] = useState({        
        isGetData: true,
        data: {},
        total: 0
    })

    const jsonToParam = (data) => {
        return Object.keys(data).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')
    }


    useEffect(() => {      
        



        getAnalyticsCount()    
        // getAuditData() 
        getAnalyticsCountByDate()   
        const intervalId = setInterval(() => {
            isReloadData(true)
        }, 1000*RUN_FREQ_TIME)
        return () => clearInterval(intervalId)

    }, [alldata.isGetData])   


    const getAnalyticsCount = (fromdate=null, todate=null) => {
     
        const clientId =  localStorage.getItem('client_id');
                
            if (clientId === '1118') {
                // Get current date and time
                let currentDay = new Date();
                let currentHour = currentDay.getHours();
                let currentMinute = currentDay.getMinutes();
                
                // Check if the current time is before 5:00 AM
                if (currentHour < 5 || (currentHour === 5 && currentMinute === 0)) {
                    // If it's before 5 AM, set 'fromdate' to the previous day at 05:00:00 AM
                    let previousDay = new Date();
                    previousDay.setDate(previousDay.getDate() - 1);  // Move back one day
                    fromdate = format(previousDay, 'yyyy-MM-dd') + ' 05:00:00';
                    
                    // Set 'todate' to the current day at 05:00:00 AM
                    todate = format(currentDay, 'yyyy-MM-dd') + ' 05:00:00';
                } else {
                    // If it's after 5 AM, set 'fromdate' to the current day at 05:00:00 AM
                    fromdate = format(currentDay, 'yyyy-MM-dd') + ' 05:00:00';
                    
                    // Set 'todate' to the next day at 05:00:00 AM
                    let nextDay = new Date();
                    nextDay.setDate(nextDay.getDate() + 1);  // Move forward one day
                    todate = format(nextDay, 'yyyy-MM-dd') + ' 05:00:00';
                }
            }
            
                       
            else{
                fromdate = format(new Date(), 'yyyy-MM-dd') + ' 00:00:00';  // Set hours, minutes, and seconds to 00:00:00
                todate = format(new Date(), 'yyyy-MM-dd') + ' 23:59:59';    // Set hours, minutes, and seconds to 23:59:59
                            
            } 
            
        let bodyContent = jsonToParam({
            "fromdate":fromdate,
            "todate": todate
        });

        // let bodyContent = jsonToParam({
        //     "fromdate": "2024-10-08 05:00:00",
        //     "todate": "2024-10-09 05:00:00"
        // });

        console.log("=======body=====",bodyContent);

        let url = `${baseURL}/audits?${bodyContent}`
        if (alldata.isGetData) {
            axios({
                method: 'get',
                url: getURL(url),
                validateStatus: (status) => handleResponse(status)
            }).then((response) => {
                // console.log(response.data)
                if (response.status === 200 || response.status === 201) {
                    seData({
                        ...alldata,
                        response: response.data,
                        data: response.data,
                        isGetData: false                        
                    })
                }
                // getAnalyticsCountByDate()
            })
        }
    }






    const getAnalyticsCountByDate = (fromdate=null, todate=null) => {
        const clientId =  localStorage.getItem('client_id');

     
        if (!fromdate || !todate) {

            
            if (clientId === '1118') {
                // Get current date and time
                let currentDay = new Date();
                let currentHour = currentDay.getHours();
                let currentMinute = currentDay.getMinutes();
                
                // Check if the current time is before 5:00 AM
                if (currentHour < 5 || (currentHour === 5 && currentMinute === 0)) {
                    // If it's before 5 AM, set 'fromdate' to the previous day at 05:00:00 AM
                    let previousDay = new Date();
                    previousDay.setDate(previousDay.getDate() - 1);  // Move back one day
                    fromdate = format(previousDay, 'yyyy-MM-dd') + ' 05:00:00';
                    
                    // Set 'todate' to the current day at 05:00:00 AM
                    todate = format(currentDay, 'yyyy-MM-dd') + ' 05:00:00';
                } else {
                    // If it's after 5 AM, set 'fromdate' to the current day at 05:00:00 AM
                    fromdate = format(currentDay, 'yyyy-MM-dd') + ' 05:00:00';
                    
                    // Set 'todate' to the next day at 05:00:00 AM
                    let nextDay = new Date();
                    nextDay.setDate(nextDay.getDate() + 1);  // Move forward one day
                    todate = format(nextDay, 'yyyy-MM-dd') + ' 05:00:00';
                }
            }
            
                       
            else{
                fromdate = format(new Date(), 'yyyy-MM-dd') + ' 00:00:00';  // Set hours, minutes, and seconds to 00:00:00
                todate = format(new Date(), 'yyyy-MM-dd') + ' 23:59:59';    // Set hours, minutes, and seconds to 23:59:59
                            
            }

        }    
            
        let bodyContent = jsonToParam({
            "fromdate": fromdate,
            "todate": todate
        });

                    
        // let bodyContent = jsonToParam({
        //     "fromdate": "2024-10-08 05:00:00",
        //     "todate": "2024-10-09 05:00:00"
        // });


        let url = `${baseURL}/audits-by-date?${bodyContent}`

        if (graph.isGetData) {
            axios({
                method: 'get',
                url: getURL(url),
                validateStatus: (status) => handleResponse(status)
            }).then((response) => {
                if (response.status === 200 || response.status === 201) {
                //    console.log("==============================",manageDateWiseData(response.data))

                    const [ total, data ] = manageDateWiseData(response.data)
                    setGraph({
                        ...graph,
                        data: data,
                        isGetData: false,
                        total: total                     
                    })
                }
            })
        }
        
    }

    const jsonToDict = (res) => {
        let data = {}
        for (let a of res)
            data[parseInt(a.hour)] = a.count
        return data
    }

    const manageDateWiseData = (res) => {
        let total = 0
        let data = jsonToDict(res)
        const today = {}
        let last = 0
        let day = ''
        const clientID = localStorage.getItem('client_id')
        if (clientID === '1118') {
            // Initialize variables for the first day start and total
            let total = 0;
            let last = 5; // Starting from 5 AM
            let today = {};
        
            // Loop through the day intervals from 5 AM to midnight (12 AM)
            for (let n = 6; n <= 24; n++) { // From 5 AM to 12 AM (midnight)
                let day = `${last}-${n}`; // Represent the day interval as a string (e.g., "5-6")
                today[day] = 0; // Initialize the total for this interval
        
                // Sum the data points from 'last' to 'n'
                for (let x = last; x < n; x++) {
                    today[day] += x in data ? data[x] : 0; // Accumulate data
                }
        
                // Add the total for this period to the overall total
                total += parseInt(today[day]);
        
                // Update the start time (last) for the next iteration
                last = n;
            }
        
            // For the remaining hours from midnight to 5 AM the next day
            for (let n = 0; n < 5; n++) { // Loop through from 12 AM to 5 AM (next day)
                let nextTime = (last + 1) % 24;  // Calculate the time after 1 hour
                let day = `${last}-${nextTime}`; // Represent the interval (e.g., "24-1", "1-2")
                today[day] = 0; // Initialize the total for this interval
        
                // Sum the data points for this 1-hour period
                for (let x = last; x < nextTime; x++) {
                    today[day] += x in data ? data[x] : 0; // Accumulate data
                }
        
                // Add the total for this period to the overall total
                total += parseInt(today[day]);
        
                // Update the start time (last) for the next 1-hour interval
                last = nextTime;
            }
        
            // Return the total and the daily data
            return [total, today];
        }
        
        
        
        
        else{
            for (let n=3; n<=24; n+=3) {
                day = `${last}-${n}`
                today[day] = 0
                for (let x=last; x<n; x++)
                    today[day] += x in data ? data[x]: 0
                    total += parseInt(today[day])
                last = n
            }
            return [ total, today ]
        }

    }

    const isReloadData = (status) => {
        seData({ ...alldata, isGetData: status })
        setGraph({ ...graph, isGetData: status })
    }

    const getIcon = (analytic) =>  analytic ? (analytic.toLowerCase() in ANALYTICS_ICONS ? ANALYTICS_ICONS[analytic.toLowerCase()]: 'ant-design:dot-chart-outlined') : 'ant-design:dot-chart-outlined'
    
    return {
        alldata,
        graph,
        getIcon,
        isReloadData
    }
}

export default useDashboard