import { useState, useEffect } from "react"
import { handleResponse } from "src/utils/responseHandler"
import axios from '../axios'
import { getURL } from 'src/utils/config';
import { format } from "date-fns";
import dayjs from "dayjs";
import { getTimezoneList } from "./analytics";


const useAuditList = (userType) => {
    const IsAgentBased = localStorage.getItem('IsAgentBased');
    // console.log('userType', userType)

    const RUN_FREQ_TIME = 30 //sec

    const BUZZER_CAMS = [6693, 6694, 6695, 6696, 6697]
    const buzzer = new Audio('/sounds/buzzer-or-wrong-answer.mp3')
    const noHelmet = new Audio('/sounds/no_safety_helmet_detected.mp3')
    const noShoes = new Audio('/sounds/no_safety_shoes_detected.mp3')
    const noCrashHelmet = new Audio('/sounds/no_crash_helmet_violation.mp3')

    buzzer.load()

    

    const [alldata, dataSetup] = useState({
        isGetData: true,
        isLoad: true,
        customFilterData: [],
        data: [],
        filterData: [],
        lastId: null,
        firstId: null,
        reload: true,
        isReloadForNewData: true,
    })

    const [search, setSearch] = useState('')
    const [isEscalating, setEscalat] = useState(false)
    const [open, setOpen] = useState(false)
    const [cameras, setCameras] = useState([])
    const [analytics, setAnalytics] = useState([])
    // const [fromDate, setFromDate] = useState( IsAgentBased === '0' ? dayjs().startOf('day') :dayjs().startOf('month').hour(0).minute(0))
    // const [toDate, setToDate] = useState(dayjs().endOf('day')) 

    const [fromDate, setFromDate] = useState(() => {
        const now = dayjs();
        const currentHour = now.hour();
        const currentMinutes = now.minute();
        const client_id = localStorage.getItem('client_id');
    
        // Check if it's before 5 AM
        const isBefore5AM = currentHour < 5 || (currentHour === 5 && currentMinutes === 0);
    
        if (IsAgentBased === '0') {
          if(client_id === '1118'){

            if (isBefore5AM  ) {
                // Before 5 AM: Set from the previous day at 5 AM to today at 5 AM
                return dayjs().subtract(1, 'day').hour(5).minute(0).second(0); // Previous day 5 AM
            } else {
                // After 5 AM: Set from today at 5 AM to tomorrow at 5 AM
                return dayjs().hour(5).minute(0).second(0); // Today 5 AM
            }
          }
          else{

            return  dayjs().startOf('day')
          }


        } else {
            // If IsAgentBased is not '0', default to start of the month at midnight
            return dayjs().startOf('month').hour(0).minute(0);
        }
    });
    
    const [toDate, setToDate] = useState(() => {
        const now = dayjs();
        const currentHour = now.hour();
        const currentMinutes = now.minute();
        const client_id = localStorage.getItem('client_id');

        // Check if it's before 5 AM
        const isBefore5AM = currentHour < 5 || (currentHour === 5 && currentMinutes === 0);
    
        if (IsAgentBased === '0') {
            if(client_id === '1118'){

                if(isBefore5AM){

                    return dayjs().hour(5).minute(0).second(0); // Today 5 AM
                } else {
                        // After 5 AM: Set to tomorrow at 5 AM
                    return dayjs().add(1, 'day').hour(5).minute(0).second(0); // Tomorrow 5 AM
    
                }
                    // Before 5 AM: Set to today at 5 AM
        
            }
            else{
                return dayjs().endOf('day')
            }
        }
        else{
           return dayjs().endOf('day')
        }


    });
    

    const [detectedType, setDetectedType] = useState([])
    const [device, setDevice] = useState([])
    const [alertStatus, setAlertStatus] = useState({ open: false, title: "", description: ""})
    const [initialToTime,setInitialToTime]=useState(true)
    const [resData,setResData]=useState([])
    // const [isGet,setIsGet] = useState(false)

    const [calculatedDay, setCalculatedDay] = useState(null); 
    // const [previousEventCount,setpreviousEventCount] = useState(0);
    

    const [previousEventCount, setPreviousEventCount] = useState(0);
    const [isGet, setIsGet] = useState(false);
    const [hasFetchedInitially, setHasFetchedInitially] = useState(false);
    const [buzzerCount,setBuzzerCount] = useState(0);
    
    useEffect(() => {
      ////debugger
      const timezoneId = localStorage.getItem('timezone_id')
      const fetchData = async () => {
        const response = await new Promise((resolve, reject) => {
          getTimezoneList((response) => {
           
              if (response.status === 200) {
                  resolve(response);
              } else {
                  reject(new Error('Failed to fetch timezone list'));
              }
          });
        });
    
        let data = response.data;
        let timeOffset = data.filter(item=>item.Id ===parseInt(timezoneId,10))[0].Time;
        // let timeOffset = '-22:30'
    
        const now = new Date();
        const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
        const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
        ////debugger
        const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
        const adjustedDay = adjustedTime.getDate()
        const adjustedMonth = (adjustedTime.getMonth())+1; 
        const adjustedYear = adjustedTime.getFullYear();
        //console.log(adjustedDay,adjustedMonth,adjustedYear)
        let defaultFromDate;
        const clientId = localStorage.getItem('client_id');
        if (clientId === '1118') {
            // Increase the date by 1 to get tomorrow's date
            defaultFromDate = dayjs(new Date(adjustedYear, adjustedMonth - 1, adjustedDay + 1));
          } else {
            // Otherwise, use today's adjusted date
            defaultFromDate = dayjs(new Date(adjustedYear, adjustedMonth - 1, adjustedDay));
          }        //setFromDate(defaultFromDate);
        console.log(adjustedTime)
        setCalculatedDay(defaultFromDate);
    
      }
    
      fetchData()
    },[]);


    const playSound = audio => {
      audio.play().catch(err => {
        console.error("Audio play error:", err);
      });
    };
    
    const playBuzzer = () => buzzer.play().catch((err) => handleNotificationBar(true, "Enable Sound Interaction with User Consent", "To enhance your web experience with sound effects, we kindly ask for your permission to play a buzzer. Simply click the 'OK' button"))

    const playBuzzer1 = () => noHelmet.play().catch((err) => handleNotificationBar(true, "Enable Sound Interaction with User Consent", "To enhance your web experience with sound effects, we kindly ask for your permission to play a buzzer. Simply click the 'OK' button"))

    const playBuzzer2 = () => noShoes.play().catch((err) => handleNotificationBar(true, "Enable Sound Interaction with User Consent", "To enhance your web experience with sound effects, we kindly ask for your permission to play a buzzer. Simply click the 'OK' button"))

    const playBuzzer3 = () => noCrashHelmet.play().catch((err) => handleNotificationBar(true, "Enable Sound Interaction with User Consent", "To enhance your web experience with sound effects, we kindly ask for your permission to play a buzzer. Simply click the 'OK' button"))

    const handleNotificationBar = (isOpen=false, title="", description= "") => {

        if (!isOpen){
          buzzer.play()
          
        }

            // buzzer.play()
        setAlertStatus({...alertStatus, open: isOpen, title: title, description: description})
    }

    const handleFromDate = (value) => {
        setFromDate(value)
    }
   

      const handleToDate = (value) => {
        setToDate(value);
      };


    const handleDetectedType = (_, value) => {
        setDetectedType(value)
    }

    const handleDevice = (_, value) => {
        ////debugger
        setDevice(value)
    }


    const clearAll = () => { 
        dataSetup({
            ...alldata,
            isLoad: false,
            isGetData: false,
            customFilterData: [...alldata.data],
            filterData: filterData(search, alldata.data),
            reload: false
        })
    }

    const handleDelete = (filterType, value = null) => {
        if (filterType === 'fromDate') {
            setFromDate(null)
            if (!(toDate && detectedType && device))
                clearAll()
        } else if (filterType === 'toDate') {
            setToDate(null)
            if (!(fromDate && detectedType && device))
                clearAll()
        } else if (filterType === 'detectedType') {
            setDetectedType([...detectedType.filter((_, index) => index !== value)])
            if (!(fromDate && toDate && device))
                clearAll()
        } else if (filterType === 'camera') {
            setDevice([...device.filter((_, index) => index !== value)])
            if (!(fromDate && toDate && detectedType))
                clearAll()
        } else if (filterType === 'clear') {
            setFromDate(null)
            setToDate(null)
            setDetectedType([])
            setDevice([])
            clearAll()
        }
    }

    const handlerGetFilterData = () => {
        console.log("fromdate========",fromDate,toDate)

        ////debugger
        // console.log("Filter Data for Escalated Video", fromDate , toDate ,detectedType)
        setBuzzerCount(1)

        setIsGet(true)

        if (fromDate || toDate || detectedType.length > 0 || device.length > 0){
            dataSetup({ ...alldata, isReloadForNewData: false, isGetData: true, isLoad: true })
        }
        else if(fromDate || toDate  || device.length > 0){
            dataSetup({ ...alldata, isReloadForNewData: false, isGetData: true, isLoad: true })
      
        }
    }

    const jsonToParam = (data) => Object.keys(data).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&')

    // useEffect(() => {
    //     if (open) {
    //         // buzzer.autoplay = true
    //         // buzzer.play()
    //     }
    // }, [open])


    const getCameras = () => {
        axios({
            method: 'get',
            url: getURL('devices?status=1'),
            validateStatus: (status) => handleResponse(status),
        }).then(function (response) {
            if (response.status === 200) {
                if(response.data !== null){
                    setCameras(response.data)
                }
            }
        })
    }

    const getAnalytics = () => {
        axios({
            method: 'get',
            url: getURL('analytics-sub/client-wise-analytics'),
            validateStatus: (status) => handleResponse(status),
        }).then(function (response) {
            if (response.status === 200) {
                setAnalytics([...response.data])
            }
        })
    }


    // const getFilters = () => {

    //     ////debugger
    //     const timezoneId = localStorage.getItem('timezone_id')

    //     const fromDatefor = fromDate;
    //     const toDatefor = toDate;

    //     getTimezoneList((response)=>{
    //         if(response.status===200){
    //           let data = response.data;
    //           console.log(data)
    //           ////debugger
    //           let timeOffset = data.filter(item=>item.Id ===parseInt(timezoneId,10))[0].Time;
              
    //           //timeOffset='-16:30';
        
    //           const now = new Date();
    //           //console.log(now)
        
    //           const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
    //           const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
        
    //           const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
        
    //           ////debugger
    //           console.log(now,adjustedTime)
    //           console.log(adjustedTime.getTime())
    //           console.log(now.getTime())
        
    //           if(now.getDate() !== adjustedTime.getDate()){
    //             const adjustedDay = adjustedTime.getDate();
    //             const adjustedMonth = adjustedTime.getMonth(); 
    //             const adjustedYear = adjustedTime.getFullYear();
          
    //             setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
    //             setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));
          
    //             fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
    //             toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
    //           }   
              
    //           const nowHours = now.getHours();
    //           const nowMinutes = now.getMinutes();
    //           const adjustedHours = adjustedTime.getHours();
    //           const adjustedMinutes = adjustedTime.getMinutes();
              
    //           if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
    //               const adjTimeValue = dayjs(adjustedTime)
    //               setToDate(adjTimeValue); 
    //               toDatefor = adjTimeValue;
    //           }
        
    //         }
    //       })

    //     const isValidDate = (value) => {
    //         const date = new Date(value);
    //         return !isNaN(date.getTime());
    //       };

    //     const parseDate = (dateString) => {
    //         if (!isValidDate(dateString)) {
    //           console.error("Invalid date:", dateString);
    //           return null; // Return null if the date string is invalid
    //         }
    //         return new Date(dateString);
    //       };
    
    //       const formatDateTime = (date) => {
    //         const year = date.getFullYear();
    //         const month = String(date.getMonth() + 1).padStart(2, '0');
    //         const day = String(date.getDate()).padStart(2, '0');
    //         const hours = String(date.getHours()).padStart(2, '0');
    //         const minutes = String(date.getMinutes()).padStart(2, '0');
    //         const seconds = String(date.getSeconds()).padStart(2, '0');
    
    //         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //       };
    






    //     const filters = {};

    //     if (IsAgentBased === '0') {
    //         const today = new Date();
    //         const startOfDay = new Date(today.setHours(0, 0, 0, 0));
    //         const endOfDay = new Date(today.setHours(23, 59, 59, 999));

    //         filters.fromdate = formatDateTime(startOfDay);
    //         filters.todate = formatDateTime(endOfDay);
    //     } 
        
    //     else {

    //     if (fromDate && !toDate) {
    //         filters.fromdate = formatDateTime(new Date(fromDate), 'yyyy-MM-dd HH:mm:SS')
    //     } else if (!fromDate && toDate) {
    //         filters.todate = formatDateTime(new Date(toDate), 'yyyy-MM-dd HH:mm:SS')
    //     } else if (fromDate && toDate) {
    //         filters.fromdate = formatDateTime(new Date(fromDate), 'yyyy-MM-dd HH:mm:SS')
    //         filters.todate = formatDateTime(new Date(toDate), 'yyyy-MM-dd HH:mm:SS')
    //     }
    //     }
    //     if (detectedType) {
    //         filters.detectedtype = (detectedType.map((item) => item.Id)).join(',')
    //     }
    //     if (device) {
    //         filters.camera = (device.map((item) => item.uid?item.uid:item)).join(',')
    //     }
    //     // console.log('filters', filters)
    //     return filters
    // }

    const getFilters = async () => {
        ////debugger
        console.log("fromdate and todate ",fromDate,toDate);

        const timezoneId = localStorage.getItem('timezone_id')
        const clientType = localStorage.getItem('client_type')
        const clientId = localStorage.getItem('client_id');

        let fromDatefor = fromDate;
        let toDatefor = toDate;
      
        let filters = {};
      
        if (clientType === 'US' && !isGet) {
          try {
            const response = await new Promise((resolve, reject) => {
              ////debugger
              getTimezoneList((response) => {
                if (response.status === 200) {
                  resolve(response);
                } else {
                  reject(response);
                }
              });
            });
            ////debugger
            const data = response.data;
            console.log(data)
            let timeOffset = data.filter(item => item.Id === parseInt(timezoneId, 10))[0].Time;
            //timeOffset='-16:30';
      
            const now = new Date();
            //console.log(now)
      
            const [sign, hours, minutes] = timeOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
            const offsetInMinutes = (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '+' ? 1 : -1);
      
            const adjustedTime = new Date(now.getTime() + offsetInMinutes * 60 * 1000);
      
            ////debugger
            console.log(now, adjustedTime)
            console.log(adjustedTime.getTime())
            console.log(now.getTime())
      
            if (now.getDate() !== adjustedTime.getDate()) {
              const adjustedDay = adjustedTime.getDate();
              const adjustedMonth = adjustedTime.getMonth();
              const adjustedYear = adjustedTime.getFullYear();
      
              setFromDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0));
              setToDate(dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day'));
      
              fromDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).startOf('month').hour(0).minute(0);
              toDatefor = dayjs(new Date(adjustedYear, adjustedMonth, adjustedDay)).endOf('day');
            }
      
            const nowHours = now.getHours();
            const nowMinutes = now.getMinutes();
            const adjustedHours = adjustedTime.getHours();
            const adjustedMinutes = adjustedTime.getMinutes();
      
            if (nowHours !== adjustedHours || nowMinutes !== adjustedMinutes) {
              const adjTimeValue = dayjs(adjustedTime)
              setToDate(adjTimeValue);
              toDatefor = adjTimeValue;
            }
          } catch (error) {
            console.error(error);
          }
        }
      
        const formatDateTime = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
      
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
      
        if (IsAgentBased === '0' && clientId === '1118' && buzzerCount === 0) {
            const today = new Date();
            const currentHour = today.getHours();
            
            // Set startOfDay and endOfDay based on whether it is before or after 5 AM
            let startOfDay, endOfDay;
            
            if (currentHour < 5) {
              // If before 5 AM, set startOfDay to previous day's 5:00 AM
              startOfDay = new Date(today.setHours(5, 0, 0, 0) - 24 * 60 * 60 * 1000); // Subtract one day for startOfDay
              // Set endOfDay to current day's 5:00 AM
              endOfDay = new Date(today.setHours(5, 0, 0, 0)); // End of the current day should be 5:00 AM tomorrow
            } else {
              // If after 5 AM, set startOfDay to current day's 5:00 AM
              startOfDay = new Date(today.setHours(5, 0, 0, 0)); // Start of the current day
              // Set endOfDay to the next day's 5:00 AM
              endOfDay = new Date(today.setHours(5, 0, 0, 0) + 24 * 60 * 60 * 1000); // Add one day for endOfDay
            }
            
            console.log("startOfDay:", startOfDay);
            console.log("endOfDay:", endOfDay);
            
            filters.fromdate = formatDateTime(new Date(startOfDay), 'yyyy-MM-dd HH:mm:SS');
            filters.todate = formatDateTime(new Date(endOfDay), 'yyyy-MM-dd HH:mm:SS');
        }
        
        else if (IsAgentBased === '0') {
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));
  
          filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS');
          filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS');
      }
       else {
      
          if (fromDatefor && !toDatefor) {
            filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS')
          } else if (!fromDatefor && toDatefor) {
            filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS')
          } else if (fromDatefor && toDatefor) {
            filters.fromdate = formatDateTime(new Date(fromDatefor), 'yyyy-MM-dd HH:mm:SS')
            filters.todate = formatDateTime(new Date(toDatefor), 'yyyy-MM-dd HH:mm:SS')
          }
        }
        if (detectedType) {
          filters.detectedtype = (detectedType.map((item) => item.Id)).join(',')
        }
        if (device) {
          filters.camera = (device.map((item) => item.uid ? item.uid : item)).join(',')
        }
        console.log('filters', filters)
        return filters;
      }

    useEffect(() => {
        getCameras()
        getAnalytics()
    }, [])


    // useEffect(() => {
    //     ////debugger
    //     const fetchData = async () => {
         
    //         ////debugger
    //       if (alldata.isGetData) {
    //         if (alldata.isLoad === false)
    //           dataSetup({
    //             ...alldata,
    //             isLoad: true
    //           })
      
    //         const filters = await getFilters();
    //         ////debugger
    //         console.log("===========",filters);

    //         const body = jsonToParam(filters);
    //         console.log("===========",body);
    //         const apiURL = `/client-info/get_client_audits?${body}`
    //         axios({
    //           method: 'get',
    //           url: getURL(apiURL),
    //           validateStatus: (status) => handleResponse(status),
    //         }).then(function (response) {
    //           if (response.status === 200 || response.status === 201) {
    //             console.log(response.data);
    //             const resData = response.data ? response.data : []
    //             const cameraIds = [9580, 9579, 9490, 9489]; // List of camera IDs to filter
    //             const camera9580Events = resData.filter(event => cameraIds.includes(event.CameraId));
    //             // const camera9580Events = resData.filter(event => event.CameraId === 9580);9579
    //              console.log("++++++++++++++==========",resData)
              
    //            const currentEventCount = camera9580Events.length;
    //             // if (currentEventCount > previousEventCount) {
    //             // playBuzzer(); // Call the buzzer function when events increase
    //             // }
          
    //             // Update the previous event count for the next check

    //             if (currentEventCount > previousEventCount) {
    //               // Check the DetectedType of the first event and play the appropriate sound
    //               const firstEventType = camera9580Events[0]?.DetectedType;
    //               console.log("firstEventType=======================================================",firstEventType)
    //               if (firstEventType === 'no_safety_helmet') {
                    
    //                 console.log("no_safety_helmet=======================================================",firstEventType)

    //                 playBuzzer1();
    //               } else if (firstEventType === 'no_safety_shoes') {
    //                 playBuzzer2();
    //               }
    //               else if (firstEventType === 'no_crash_helmet_violation') {
    //                 playBuzzer3();
    //               }
    //               else{
    //                 playBuzzer(); 
    //               }
              
    //      // Call the buzzer function when events increase
    //             }

    //             setpreviousEventCount(currentEventCount);

    //             setResData(response.data)
    //             if (alldata.isReloadForNewData) {
    //               let auditsdata = []
    //               const lastAuditId = (!alldata.reload || !alldata.lastId) && resData.length ? getId(resData, 'last') : alldata.lastId
    //               const firstAuditId = alldata.reload && resData.length ? getId(resData, 'first') : alldata.firstId
    //               if (alldata.reload && alldata.firstId) {
    //                 auditsdata = [...resData, ...alldata.data]
    //                 if (resData.length > 0) {
    //                   const validCameras = resData.filter((item) => BUZZER_CAMS.includes(Number(item.CameraId)))
    //                   if (validCameras.length > 0 && userType && Number(userType) === 33) {
    //                     playBuzzer()
    //                   }
    //                   setOpen(true)
    //                   setTimeout(() => setOpen(false), 1000 * 30)
    //                 }
    //               }
    //               else
    //                 auditsdata = lastAuditId !== alldata.lastId ? alldata.data.concat(resData) : alldata.data
      
    //               dataSetup({
    //                 ...alldata,
    //                 isLoad: false,
    //                 isGetData: false,
    //                 data: auditsdata,
    //                 filterData: filterData(search, resData),
    //                 lastId: lastAuditId,
    //                 reload: false,
    //                 firstId: firstAuditId
    //               })
      
    //             } else {
    //               dataSetup({
    //                 ...alldata,
    //                 isLoad: false,
    //                 isGetData: false,
    //                 customFilterData: resData,
    //                 filterData: filterData(search, resData),
    //                 reload: false
    //               })
    //             }
    //           } else
    //             dataSetup({
    //               ...alldata,
    //               isLoad: false,
    //             })
    //         })
    //       }
    //     }
    //     fetchData();

    //     if(isGet){
    //       setIsGet(false)
    //     }
    //   }, [alldata.isGetData])

    useEffect(() => {
      const fetchData = async () => {
         console.log("=============fromdate========",fromDate);
          if (alldata.isGetData) {
              if (!alldata.isLoad) {
                  dataSetup({ ...alldata, isLoad: true });
              }

              const filters = await getFilters();
              const body = jsonToParam(filters);
              const apiURL = `/client-info/get_client_audits?${body}`;
              
              axios({
                  method: 'get',
                  url: getURL(apiURL),
                  validateStatus: (status) => handleResponse(status),
              }).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                      const resData = response.data || [];
                      const cameraIds = [9580, 9579, 9490, 9489];
                      const camera9580Events = resData.filter(event => cameraIds.includes(event.CameraId));
                      const currentEventCount = camera9580Events.length;

                    //   Play buzzer only if initial fetch is done and event count has increased
                      if (hasFetchedInitially && currentEventCount > previousEventCount) {
                          console.log("=========eventType=========",camera9580Events[0].EventTime);
                          console.log("=========buzzerCount=========",buzzerCount);

                          const firstEventType = camera9580Events[0]?.DetectedType;
                         if(buzzerCount === 0){

                            if (firstEventType === 'no_safety_helmet') {
                                playBuzzer1();
                            } else if (firstEventType === 'no_safety_shoes') {
                                playBuzzer2();
                            } else if (firstEventType === 'no_crash_helmet_violation') {
                                playBuzzer3();
                            } else {
                                playBuzzer();
                            }

                         }

                      }
                      setBuzzerCount(0)

                    // if (hasFetchedInitially && currentEventCount > previousEventCount) {
                    //     // Extracting the relevant part of EventTime, assuming it's in the format "YYYY-MM-DD HH:MM:SS"
                    //     // const eventTimeStr = camera9580Events[0]?.EventTime.split(' ')[0] + ' ' + camera9580Events[0]?.EventTime.split(' ')[1]; // Format "YYYY-MM-DD HH:MM:SS"
                    //     const eventTimeStr = camera9580Events[0]?.EventTime.split('.')[0];
                    //     // Get current date and time in the same format
                    //     const currentDate = new Date();
    
                    //     // Format current date and time to "YYYY-MM-DD HH:MM:SS" using local time (IST)
                    //     const currentTimeStr = currentDate.getFullYear() + '-' +
                    //                             ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
                    //                             ('0' + currentDate.getDate()).slice(-2) + ' ' +
                    //                             ('0' + currentDate.getHours()).slice(-2) + ':' +
                    //                             ('0' + currentDate.getMinutes()).slice(-2) + ':' +
                    //                             ('0' + currentDate.getSeconds()).slice(-2);
                    
                    //     // Compare the times
                    //     if (eventTimeStr === currentTimeStr || Math.abs(new Date(currentDate) - new Date(eventTimeStr)) <= 3000) {
                    //         // If eventTimeStr is the same as currentTimeStr or within a 3-second difference
                    //         // If eventTimeStr is the same as currentTimeStr or within a 3-second difference
                    //         console.log("=========eventType=========", camera9580Events[0].EventTime);
                    //         const firstEventType = camera9580Events[0]?.DetectedType;
                            
                    //         if (firstEventType === 'no_safety_helmet') {
                    //             playBuzzer1();
                    //         } else if (firstEventType === 'no_safety_shoes') {
                    //             playBuzzer2();
                    //         } else if (firstEventType === 'no_crash_helmet_violation') {
                    //             playBuzzer3();
                    //         } else {
                    //             playBuzzer();
                    //         }
                    //     }
                    // }
                    


                      // Set initial fetch flag to true after the first fetch
                      if (!hasFetchedInitially) setHasFetchedInitially(true);

                      // Update previous event count for future checks
                      setPreviousEventCount(currentEventCount);

                      setResData(response.data);
                      if (alldata.isReloadForNewData) {
                          let auditsdata = [];
                          const lastAuditId = (!alldata.reload || !alldata.lastId) && resData.length ? getId(resData, 'last') : alldata.lastId;
                          const firstAuditId = alldata.reload && resData.length ? getId(resData, 'first') : alldata.firstId;

                          if (alldata.reload && alldata.firstId) {
                              auditsdata = [...resData, ...alldata.data];
                              if (resData.length > 0) {
                                  const validCameras = resData.filter((item) => BUZZER_CAMS.includes(Number(item.CameraId)));
                                  if (validCameras.length > 0 && userType && Number(userType) === 33) {
                                      playBuzzer();
                                  }
                                  setOpen(true);
                                  setTimeout(() => setOpen(false), 1000 * 30);
                              }
                          } else {
                              auditsdata = lastAuditId !== alldata.lastId ? alldata.data.concat(resData) : alldata.data;
                          }

                          dataSetup({
                              ...alldata,
                              isLoad: false,
                              isGetData: false,
                              data: auditsdata,
                              filterData: filterData(search, resData),
                              lastId: lastAuditId,
                              reload: false,
                              firstId: firstAuditId
                          });
                      } else {
                          dataSetup({
                              ...alldata,
                              isLoad: false,
                              isGetData: false,
                              customFilterData: resData,
                              filterData: filterData(search, resData),
                              reload: false
                          });
                      }
                  } else {
                      dataSetup({
                          ...alldata,
                          isLoad: false,
                      });
                  }
              });
          }
      };

      fetchData();
      
      if (isGet) {
          setIsGet(false);
      }
      
  }, [alldata.isGetData, previousEventCount]);


    const getId = (data, order) => {
        let id = data.length ? (order === 'last' ? data[data.length - 1].Id : data[0].Id) : null
        // console.log('data',data)
        // for (let x of data)
        //     id = id === null || (order === 'last' && parseInt(x.Id) < id) || (order === 'first' && parseInt(x.Id) > id) ? parseInt(x.Id) : id
        return id
    }

    const getDataFromLastId = (data, lastId) => [...data.filter((item) => parseInt(item.Id) > parseInt(lastId))]

    const loadMoreAudits = () => dataSetup({ ...alldata, isGetData: true })

    const reLoadAudits = () => dataSetup({ ...alldata, reload: true, isGetData: true })

    useEffect(() => {
        const intervalId = setInterval(() => {
            // console.log('Interval', alldata.reload)
            if (!alldata.reload && alldata.isReloadForNewData)
                reLoadAudits()
        }, 1000 * RUN_FREQ_TIME)
        return () => clearInterval(intervalId)
    })

    const filterData = (search, data) => {
      const keywords = ['high', 'normal'];
        return data.filter((item) => {
        //   //debugger
          const lowerSearch = search.toLowerCase();
          const containsKeyword = keywords.some(keyword => keyword.includes(lowerSearch));

            if (containsKeyword){         
                let connecttext = `${item.Priority === 1 ? "High" : "Normal"}` 
                connecttext = connecttext.toLowerCase()
                return connecttext.match(String(search).toLowerCase())
            }
            else{
                let connecttext = `${item.DetectedType} ${item.devicename}  ${item.ClientCreatedOn} ${item.EventNo} ${item.EventType}  ${item.EventTime}`
                connecttext = connecttext.toLowerCase()
                return connecttext.match(String(search).toLowerCase())             
            }
        })
    }

    const filterAudits = (value) => {
        ////debugger
        console.log(alldata)
        if (value) 
            dataSetup({ ...alldata, filterData: filterData(value, resData) })
        else
            dataSetup({ ...alldata, filterData: filterData('', resData) })
    }

    const updateToClient = (auditId) => {
        setEscalat(false)
        axios({
            method: 'post',
            url: getURL(`/escalation/isescalated?isescalated=1&audit_id=${auditId}`),
            validateStatus: (status) => handleResponse(status),
        }).then((response) => {
            setEscalat(true)
            // console.log(response.data)
        })
    }

    return {
        alldata,
        loadMoreAudits,
        dataSetup,
        reLoadAudits,
        search,
        setSearch,
        filterAudits,
        updateToClient,
        isEscalating,
        open,
        setOpen,
        cameras,
        analytics,
        fromDate,
        toDate,
        handleFromDate,
        handleToDate,
        detectedType,
        handleDetectedType,
        device,
        handleDevice,
        handleDelete,
        handlerGetFilterData,
        alertStatus,
        calculatedDay,
        handleNotificationBar
    }
}

export default useAuditList