import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { handleResponse } from "src/utils/responseHandler"
import { jsonToParam,getURL } from "src/utils/config"
import axios from 'src/axios'


const useClientWiseLEC = () => {

    const [ reportData, setReportData ] = useState({ data: [], username: '',  avgTime: '',  eventsCount: 0})
    const [ totalEscAgc,setTotalESC] = useState([]); 

    const { date , clientid ,hour ,latency} = useParams()

    useEffect(() => {
        // console.log("reports ",date, clientid ,hour)
        getAgentWiseReport({
            date: date,
            ClientId: clientid,
            hour : hour,
            latency : latency
            
        })
    }, [date, clientid ,hour ,latency])    

    const manageReportData = (data) => {

        let username = ''
        let auditsCount = 0
        let audits = []
        let avgAuditTime = ''

        audits = data
        if (data.hasOwnProperty('agent_details') && data.agent_details.length > 0) {
            username = data.agent_details[0].agentname
            avgAuditTime = data.agent_details[0].averagereviewtimeperaudit
        }
        if (data.hasOwnProperty('EventsCount'))
            auditsCount = data.EventsCount

        return [username, auditsCount, avgAuditTime, audits]
    }


    const getAgentWiseReport = (formdata) => {
        axios({
            method: 'get',
            url: getURL(`client-reports/get-client-wise-hourly-latencycount-audits?${jsonToParam(formdata)}`),
            validateStatus: (status) => handleResponse(status)
        }).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // console.log("Latency Data ",response.data);
                setTotalESC(response.data);
                const [ username, auditsCount, avgAuditTime, audits ] = manageReportData(response.data)
                setReportData({...reportData, data: audits, username: username, avgTime: avgAuditTime, eventsCount: auditsCount})
            }
        })
    }
    

    return [ reportData ]
}

export default useClientWiseLEC