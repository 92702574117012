import { filter } from "lodash";
import { useEffect, useState, useRef } from "react";
// material
import {
  Table,
  Stack,
  Avatar,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  // Checkbox,
  TableRow,
  TableBody,
  TextField,
  Typography,
  TableCell,
  Link,
  Button,
  TableContainer,
  TablePagination,
  Select,
  Box,
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  // Typography,
  // TableFooter
} from "@mui/material";
// components
// import Label from '../../components/Label';
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import Label from "src/components/Label";
import {
  UserListHead,
  UserListToolbar,
  // UserMoreMenu
} from "../@dashboard/user";
import { styled } from "@mui/material/styles";
import { ro } from "date-fns/locale";
import Iconify from "src/components/Iconify";
import { setReponAudit } from "src/api/releaseAudit";
import CircularProgress from "@mui/material/CircularProgress";
import CopyToClipboard from "react-copy-to-clipboard";
import { InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// ----------------------------------------------------------------------

// create custom cell component for border table cell
const CustomTableCell = styled(TableCell)(() => ({
  border: "1px solid #ededed",
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ResultTable({
  activeTab,
  filterForms,
  shoulDsiplayPagination,
  isResponseAvailable,
}) {
  ////debugger
  console.log('activeTab',activeTab,isResponseAvailable,filterForms)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isSnoAvail, setIsSnoAvail] = useState(false);

  const [pinnedClients, setPinnedClients] = useState([]);
  // Camera Wise
  const [totalART, setTotalART] = useState(null);
  const [totalAHT, setTotalAHT] = useState(null);
  const [totalLatancy, setTotalLatancyCount] = useState(null);
  const [totalTEC, setTECCount] = useState(null);
  const [totalFEC, setFCCCount] = useState(null);
  const [totalCEC, setCECCount] = useState(null);
  const [totalGEC, setGECCount] = useState(null);

  const [openEvent, setOpenEvent] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  //const [firstRow,setFirstRow]=useState(false)
  //const [previousPageLastGroup, setPreviousPageLastGroup] = useState(null);
  const [selectedSpeed, setSelectedSpeed] = useState(3); // Initial playback speed

  // escalte audit section
  const [selectedEventNo, setSelectedEventNo] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [copied, setCopied] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 3;
    }
  }, []);

  useEffect(() => {
   // console.log(pinnedClients);
  }, [pinnedClients]);

  useEffect(() => {
    //console.log('active tab changes',activeTab)
    if (activeTab.tab === 0) {
      // updatePinnedClients()
    }
  }, [page, rowsPerPage, activeTab.data]);

  const updatePinnedClients = () => {
    const startIdx = page * rowsPerPage;
    const endIdx = Math.min(startIdx + rowsPerPage, activeTab.data.length);
    let pinnedClient = null;

    // if (startIdx < activeTab.data.length && !activeTab.data[startIdx].cameraName && !activeTab.data[startIdx].date) {
    //   if (page > 0 && pinnedClients) {
    //     pinnedClient = pinnedClients;
    //     setFirstRow(true)
    //   }
    // } else {
    //   for (let i = endIdx - 1; i >= startIdx; i--) {
    //     const client = filteredUsers[i];
    //     if (client.hasOwnProperty('sno')) {
    //       pinnedClient = client;
    //       setFirstRow(true)
    //       break;
    //     }
    //   }
    // }
    for (let i = endIdx - 1; i >= startIdx; i--) {
      const client = filteredUsers[i];
      if (client.hasOwnProperty("sno")) {
        pinnedClient = client;
        setPinnedClients((prevPinnedClients) => [
          ...prevPinnedClients,
          pinnedClient,
        ]);
      }
    }
  };

  useEffect(() => {
    // console.log("tab changes");
    setFilterName("");
    if (Object.keys(activeTab).length > 0) {
      if (activeTab.data.length > 0) {
        let value = activeTab.data[0].sno
          ? setIsSnoAvail(true)
          : setIsSnoAvail(false);
      }
    }

    setPage(0);
  }, [activeTab]);

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => {
        const arravalue = [
          ...[...activeTab.tableHeads.map((row) => _user[row.id])].filter(
            (a) => a
          ),
        ].join(" ");
        return arravalue.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = activeTab.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    // console.log(event.target.value);
    setFilterName(event.target.value);
  };

  let emptyRows = 0;
  ////debugger

  if (shoulDsiplayPagination) {
    emptyRows =
      page > 0
        ? Math.max(0, (1 + page) * rowsPerPage - activeTab.data.length)
        : 0;
  } else {
    emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, activeTab.data.length - page * rowsPerPage);
  }

  const filteredUsers = applySortFilter(
    activeTab.data,
    getComparator(order, orderBy),
    filterName
  );
  //  console.log("filteredUsers Lenght ......",filteredUsers.length);
  const isUserNotFound = filteredUsers.length === 0;

  const getHrefLink = (rowData, text) => {
    text.match(/{/gi).forEach((_) => {
      let indexText = text.substring(text.indexOf("{") + 1, text.indexOf("}"));
      text = text.replace(`{${indexText}}`, rowData[indexText]);
    });
    return text;
  };

  const isSubRow = (row) => row.client === "Sub Total";
  const isGrandRow = (row) => row.client === "Grand Total";
  const isGrandRowAgent = (row) => row.agenthour === "Grand Total";

  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTimeString(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  useEffect(() => {
    if (filteredUsers != null && activeTab.tab === 1) {
      let totalFECCount = filteredUsers.reduce(
        (acc, user) => acc + user.falseeventscount,
        0
      );
      let totalTECCount = filteredUsers.reduce(
        (acc, user) => acc + user.trueeventscount,
        0
      );
      let totalCECCount = filteredUsers.reduce(
        (acc, user) => acc + user.completedeventscount,
        0
      );
      let totalGECCount = filteredUsers.reduce(
        (acc, user) => acc + user.generatedeventscount,
        0
      );

      let totalLatencyCount = filteredUsers.reduce(
        (acc, user) => acc + user.latency,
        0
      );
      let totalARTSeconds = filteredUsers.reduce(
        (acc, user) => acc + timeStringToSeconds(user.art),
        0
      );
      let totalAHTSeconds = filteredUsers.reduce(
        (acc, user) => acc + timeStringToSeconds(user.aht),
        0
      );

      setFCCCount(Math.floor(totalFECCount));
      setTECCount(Math.floor(totalTECCount));
      setCECCount(Math.floor(totalCECCount));
      setGECCount(Math.floor(totalGECCount));

      setTotalLatancyCount(Math.floor(totalLatencyCount));
      setTotalART(secondsToTimeString(totalARTSeconds));
      setTotalAHT(secondsToTimeString(totalAHTSeconds));
    } else if (filteredUsers != null && activeTab.tab === 3) {
      let totalFECCount = filteredUsers.reduce(
        (acc, user) => acc + user.falseevents,
        0
      );
      let totalTECCount = filteredUsers.reduce(
        (acc, user) => acc + user.trueevents,
        0
      );
      let totalEscalatedCount = filteredUsers.reduce(
        (acc, user) => acc + user.totalescalated,
        0
      );
      let totalEvents = filteredUsers.reduce(
        (acc, user) => acc + user.totalevents,
        0
      );
      let totalARTSeconds = filteredUsers.reduce(
        (acc, user) => acc + timeStringToSeconds(user.art),
        0
      );
      let totalAHTSeconds = filteredUsers.reduce(
        (acc, user) => acc + timeStringToSeconds(user.aht),
        0
      );

      setFCCCount(Math.floor(totalFECCount));
      setTECCount(Math.floor(totalTECCount));
      setCECCount(Math.floor(totalEscalatedCount));
      setGECCount(Math.floor(totalEvents));
      setTotalART(secondsToTimeString(totalARTSeconds));
      setTotalAHT(secondsToTimeString(totalAHTSeconds));
    }
  }, [filteredUsers]);

  useEffect(() => {
    setSelectedSpeed(3);
  }, []);

  const videoLoaded = () => {
    // //debugger
    const video = document.getElementById("videoPlayer");
    let playSpeed = 3;
    if (video) {
      setSelectedSpeed(playSpeed);
      video.playbackRate = playSpeed;
    }
  };

  const handleImageClick = (event, row) => {
    ////debugger
    event.preventDefault();
    setDialogContent(row);
    setOpenEvent(true);
  };

  const handleCloseDialog = () => {
    setOpenEvent(false);
  };

  const handleSpeedChange = (event) => {
    setSelectedSpeed(event.target.value);
    const video = document.getElementById("videoPlayer");
    if (video) {
      video.playbackRate = event.target.value;
    }
  };

  const formatEventRaised = (users) => {
    return users.map((user) => {
      const formattedEventRaised =
        user.eventraised != null ? user.eventraised.split(".")[0] : "";
      const formattedRevieStart =
        user.reviewstart != null ? user.reviewstart.split(".")[0] : "";
      const formattedRevieEnd =
        user.reviewend != null ? user.reviewend.split(".")[0] : "";
      const formattedIsEscalated =
        user.is_supervisor_escalated === false ? "NO" : "YES";
      return {
        ...user,
        eventraised: formattedEventRaised,
        reviewstart: formattedRevieStart,
        reviewend: formattedRevieEnd,
        is_supervisor_escalated: formattedIsEscalated,
      };
    });
  };

  {
    /* <Label variant="ghost" color={ Number(row.IsAnnonced) === 1 ? 'success': 'error'}>
{Number(row.IsAnnonced) === 1  ? 'Yes': 'No'}
</Label> */
  }

  // Applying the format function to your data
  const formattedUsers = formatEventRaised(filteredUsers);
  // console.log("formate user",formattedUsers);

  const handleOpenDialogAudit = (eventNo) => {
    setSelectedEventNo(eventNo);
    setOpenDialog(true);
  };

  const handleCloseDialogAudit = () => {
    setOpenDialog(false);
    setSelectedEventNo([]);
  };

  const handleConfirm = () => {
    const body = {
      EventNo: selectedEventNo.eventno,
    };
    setReponAudit(body, (response) => {
      if (response.status === 200) {
        // console.log("event send to tier 2 ")
      }
    });

    handleCloseDialogAudit();
  };

  return (
    <>
    
      <Box>
        {activeTab.tab != 5 && (
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            reportData={activeTab}
          />
        )}
       
        {/* <Scrollbar style={{ height: "80vh" }}> */}
          <TableContainer sx={{ minWidth: 800, maxHeight: "80vh" }}>
            <Table stickyHeader>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={activeTab.tableHeads}
                rowCount={activeTab.data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {isResponseAvailable && (
                <TableBody>
                  {formattedUsers
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .slice(
                      shoulDsiplayPagination ? page * rowsPerPage : 0,
                      shoulDsiplayPagination
                        ? page * rowsPerPage + rowsPerPage
                        : filteredUsers.length
                    )
                    .map((row, index) => {
                      let isYellow = "";
                      if (filterForms.noOfAuditsTypes === "Hourly") {
                        isYellow = row.eventscount <= filterForms.noOfAudits;
                      } else if (
                        filterForms.noOfAuditsTypes === "FullDay" &&
                        row.client === "Sub Total"
                      ) {
                        isYellow = row.eventscount <= filterForms.dayNoOfAudity;
                      } else if (filterForms.noOfAuditsTypes === "Both") {
                        row.client === "Sub Total"
                          ? (isYellow =
                              row.eventscount <= filterForms.dayNoOfAudity)
                          : (isYellow =
                              row.eventscount <= filterForms.noOfAudits);
                      }
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          style={
                            // isYellow ? { backgroundColor: "#FFCA4B" } : {}
                            isYellow &&
                            row.agenthour !== "Grand Total" &&
                            row.agenthour !== "Sub Total"
                              ? { backgroundColor: "#FFCA4B" }
                              : {}
                          }
                        >
                          {/* <CustomTableCell align="left">{index+1}</CustomTableCell> */}
                          {isSnoAvail === false && (
                            <CustomTableCell align="left">
                              {index + 1}
                            </CustomTableCell>
                          )}
                          {activeTab.tableHeads.map(
                            (heads, key) =>
                              heads.id !== "" &&
                              (typeof row[heads.id] !== "object" ||
                                (typeof row[heads.id] === "object" &&
                                  row[heads.id] !== null &&
                                  row[heads.id].hasOwnProperty("rowSpam") &&
                                  row[heads.id].rowSpam > 0)) && (
                                <CustomTableCell
                                  rowSpan={
                                    typeof row[heads.id] === "object" &&
                                    row[heads.id] !== null &&
                                    row[heads.id].hasOwnProperty("rowSpam") &&
                                    row[heads.id].rowSpam > 0
                                      ? Number(row[heads.id].rowSpam)
                                      : 1
                                  }
                                  key={key}
                                  align="left"
                                >
                                  {heads.dataType === "image" ? (
                                    <Link
                                      href={row[heads.id]}
                                      target="_blank"
                                      onClick={(e) => handleImageClick(e, row)}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar
                                          variant="square"
                                          alt={row[heads.id]}
                                          src={row[heads.id]}
                                        />
                                      </Stack>
                                    </Link>
                                  ) : heads.dataType === "button" &&
                                    !(
                                      isSubRow(row) ||
                                      isGrandRow(row) ||
                                      isGrandRowAgent(row)
                                    ) ? (
                                    row.SupervisorEscalated === true ? (
                                      <Button color="error">Yes</Button>
                                    ) : (
                                      <Button
                                        disabled={row.AgentEscalated === true}
                                        variant="contained"
                                        color="success"
                                        onClick={() =>
                                          handleOpenDialogAudit(row)
                                        }
                                      >
                                        No
                                      </Button>
                                    )
                                  ) : heads.dataType === "link" &&
                                    !(
                                      isSubRow(row) ||
                                      isGrandRow(row) ||
                                      isGrandRowAgent(row)
                                    ) ? (
                                    <Link
                                      target="_blank"
                                      href={getHrefLink(row, heads.link)}
                                    >
                                      {row[heads.id]}
                                    </Link>
                                  ) : typeof row[heads.id] === "object" &&
                                    row[heads.id] !== null &&
                                    row[heads.id].hasOwnProperty(
                                      "columnValue"
                                    ) ? (
                                    row[heads.id].columnValue
                                  ) : isSubRow(row) ||
                                    isGrandRow(row) ||
                                    isGrandRowAgent(row) ? (
                                    <b>{row[heads.id]}</b>
                                  ) : row[heads.id] === null ? (
                                    ""
                                  ) : (
                                    row[heads.id]
                                  )}
                                </CustomTableCell>
                              )
                          )}
                        </TableRow>
                      );
                    })}
                  {activeTab.tab === 1 && filteredUsers.length > 0 && (
                    <TableRow>
                      <CustomTableCell
                        colSpan={activeTab.tableHeads.length - 8}
                        align="right"
                      ></CustomTableCell>
                      <CustomTableCell align="left">
                        <b>Total </b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalGEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalCEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalTEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalFEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalLatancy}</b>
                      </CustomTableCell>
                      <CustomTableCell  align="left" ></CustomTableCell>
                      <CustomTableCell align="left"></CustomTableCell>
                    </TableRow>
                  )}
                  {activeTab.tab === 3 && filteredUsers.length > 0 && (
                    <TableRow>
                      <CustomTableCell
                        colSpan={activeTab.tableHeads.length - 8}
                        align="right"
                      ></CustomTableCell>
                      <CustomTableCell align="left">
                        <b>Total </b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalGEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalTEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalFEC}</b>
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        <b>{totalCEC}</b>
                      </CustomTableCell>
                      <CustomTableCell  align="left" ></CustomTableCell>
                      <CustomTableCell align="left"></CustomTableCell>
                    </TableRow>
                  )}
                  {activeTab.footer.map((r, k) => (
                    <TableRow key={k}>
                      <CustomTableCell
                        sx={{ fontWeight: 800 }}
                        colSpan={activeTab.colSpan + 1}
                        align="center"
                      >
                        {activeTab.title}
                      </CustomTableCell>
                      {activeTab.footerKeys.map((fkr, fkk) => (
                        <CustomTableCell
                          sx={{ fontWeight: 800 }}
                          key={fkk}
                          align="left"
                        >
                          {r[fkr]}
                        </CustomTableCell>
                      ))}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <CustomTableCell colSpan={activeTab.tableHeads.length} />
                    </TableRow>
                  )}
                </TableBody>
              )}

              {/* causes validation message */}
              {(isUserNotFound && filterName) ? (  
                <TableBody>
                  <TableRow>
                    <CustomTableCell
                      align="center"
                      colSpan={activeTab.tableHeads.length}
                      sx={{ py: 3 }}
                    >                     
                        <SearchNotFound searchQuery={filterName} />                    
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              ):null}
              {filteredUsers.length === 0 && !filterName && isResponseAvailable && (
                  <TableBody>
                    <TableRow>
                      <CustomTableCell
                        align="center"
                        colSpan={activeTab.tableHeads.length}
                        sx={{ py: 3 }}
                      >
                      
                          {/* <Typography
                            variant="body1"
                            align="center"
                            style={{ fontWeight: "bold" }}
                          > */}
                            {/* No Data */}
                          
                              {activeTab.tab != 5 && <CircularProgress />}
                              {activeTab.tab == 5 && (
                                <Typography
                                  variant="body1"
                                  align="center"
                                  style={{ fontWeight: "bold" }}
                                >
                                  No Data
                                </Typography>
                              )}
                          
                          {/* </Typography> */}
                      
                      </CustomTableCell>
                    </TableRow>
                  </TableBody>
              )}
              {!isResponseAvailable && (
                <TableBody>
                  <TableRow>
                    <CustomTableCell
                      align="center"
                      colSpan={activeTab.tableHeads.length}
                      sx={{ py: 3 }}
                    >
                    
                        <Typography
                          variant="body1"
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          No Data
                        </Typography>
                     
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        {/* </Scrollbar> */}

        {shoulDsiplayPagination && (
          <TablePagination
            rowsPerPageOptions={[100, 300, 500]}
            component="div"
            count={activeTab.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>

      <Dialog open={openEvent} fullWidth={fullWidth} maxWidth={"sm"}>
        <DialogContent>
          <video
            controls
            id="videoPlayer"
            width="100%"
            height="auto"
            autoPlay
            onLoadStart={videoLoaded}
          >
            <source src={dialogContent.VideoUrl} type="video/mp4" />
          </video>

          <Box>
            <Stack direction="row" spacing={3}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{ pt: 1 }}
              >
                <Typography sx={{ fontSize: "0.9rem", fontWeight: 800 }}>
                  Camera Name:{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}
                >
                  {dialogContent.camera}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{ pt: 1 }}
              >
                <Typography sx={{ fontSize: "0.9rem", fontWeight: 800 }}>
                  Event No:{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "0.9rem", textTransform: "capitalize" }}
                >
                  {dialogContent.eventno}
                </Typography>
              </Stack>
            </Stack>
            <TextField
              style={{ width: "100%", height: 50 }}
              value={dialogContent.VideoUrl}
              InputProps={{
                style: {
                  fontSize: "0.7rem",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <IconButton onClick={handleCopy} edge="end"> */}
                    <CopyToClipboard
                      text={dialogContent.VideoUrl}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 2000);
                      }}
                    >
                      <IconButton edge="end">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
            <Stack direction="row" sx={{ pt: 2 }}>
              {copied && (
                <Typography variant="body2" color="success.main" sx={{ pl: 1 }}>
                  Copied!
                </Typography>
              )}
            </Stack>

            {/* <Stack direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              // sx={{pt: 1}}
              >
                  <Typography sx={{ fontSize: "0.7rem", fontWeight: 800 }}>Video URL: </Typography>
                    <Typography sx={{ fontSize: "0.7rem", textTransform: "capitalize" }}>{dialogContent.VideoUrl}</Typography>
          </Stack> */}
          </Box>

          <Grid container sx={{ pt: 2 }}>
            <Grid item md>
              <FormControl sx={{ minWidth: "25%" }}>
                <InputLabel id="video-simple-select-label">Speed</InputLabel>
                <Select
                  labelId="video-simple-select-label"
                  id="video-simple-select"
                  label="Speed"
                  value={selectedSpeed ? selectedSpeed : 3}
                  onChange={handleSpeedChange}
                >
                  <MenuItem value={1}>1x</MenuItem>
                  <MenuItem value={2}>2x</MenuItem>
                  <MenuItem value={3}>3x</MenuItem>
                  <MenuItem value={5}>5x</MenuItem>
                  <MenuItem value={10}>10x</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md sx={{ textAlign: "right" }}>
              <Button
                color="error"
                variant="contained"
                onClick={handleCloseDialog}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openDialog}>
        <DialogContent>
          <Stack
            //  direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: 350, textAlign: "center" }}
          >
            <Iconify
              icon={"eva:alert-triangle-outline"}
              sx={{ fontSize: 92, color: "#ffb2a3" }}
            />
            <Typography sx={{ color: "gray" }} variant="h5">
              {" "}
              Are you sure you want to send Audit to tier-2 for Event No:{" "}
              {selectedEventNo.eventno}?
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialogAudit}
            color="error"
          >
            No
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}