import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useRef } from "react";
// material
import {
  Table,
  Stack,
  Avatar,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Link,
  TableContainer,
  Typography,
  TablePagination,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  // Typography,
  // TableFooter,
  // Chip
} from "@mui/material";

import {IconButton, InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// components
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../@dashboard/user";
import Iconify from "src/components/Iconify";

import Label from "../../components/Label";
import { setReponAudit } from "src/api/releaseAudit";
import CopyToClipboard from "react-copy-to-clipboard";


// ----------------------------------------------------------------------

// const text =  {
//     "Id": 1918,
//     "Comments": "",
//     "CreatedOn": "2023-02-03 15:31:36.533",
//     "UpdatedOn": "2023-02-03 16:32:37.277",
//     "devicename": "SOFTWARE",
//     "CameraId": 6149,
//     "UserId": 15,
//     "VideoUrl": "http://192.168.30.66:8800/videos/6149_SOFTWARE/clip_6149_SOFTWARE_230203_153109.mp4",
//     "DetectedType": "person",
//     "Analyticskey": "person",
//     "UpdatedUrl": "",
//     "SnapshotUrl": "http://192.168.30.66:8800/images/6149_SOFTWARE/snap_6149_230203_153109.jpg",
//     "Remarks": "person",
//     "CreatedBy": "",
//     "UpdatedBy": "",
//     "ClientId": 13,
//     "type": 0,
//     "is_locked": true,
//     "is_escalated": false,
//     "EscalatedNotes": "",
//     "EventType": "",
//     "VideoPath": "",
//     "SnapshotPath": "",
//     "EventNo": "6149_230203153136",
//     "DBSyncStatus": true,
//     "EventTime": "15:31:36.533",
//     "SupervisorStatus": 0,
//     "SupervisorComments": "",
//     "substream": "",
//     "AuditingStatus": 3,
//     "AuditAssignTo": 0,
//     "AuditAssignOn": "2023-02-03 16:32:26.222",
//     "is_favourite": 0,
//     "related_to": "Agent",
//     "TemporaryStream": "",
//     "is_true_event": 0,
//     "ClientStatus": 0,
//     "category": 2,
//     "audit_sno": 1,
//     "is_supervisor_escalated": false,
//     "Status": ""
//   }

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "company", label: "Preview", alignRight: false },
  { id: "EventNo", label: "Event No", alignRight: false },
  { id: "devicename", label: "Camera Name", alignRight: false },
  { id: "status", label: "Event Raised", alignRight: false },
  { id: "status", label: "Review Start Time", alignRight: false },
  { id: "status", label: "Review End Time", alignRight: false },
  { id: "status", label: "Latency", alignRight: false },
  { id: "status", label: "Reviewed By", alignRight: false },
  { id: "EventType", label: "Agent Disposition", alignRight: false },
  { id: "is_escalated", label: "Is Escalated?", alignRight: false },
  { id: "status", label: "Is Announced?", alignRight: false },
  { id: "status", label: "Is called?", alignRight: false },
  { id: "Comments", label: "Comments", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ReportTable({ reportData }) {
  ////debugger
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEventNo, setSelectedEventNo] = useState([]);

  const [openEvent, setOpenEvent] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [selectedSpeed, setSelectedSpeed] = useState(3); // Initial playback speed
  const videoRef = React.createRef();

  //const [reportDataCopy,setReportDataCopy]=useState({})

  useEffect(() =>{
    setSelectedSpeed(3);
  },[selectedEventNo])

  // useEffect(()=>{
  //   setReportDataCopy(reportData)
  // },[])

  // useEffect(()=>{
  //   //debugger
  //   console.log('reportDataCopy',reportDataCopy);
  // },[reportDataCopy])


  function applySortFilter(array, comparator, query) {
    return filter(array, (row) => {
      const rowValues = Object.values(row).map((value) =>
        String(value).toLowerCase()
      );
      return rowValues.join(" ").includes(query.toLowerCase());
    }).sort(comparator);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = reportData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    // console.log(event.target.value)
    setFilterName(event.target.value);
  };

  const handleOpenDialog = (eventNo) => {
    setSelectedEventNo(eventNo);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEventNo([]);
  };

  const handleConfirm = () => {
    ////debugger
    const body = {
      EventNo: selectedEventNo.EventNo,
    };
    setReponAudit(body, (response) => {
      if (response.status === 200) {
        ////debugger
        // console.log("event send to tier 2 ")
      }
    });

    handleCloseDialog();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reportData.length) : 0;
    

  const filteredUsers = applySortFilter(
    reportData,
    getComparator(order, orderBy),
    filterName
  );

  // console.log('type of',typeof(filteredUsers))

  const isUserNotFound = filteredUsers.length === 0;

  const secondsToHHMMSS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const pad = (num) => String(num).padStart(2, "0");

    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  const formatEventRaised = (users) => {
    return users.map((user) => {
      const formattedDate =
        user.EventRaised != null ? user.EventRaised.split(".")[0] : "";
      const formattedRevieStart =
        user.ReviewStartTime != null ? user.ReviewStartTime.split(".")[0] : "";
      const formattedRevieEnd =
        user.ReviewEndTime != null ? user.ReviewEndTime.split(".")[0] : "";
      const latencySeconds =
        user.Latency != null ? secondsToHHMMSS(user.Latency) : "";

      return {
        ...user,
        EventRaised: formattedDate,
        ReviewStartTime: formattedRevieStart,
        ReviewEndTime: formattedRevieEnd,
        Latency: latencySeconds,
      };
    });
  };

  // Applying the format function to your data
  const formattedUsers = formatEventRaised(filteredUsers);

  // console.log("formatted user...",formattedUsers);

  const handleImageClick = (event, row) => {
    event.preventDefault();
    setDialogContent(row);
    setOpenEvent(true);
  };

  const handleCloseDialogEvent = () => {
    setSelectedSpeed(3)
    setOpenEvent(false);
  };

  // const handleSpeedChange = (event) => {
  //   setSelectedSpeed(event.target.value);
  //   const video = document.getElementById("videoPlayer");
  //   if (video) {
  //     video.playbackRate = event.target.value;
  //   }
  // };
 
  const handleSpeedChange = (event) => {
    setSelectedSpeed(event.target.value);
    if (videoRef.current) {
      videoRef.current.playbackRate = event.target.value;
    }
  };
  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = selectedSpeed;
    }
  };

  // console.log("filter user",filteredUsers);


  const [copied, setCopied] = useState(false);

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(dialogContent.VideoUrl).then(() => {
  //     setCopied(true);
  //     setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  //   }).catch(err => {
  //     console.error('Failed to copy: ', err);
  //   });
  // };





  return (
    <>
      <Box>

        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          reportData={reportData}
          sx={{ pt: 3}}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={reportData.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {formattedUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        // role="checkbox"
                        // selected={isItemSelected}
                        // aria-checked={isItemSelected}
                      >
                        <TableCell>{index + page * rowsPerPage + 1}</TableCell>
                        <TableCell align="left">
                          <Link
                            href={row.VideoUrl}
                            target="blank"
                            onClick={(e) => handleImageClick(e, row)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                variant="square"
                                alt={row.SnapshotUrl}
                                src={row.SnapshotUrl}
                              />
                            </Stack>
                          </Link>
                        </TableCell>
                        <TableCell align="left">{row.EventNo}</TableCell>
                        <TableCell align="left">{row.CameraName}</TableCell>
                        <TableCell align="left">{row.EventRaised}</TableCell>
                        <TableCell align="left">
                          {row.ReviewStartTime}
                        </TableCell>
                        <TableCell align="left">{row.ReviewEndTime}</TableCell>
                        <TableCell align="left">{row.Latency}</TableCell>
                        <TableCell align="left">{row.ReportedBy}</TableCell>
                        <TableCell align="left">{row.EventType}</TableCell>
                        <TableCell>
                          {row.SupervisorEscalated === true ? (
                            <Button color="error">Yes</Button>
                          ) : (
                            <Button
                            disabled ={row.AgentEscalated === true}
                            variant="contained"
                            color="success"
                            onClick={() => handleOpenDialog(row)}
                          >          
                            No
                          </Button>

                          

                          )}
                        </TableCell>
                        {/* <TableCell align="left">{row.IsEscalated === true ? (
                            <Iconify icon="eva:checkmark-circle-2-outline" sx={{ color: "green", fontSize: 30 }} />
                            // <Chip label={<Iconify icon="mdi:check" />} color="success" size="small" variant="outlined" />
                          ):(
                            <Iconify icon="eva:close-circle-outline" sx={{ color: "red", fontSize: 30 }} />
                          )}</TableCell> */}

                        <TableCell>
                          <Label
                            variant="ghost"
                            color={
                              Number(row.IsAnnounced) === 1 ? "success" : "error"
                            }
                          >
                            {Number(row.IsAnnounced) === 1 ? "Yes" : "No"}
                          </Label>
                        </TableCell>

                        {/* <TableCell align="left">{Number(row.IsAnnonced) === 1 ? (
                            <Iconify icon="eva:checkmark-circle-2-outline" sx={{ color: "green", fontSize: 30 }} />
                            // <Chip label={<Iconify icon="mdi:check" />} color="success" size="small" variant="outlined" />
                          ):(
                            <Iconify icon="eva:close-circle-outline" sx={{ color: "red", fontSize: 30 }} />
                          )}</TableCell> */}

                        <TableCell>
                          <Label
                            variant="ghost"
                            color={
                              Number(row.IsCalled) === 1 ? "success" : "error"
                            }
                          >
                            {Number(row.IsCalled) === 1 ? "Yes" : "No"}
                          </Label>
                        </TableCell>
                        {/* 
                          <TableCell align="left">{Number(row.IsCalled) === 1 ? (
                            <Iconify icon="eva:checkmark-circle-2-outline" sx={{ color: "green", fontSize: 30 }} />
                            // <Chip label={<Iconify icon="mdi:check" />} color="success" size="small" variant="outlined" />
                          ):(
                            <Iconify icon="eva:close-circle-outline" sx={{ color: "red", fontSize: 30 }} />
                          )}</TableCell> */}
                        <TableCell align="left">{row.Comments}</TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={TABLE_HEAD.length} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={TABLE_HEAD.length}
                      sx={{ py: 3 }}
                    >
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[100, 300, 500]}
          component="div"
          count={reportData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <Stack
            //  direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: 350, textAlign: "center" }}
          >
            <Iconify
              icon={"eva:alert-triangle-outline"}
              sx={{ fontSize: 92, color: "#ffb2a3" }}
            />
            <Typography sx={{ color: "gray" }} variant="h5">
              {" "}
              Are you sure you want to send Audit to tier-2 for Event No:{" "}
              {selectedEventNo.EventNo}?
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDialog} color="error">
            No
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
      open={openEvent}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: { xs: '80%', sm: '60%', md: '40%' }, // Responsive width
          maxWidth: { xs: '80%', sm: '60%', md: '40%' }, // Responsive max width
        },
      }}
    >
      <DialogContent>
        <video
          controls
          id="videoPlayer"
          width="100%"
          height="auto" // Adjust height automatically
          ref={videoRef}
          autoPlay
          onLoadedMetadata={handleVideoLoaded}
          style={{ objectFit: 'contain' }} // Ensure video fits well
        >
          <source src={dialogContent.VideoUrl} type="video/mp4" />
        </video>

        <Stack
          direction={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Typography sx={{ fontSize: '0.9rem', fontWeight: 800 }}>Camera Name:</Typography>
          <Typography sx={{ fontSize: '0.9rem', textTransform: 'capitalize' }}>
            {dialogContent.CameraName}
          </Typography>
          <Typography sx={{ fontSize: '0.9rem', fontWeight: 800, ml: 2 }}>Event No:</Typography>
          <Typography sx={{ fontSize: '0.9rem', textTransform: 'capitalize' }}>
            {dialogContent.EventNo}
          </Typography>
        </Stack>

        <TextField
          id="videoLink"
          variant="outlined"
          fullWidth
          style={{ height: 50, marginTop: 16 }}
          value={dialogContent.VideoUrl}
          InputProps={{
            style: { fontSize: '0.9rem' },
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboard
                  text={dialogContent.VideoUrl}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  }}
                >
                  <IconButton edge="end">
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" sx={{ pt: 2 }}>
          {copied && <Typography variant="body2" color="success.main" sx={{ pl: 1 }}>Copied!</Typography>}
        </Stack>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="video-simple-select-label">Speed</InputLabel>
              <Select
                labelId="video-simple-select-label"
                id="video-simple-select"
                label="Speed"
                value={selectedSpeed}
                onChange={handleSpeedChange}
              >
                <MenuItem value={1}>1x</MenuItem>
                <MenuItem value={2}>2x</MenuItem>
                <MenuItem value={3}>3x</MenuItem>
                <MenuItem value={5}>5x</MenuItem>
                <MenuItem value={10}>10x</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            <Button
              color="error"
              variant="contained"
              onClick={handleCloseDialogEvent}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    </>
  );
}